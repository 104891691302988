import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { useStores } from 'core/hooks/useStores';

const LogoutItem = observer(() => {
  const { authStore } = useStores();
  const { logout } = authStore;

  return (
    <ListItemButton
      onClick={logout}
      sx={{ borderTop: 1, borderColor: 'divider' }}
    >
      <ListItemText primary={<FormattedMessage id="navigation.logout" />} />
    </ListItemButton>
  );
});

export default LogoutItem;
LogoutItem.displayName = 'LogoutItem';
