import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import BadgeIcon from 'components/SvgIcons/Badge';
import ErrorBoundary from 'components/ErrorBoundary';
import { useGetBadges } from 'core/api/user';

const Badges = () => {
  const history = useHistory();

  const { data, isLoading, error } = useGetBadges();
  const badges = data?.employeeBadges.filter((badge) => !!badge.receivedAt);

  return (
    <Paper
      sx={{ mb: 2, p: 2, width: '100%', borderRadius: 2, cursor: 'pointer' }}
      elevation={0}
      variant="outlined"
      onClick={() => history.push('/profile')}
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Box display="flex" alignItems="center" mb={2}>
            <BadgeIcon />
            <Typography variant="h6" sx={{ ml: 1 }}>
              <FormattedMessage
                id="home.badges.title"
                values={{ count: badges.length }}
              />
            </Typography>
          </Box>
          {badges.length > 0 ? (
            badges.map(({ title, image: { file: { url } } }, idx) => (
              <Tooltip title={title}>
                <img
                  key={idx}
                  src={url}
                  alt=""
                  style={{ height: 35, marginRight: 16 }}
                />
              </Tooltip>
            ))
          ) : (
            <Typography color="textSecondary">
              <FormattedMessage id="home.badges.empty" />
            </Typography>
          )}
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Badges.displayName = 'Badges';
export default Badges;
