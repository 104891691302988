import React from 'react';
import PropTypes from 'prop-types';

import MUIAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery, useTheme } from '@mui/material';

import AudioPlayer from 'components/ContentRenderer/Embeddable/embeddables/components/AudioPlayer';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}));

const Accordion = ({ panels }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const getPanelContent = (media, title, content) => {
    return (
      <Grid
        container
        justifyContent="space-between"
        direction={isMobile ? 'column-reverse' : 'row'}
      >
        <Grid xs>
          <Typography>{content}</Typography>
        </Grid>
        {media && (
          <Grid xs="auto">
            <AudioPlayer url={media[0].file.url} title={title} />
          </Grid>
        )}
      </Grid>
    );
  };

  return panels.map(({ title, subtitle, content, sections, media }) => (
    <Box mb={2} whiteSpace="pre-line">
      <Card key={title} variant="outlined">
        <Box p={2}>
          <Typography variant="h5" component="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h6" component="h2" gutterBottom>
            {subtitle}
          </Typography>
          {getPanelContent(media, title, content)}
        </Box>
        {sections.map(({ title, content, media }) => (
          <MUIAccordion
            classes={{ root: classes.root, expanded: classes.expanded }}
            variant="outlined"
            key={title}
            square
          >
            <AccordionSummary
              id={title}
              aria-controls="content"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h6" component="h2">
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {getPanelContent(media, title, content)}
            </AccordionDetails>
          </MUIAccordion>
        ))}
      </Card>
    </Box>
  ));
};

Accordion.propTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.string,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
        }),
      ),
      media: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      ),
    }).isRequired,
  ).isRequired,
};

Accordion.displayName = 'Accordion';
export default Accordion;
