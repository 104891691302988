import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import Activity from './Activity';
import Alerts from 'components/Alerts';
import Badges from './Badges';
import Skills from './Skills';
import Interests from './Interests';
import Experiences from './Experiences';
import About from './About';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import { COACH_VIEW, LEARNING_VIEW } from 'core/utils/permissions';

const Profile = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(() => theme.breakpoints.down('sm'));

  return (
    <Gate noServiceLogin redirectOnDenied>
      <Layout showNavigation>
        <Typography variant="h1" sx={{ pb: 3 }}>
          <FormattedMessage id="profile.title" />
        </Typography>
        <Grid
          container
          spacing={2}
          direction={isSmall ? 'column-reverse' : 'row'}
        >
          <Grid xs={12} sm={7} md={8}>
            <Gate permissions={[COACH_VIEW]}>
              <Activity />
            </Gate>
            <Gate permissions={[LEARNING_VIEW]}>
              <Badges />
            </Gate>
            <Skills />
            <Gate permissions={[COACH_VIEW]}>
              <Interests />
            </Gate>
            <Gate permissions={[LEARNING_VIEW]}>
              <Experiences />
            </Gate>
          </Grid>
          <Grid xs={12} sm={5} md={4}>
            <About isSmall={isSmall} />
          </Grid>
        </Grid>
        <Alerts />
      </Layout>
    </Gate>
  );
};

Profile.displayName = 'Profile';
export default Profile;
