import palette from '../palette';
import typography from '../typography';

export default {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.common.black,
      fontSize: typography.body2.fontSize,
    },
    arrow: {
      color: palette.common.black,
    },
  },
};
