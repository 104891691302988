import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from 'components/FormField';

const RadioSingleSelect = ({ id, options }) => {
  const { isSubmitting } = useFormikContext();

  return (
    <FormGroup name={id}>
      <FormControl name={id}>
        <RadioGroup name={id}>
          {options.map(({ value, label }) => (
            <FormControlLabel
              key={`single-select-option-${value}`}
              value={value}
              label={label}
              control={<Radio />}
              disabled={isSubmitting}
              sx={{ '.MuiFormControlLabel-label': { py: '9px' } }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </FormGroup>
  );
};

RadioSingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

RadioSingleSelect.displayName = 'RadioSingleSelect';
export default RadioSingleSelect;
