import Alert from '@mui/material/Alert';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmptyState from 'components/EmptyState';

const DEFAULT_STATUS_CODE = 500;
const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

// Custom implementation of https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  // TODO: Test that it catches JavaScript errors
  componentDidCatch(error, info) {
    this.setState({ error });
  }

  parseError = (error) => {
    if (error instanceof Error) {
      const { response: { status, data: { message } = {} } = {} } = error;
      return {
        statusCode: status || DEFAULT_STATUS_CODE,
        message: message || DEFAULT_ERROR_MESSAGE,
      };
    } else {
      return {
        statusCode: DEFAULT_STATUS_CODE,
        message: DEFAULT_ERROR_MESSAGE,
      };
    }
  };

  render() {
    const { size, error = this.state.error } = this.props;

    if (error) {
      const { message } = this.parseError(error);

      if (size === 'small') {
        return <Alert severity="error">Error: {message}</Alert>;
      } else {
        const emptyStateProps = {
          title: message,
          description: 'Please try again later',
        };

        return <EmptyState {...emptyStateProps} />;
      }
    }

    return this.props.children || null;
  }

  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.node,
    size: PropTypes.oneOf(['small', 'large']),
  };

  static defaultProps = {
    size: 'large',
  };
}
