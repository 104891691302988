import React from 'react';
import PropTypes from 'prop-types';

import DraggableBlockTarget from './DraggableBlockTarget';
import DraggableChipTarget from './DraggableChipTarget';

const DraggableTarget = ({ type, ...props }) => {
  const Component =
    type === 'Chip' ? DraggableChipTarget : DraggableBlockTarget;

  return <Component {...props} />;
};

DraggableTarget.propTypes = {
  type: PropTypes.oneOf(['Chip', 'Block']).isRequired,
};

DraggableTarget.displayName = 'DraggableTarget';
export default DraggableTarget;
