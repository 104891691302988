import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import Draggable from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/DragDrop/components/DraggableChip';
import DropContainer from 'components/ContentRenderer/Embeddable/embeddables/components/DropContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: '6px transparent dashed',
    borderRadius: 6,
    minHeight: '50%',
  },
  isDraggingOver: {
    border: `6px rgba(0, 0, 0, 0.2) dashed`,
  },
}));

const DraggableChipBank = ({ options, unselected, isDisabled }) => {
  const classes = useStyles();

  return (
    <DropContainer
      droppableId="unselected"
      isDropDisabled={isDisabled}
      classes={{ root: classes.root, isDraggingOver: classes.isDraggingOver }}
    >
      {unselected.map((value, index) => {
        const option = options.find((option) => option.value === value);
        return (
          <Draggable
            key={index}
            index={index}
            isDragDisabled={isDisabled}
            {...option}
          />
        );
      })}
    </DropContainer>
  );
};

DraggableChipBank.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  unselected: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
};

DraggableChipBank.displayName = 'DraggableChipBank';
export default DraggableChipBank;
