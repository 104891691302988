import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiDialog from './MuiDialog';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiRadio from './MuiRadio';
import MuiSwitch from './MuiSwitch';
import MuiTableBody from './MuiTableBody';
import MuiTableCell from './MuiTableCell';
import MuiTooltip from './MuiTooltip';
import MuiTab from './MuiTab';

export default {
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiDialog,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiRadio,
  MuiSwitch,
  MuiTab,
  MuiTableBody,
  MuiTableCell,
  MuiTooltip,
};
