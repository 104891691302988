import React, { useEffect } from 'react';

import LandingPage from 'components/LandingPage';
import { useSaveCampaignResponses } from 'core/api/campaign';

const CallToAction = () => {
  const {
    mutateAsync: saveCallToActionResponses,
    isLoading: isLoadingAnswer,
    error,
  } = useSaveCampaignResponses();

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  const callToActionAnswer = query.get('value');
  const questionCid = query.get('cid');

  useEffect(() => {
    saveCallToActionResponses({
      token: token,
      questionCid: questionCid,
      campaignAnswer: callToActionAnswer,
    });
  }, [saveCallToActionResponses, token, callToActionAnswer, questionCid]);

  return <LandingPage isLoading={isLoadingAnswer} error={error} />;
};

CallToAction.displayName = 'CallToAction';
export default CallToAction;
