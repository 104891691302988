import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ColumnChart from './ColumnChart';
import StackedColumnChart from './StackedColumnChart';

const useStyles = makeStyles((theme) => ({
  input: {
    ...theme.typography.caption,
    marginLeft: theme.spacing(),
  },
  select: {
    padding: theme.spacing(1, 4, 1, 2),
  },
  menuItem: {
    ...theme.typography.caption,
  },
}));

const COMPONENTS = {
  Column: ColumnChart,
  'Stacked Column': StackedColumnChart,
};

const Chart = ({ type, series, showFilters }) => {
  const classes = useStyles();

  const ChartComponent = COMPONENTS[type];

  const [activeFilter, setActiveFilter] = useState(series[0].key);
  const onChange = (e) => setActiveFilter(e.target.value);

  return (
    <Box>
      {showFilters && (
        <Box textAlign="right">
          <Typography variant="caption" display="inline">
            <FormattedMessage id="action.filter" />
          </Typography>
          <Select
            value={activeFilter}
            onChange={onChange}
            className={classes.input}
            classes={{ outlined: classes.select }}
          >
            {series.map(({ key }) => (
              <MenuItem key={key} value={key} className={classes.menuItem}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      <ChartComponent
        height={400}
        series={
          showFilters
            ? series.find((data) => data.key === activeFilter).series
            : series
        }
      />
    </Box>
  );
};

Chart.propTypes = {
  type: PropTypes.oneOf(['Column', 'Stacked Column']).isRequired,
  series: PropTypes.any.isRequired,
  showFilter: PropTypes.bool,
};
Chart.displayName = 'Chart';
export default Chart;
