import React, { useRef, useState, useLayoutEffect } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import BlockQuote from 'assets/learning/block-quote.svg';

const useStyles = makeStyles((theme) => ({
  image: {
    height: 100,
    margin: 'auto',
    marginBottom: theme.spacing(),
    display: 'block',
  },
}));

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography variant="h1" component="h1" paragraph>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography variant="h2" component="h2" paragraph>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography variant="h3" component="h3" paragraph>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography variant="h4" component="h4" paragraph>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography variant="h5" component="h5" paragraph>
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography variant="h6" component="h6" paragraph>
        {children}
      </Typography>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography variant="body1" paragraph>
        {children}
      </Typography>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <Box display="flex" alignItems="start">
        <img src={BlockQuote} alt="" />
        <Box mt={2} ml={2} color="primary.main">
          <Typography variant="body1" component="blockquote" paragraph>
            {children}
          </Typography>
        </Box>
      </Box>
    ),
  },
};

const MAX_HEIGHT = 28; // 1 line of text

const TextCard = ({ content, title, topImage }) => {
  const classes = useStyles();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight) {
      setIsOverflowing(ref.current?.clientHeight > MAX_HEIGHT);
    }
  }, []);

  return (
    <Box height="100%" px={{ xs: 2, sm: 3 }}>
      {topImage && (
        <img
          alt={topImage.description}
          src={topImage.file.url}
          className={classes.image}
        />
      )}
      {title && (
        <Typography
          variant="h2"
          paragraph
          ref={ref}
          sx={{ textAlign: isOverflowing ? 'left' : 'center' }}
        >
          {title}
        </Typography>
      )}
      {documentToReactComponents(content, options)}
    </Box>
  );
};

TextCard.displayName = 'TextCard';
export default TextCard;
