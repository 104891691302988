import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import BlockQuote from 'assets/learning/block-quote.svg';
import ErrorBoundary from 'components/ErrorBoundary';
import Embeddable from './Embeddable';

const ContentRenderer = ({ content, embeddableProps }) => {
  const getEmbeddable = ({
    data: {
      target: { id, contentType, ...fields },
    },
  }) => (
    <ErrorBoundary>
      <Embeddable
        id={id}
        type={contentType}
        fields={fields}
        {...embeddableProps}
      />
    </ErrorBoundary>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h1" component="h1" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h2" component="h2" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography variant="h3" component="h3" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography variant="h4" component="h4" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography variant="h5" component="h5" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography variant="h6" component="h6" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography variant="body1" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <Box display="flex" alignItems="start">
          <img src={BlockQuote} alt="" />
          <Box mt={2} ml={2} color="primary.main">
            <Typography variant="body1" component="blockquote" paragraph>
              {children}
            </Typography>
          </Box>
        </Box>
      ),
      [BLOCKS.TABLE]: (node, children) => (
        <Table>
          <TableHead>{children.shift()}</TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
        <TableCell>{children}</TableCell>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <TableCell>{children}</TableCell>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <Embeddable
          id={node.data.target.id}
          type="image"
          fields={node.data.target}
        />
      ),
      [BLOCKS.EMBEDDED_ENTRY]: getEmbeddable,
      [INLINES.EMBEDDED_ENTRY]: getEmbeddable,
    },
  };
  return documentToReactComponents(content, options);
};

ContentRenderer.propTypes = {
  content: PropTypes.object.isRequired,
  embeddableProps: PropTypes.object,
};
ContentRenderer.displayName = 'ContentRenderer';
export default ContentRenderer;
