import React from 'react';
import PropTypes from 'prop-types';
import ChartRenderer from 'components/Chart';

const options = {
  chart: {
    stacked: true,
    stackType: '100%',
    fontFamily: 'Lexend',
    toolbar: {
      show: false,
    },
    events: {
      mounted: (chart) => {
        chart.windowResizeHandler();
      },
    },
  },
  colors: ['#b8dfe2', '#007881'],
  fill: {
    opacity: 1,
  },
  dataLabels: {
    formatter: (val) => `${val}%`,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    axisTicks: {
      show: false,
    },
    labels: {
      formatter: (val) => `${val}%`,
    },
  },
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
};

const StackedColumnChart = ({ series }) => {
  return (
    <ChartRenderer height={400} type="bar" options={options} series={series} />
  );
};

StackedColumnChart.propTypes = {
  series: PropTypes.any.isRequired,
};
StackedColumnChart.displayName = 'StackedColumnChart';
export default StackedColumnChart;
