import React from 'react';
import PropTypes from 'prop-types';

import DropdownSingleSelect from './DropdownSingleSelect';
import RadioSingleSelect from './RadioSingleSelect';
import PositiveNegativeSingleSelect from './PositiveNegativeSingleSelect';

const SingleSelect = ({ appearance, ...props }) => {
  return (
    <>
      {appearance === 'Dropdown' && <DropdownSingleSelect {...props} />}
      {appearance === 'Radio' && <RadioSingleSelect {...props} />}
      {appearance === 'Positive Negative' && (
        <PositiveNegativeSingleSelect {...props} />
      )}
    </>
  );
};

SingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  appearance: PropTypes.oneOf(['Radio', 'Dropdown', 'Positive Negative'])
    .isRequired,
};

SingleSelect.displayName = 'SingleSelect';
export default SingleSelect;
