import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

const DragComponent = ({ index, value, isDragDisabled, children }) => {
  // From: https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
  const getStyle = (draggableProps, snapshot) => {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return { ...draggableProps.style };
    }
    return {
      ...draggableProps.style,
      transitionDuration: `0.001s`, // cannot be 0, but make it super tiny
    };
  };

  return (
    <Draggable
      draggableId={value}
      key={value}
      index={index}
      isDragDisabled={isDragDisabled}
      disableInteractiveElementBlocking
    >
      {({ draggableProps, dragHandleProps, innerRef }, snapshot) => {
        const style = getStyle(draggableProps, snapshot);
        return children({
          snapshot,
          innerRef,
          ...draggableProps,
          ...dragHandleProps,
          style,
        });
      }}
    </Draggable>
  );
};

DragComponent.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  isDragDisabled: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

DragComponent.displayName = 'DragComponent';
export default DragComponent;
