import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'core/hooks/useStores';

const RETURN_TO_KEY = 'return_to';
const REDIRECT_BASE_URL = 'https://eskalera.zendesk.com/access/jwt';

//
// Handles SSO authentication for Zendesk.
// ZD calls our endpoint (typically "/help") which renders this component.
// This component generates a new JWT Token from the server (ZD requires a token to be less than 3 min old)
// then redirects back to Zendesk with a "token" query parameter as well as the original "return_to" query parameter
// This component should always be wrapped in an <AuthenticatedRoute />
// https://support.zendesk.com/hc/en-us/articles/203663816-Enabling-JWT-JSON-Web-Token-single-sign-on
// TODO: only generate a new token when last token is over 3 min old
//
export const Zendesk = observer(() => {
  const { authStore } = useStores();
  const { getNewToken } = authStore;

  const query = new URLSearchParams(window.location.search);
  const returnToValue = query.get(RETURN_TO_KEY) || '/';

  useEffect(() => {
    const fetch = async () => {
      const { authToken } = await getNewToken();
      window.location = `${REDIRECT_BASE_URL}?jwt=${authToken}&${RETURN_TO_KEY}=${encodeURIComponent(
        returnToValue,
      )}`;
    };
    fetch();
  }, [getNewToken, returnToValue]);

  return null;
});

Zendesk.displayName = 'Zendesk';

export default Zendesk;
