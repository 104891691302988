import React from 'react';
import { useField } from 'formik';

import MuiFormGroup from '@mui/material/FormGroup';

const FormGroup = ({ name, children, ...props }) => {
  const [field] = useField({ name, multiple: true });

  return (
    <MuiFormGroup name={name} {...field} {...props}>
      {children}
    </MuiFormGroup>
  );
};

export default FormGroup;
