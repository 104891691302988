import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import Card from 'components/Card';
import Check from 'components/SvgIcons/Check';
import Lock from 'components/SvgIcons/Lock';
import PrivacyDialog from 'components/PrivacyDialog';
import { useBooleanState } from 'core/hooks/useBooleanState';

const ProgramCard = ({
  closeAt,
  completedAt,
  description,
  heroImageUrl,
  isClosed,
  isCompleted,
  isLocked,
  openAt,
  shouldResume,
  showPrivacyPolicy,
  title,
  uid,
}) => {
  const [dialogIsOpen, toggleDialogOpen] = useBooleanState(false);

  const subtitle = isCompleted ? (
    <FormattedMessage
      id="date.completed"
      values={{
        completedAt: moment.utc(completedAt).format('ll'),
      }}
    />
  ) : closeAt ? (
    `${moment.utc(openAt).format('ll')} - ${moment.utc(closeAt).format('ll')}`
  ) : (
    moment.utc(openAt).format('ll')
  );

  const image = JSON.parse(heroImageUrl);

  const actions = (
    <>
      <Button variant="outlined" component={Link} to={`/circles/${uid}`}>
        <FormattedMessage id="action.view" />
      </Button>
      <Button
        variant="contained"
        onClick={toggleDialogOpen}
        component={showPrivacyPolicy ? 'a' : Link}
        to={`/circles/${uid}`}
        disabled={isClosed || isCompleted || isLocked}
        startIcon={
          isCompleted ? <Check /> : isLocked || isClosed ? <Lock /> : null
        }
      >
        <FormattedMessage
          id={`action.${
            isCompleted
              ? 'complete'
              : isClosed
              ? 'closed'
              : shouldResume
              ? 'resume'
              : 'start'
          }`}
        />
      </Button>
      {dialogIsOpen && (
        <PrivacyDialog
          open
          onClose={toggleDialogOpen}
          maxWidth="md"
          link={`circles/${uid}`}
        />
      )}
    </>
  );

  return (
    <Card
      title={title}
      subtitle={subtitle}
      content={description}
      image={image.url}
      actions={actions}
      disabled={isLocked}
    />
  );
};

ProgramCard.propTypes = {
  closeAt: PropTypes.string,
  completedAt: PropTypes.string,
  description: PropTypes.string.isRequired,
  heroImageUrl: PropTypes.string,
  isClosed: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired,
  openAt: PropTypes.string.isRequired,
  shouldResume: PropTypes.bool,
  showPrivacyPolicy: PropTypes.bool,
  title: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};

ProgramCard.displayName = 'ProgramCard';
export default ProgramCard;
