import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Avatar from 'components/Avatar';
import PhoneInput from 'components/PhoneInput';
import Settings from './Settings';
import { useStores } from 'core/hooks/useStores';

const useStyles = makeStyles((theme) => ({
  phone: {
    padding: 0,
    '& > input.Mui-disabled': {
      '-webkit-text-fill-color': theme.palette.common.black,
      color: theme.palette.common.black,
    },
  },
  addField: {
    textTransform: 'lowercase',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
}));

const About = ({ isSmall }) => {
  const classes = useStyles();

  const { userStore } = useStores();
  const { user } = userStore;

  const [open, setOpen] = useState(-1);

  const onOpen = (idx) => () => setOpen(idx);
  const onClose = () => setOpen(-1);

  return (
    <Paper
      sx={{ p: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      <Box textAlign="center" mb={2}>
        <Avatar
          size="large"
          src={user.user.avatarUrl}
          name={user.user.fullName}
        />
        <Box my={1}>
          <Typography variant="subtitle1">{user.user.fullName}</Typography>
          <Typography variant="body2">
            {user.organization.displayName}
          </Typography>
          <Typography variant="body2">{user.employee.workEmail}</Typography>
        </Box>
        <Button variant="outlined" fullWidth onClick={onOpen(0)}>
          <FormattedMessage id="action.editAccount" />
        </Button>
        {open > -1 && (
          <Settings open={open > -1} onClose={onClose} initialTab={open} />
        )}
      </Box>
      {!isSmall && (
        <>
          <Divider />
          <Box mt={2}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage id="profile.about.title" />
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <FormattedMessage id="user.username" />
            </Typography>
            <Typography>{user.user.username}</Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <FormattedMessage id="user.nickname" />
            </Typography>
            <Typography>{user.user.preferredName}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage id="profile.contact.title" />
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <FormattedMessage id="user.personalEmail" />
            </Typography>
            {user.user.personalEmail ? (
              <Typography>{user.user.personalEmail}</Typography>
            ) : (
              <Typography className={classes.addField} onClick={onOpen(0)}>
                <FormattedMessage
                  id="action.addField"
                  values={{
                    field: <FormattedMessage id="user.personalEmail" />,
                  }}
                />
              </Typography>
            )}
            <Typography variant="body2" sx={{ mt: 2 }}>
              <FormattedMessage id="user.phone" />
            </Typography>
            {user.user.personalPhone ? (
              <PhoneInput
                disabled
                disableUnderline
                classes={{ root: classes.phone }}
                variant="standard"
                value={user.user.personalPhone}
              />
            ) : (
              <Typography className={classes.addField} onClick={onOpen(0)}>
                <FormattedMessage
                  id="action.addField"
                  values={{
                    field: <FormattedMessage id="user.phone" />,
                  }}
                />
              </Typography>
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage id="profile.language.title" />
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <FormattedMessage id="user.language" />
            </Typography>
            {user.employee.language.name ? (
              <Typography>{user.employee.language.name}</Typography>
            ) : (
              <Typography className={classes.addField} onClick={onOpen(3)}>
                <FormattedMessage
                  id="action.addField"
                  values={{
                    field: <FormattedMessage id="user.language" />,
                  }}
                />
              </Typography>
            )}
            <Typography variant="body2" sx={{ mt: 2 }}>
              <FormattedMessage id="user.timezone" />
            </Typography>
            {user.user.timezone ? (
              <Typography>{user.user.timezone}</Typography>
            ) : (
              <Typography className={classes.addField} onClick={onOpen(3)}>
                <FormattedMessage
                  id="action.addField"
                  values={{
                    field: <FormattedMessage id="user.timezone" />,
                  }}
                />
              </Typography>
            )}
          </Box>
        </>
      )}
    </Paper>
  );
};

About.displayName = 'About';
export default About;
