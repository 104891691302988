import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const EmptyState = ({
  title,
  titleTypographyProps,
  description,
  descriptionTypographyProps,
  image,
  action,
  isLoading,
  size,
  justify,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={justify}
      flexDirection="column"
      height="100%"
      p={4}
    >
      {isLoading ? (
        <CircularProgress size={size === 'small' ? 50 : 100} />
      ) : (
        <>
          {image && image}
          <Box mt={1} textAlign="center">
            {title && (
              <Typography variant="h6" {...titleTypographyProps}>
                {title}
              </Typography>
            )}
            {description && (
              <Typography variant="body2" {...descriptionTypographyProps}>
                {description}
              </Typography>
            )}
          </Box>
          <Box mt={2}>{action && action}</Box>
        </>
      )}
    </Box>
  );
};

EmptyState.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleTypographyProps: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  descriptionTypographyProps: PropTypes.object,
  size: PropTypes.oneOf(['small', 'large']),
  image: PropTypes.element,
  action: PropTypes.element,
  isLoading: PropTypes.bool,
};

EmptyState.defaultProps = {
  size: 'large',
  justify: 'center',
};

EmptyState.displayName = 'EmptyState';
export default EmptyState;
