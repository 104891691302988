import ApiService from './ApiService';

export const getProgramList = async () => ApiService.get('connect/programs');

export const getProgramDetail = async (id) =>
  ApiService.get(`connect/programs/${id}`);

export const getProgramParticipants = async (id) =>
  ApiService.get(`connect/programs/${id}/participants`);

export const getCirclePreview = async (id) =>
  ApiService.get(`connect/programs/preview/c/${id}`);

export const getQuestionInsights = async (programId, questionIds) => {
  return ApiService.get(`connect/programs/${programId}/question-insights`, {
    params: { questionIds: questionIds.join() },
  });
};
export const saveResponses = async (
  programId,
  contentType,
  contentId,
  responses,
) =>
  ApiService.post(`connect/programs/${programId}/responses`, {
    contentType,
    contentId,
    responses,
  });

export const saveEvent = async (
  programId,
  contentType,
  contentId,
  parentContentId,
  event,
) =>
  ApiService.post(`connect/programs/${programId}/event`, {
    contentType,
    contentId,
    parentContentId,
    event,
  });
