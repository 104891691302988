import React from 'react';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import UserDialog from './UserDialog';
import ErrorBoundary from 'components/ErrorBoundary';
import { useBooleanState } from 'core/hooks/useBooleanState';
import { useGetServiceUsers, useRemoveServiceUser } from 'core/api/user';
import {
  successNotification,
  errorNotification,
} from 'components/Notifications';

const Users = () => {
  const intl = useIntl();

  const { data, isLoading, error } = useGetServiceUsers();
  const { mutateAsync } = useRemoveServiceUser();

  const [dialogOpen, toggleDialogOpen] = useBooleanState(false);

  const remove = (id) => async () =>
    await mutateAsync(
      { id },
      {
        onSuccess: () =>
          successNotification(
            intl.formatMessage({
              id: 'profile.settings.service.users.remove.success',
            }),
          ),
        onError: () =>
          errorNotification(intl.formatMessage({ id: 'error.generic' })),
      },
    );

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        <FormattedMessage id="profile.settings.service.users.title" />
      </Typography>
      {!isLoading && (
        <ErrorBoundary error={error}>
          {data.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  {['username', 'startDate', 'endDate'].map((column) => (
                    <TableCell scope="col" key={column}>
                      <FormattedMessage
                        id={`profile.settings.service.${column}`}
                      />
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ id, user, startDate, endDate, status }) => (
                  <TableRow key={id}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{moment(startDate).format('LL')}</TableCell>
                    <TableCell>{moment(endDate).format('LL')}</TableCell>
                    <TableCell>
                      {status === 'Approved' ? (
                        <ButtonBase onClick={remove(id)}>
                          <Typography variant="body2" color="secondary">
                            <FormattedMessage id="action.revoke" />
                          </Typography>
                        </ButtonBase>
                      ) : (
                        status
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography color="textSecondary">
              <FormattedMessage id="profile.settings.service.users.empty" />
            </Typography>
          )}
          <Button variant="outlined" sx={{ mt: 2 }} onClick={toggleDialogOpen}>
            <FormattedMessage id="action.add" />
          </Button>
          <UserDialog open={dialogOpen} onClose={toggleDialogOpen} />
        </ErrorBoundary>
      )}
    </Box>
  );
};

Users.displayName = 'Users';
export default Users;
