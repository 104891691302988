import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EmptyStateIcon from '@mui/icons-material/BookmarkBorder';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';
import EmptyState from 'components/EmptyState';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import { useGetBookmarks, useSetBookmark } from 'core/api/coach';
import { COACH_VIEW } from 'core/utils/permissions';
import {
  successNotification,
  errorNotification,
} from 'components/Notifications';
import moment from 'moment/moment';

const Bookmarks = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useGetBookmarks();
  const { mutate } = useSetBookmark();

  const [selected, setSelected] = useState(null);

  const onDialogOpen = (deck) => () => setSelected(deck);
  const onDialogClose = () => setSelected(null);

  const onRemoveBookmark = () => {
    mutate(
      { id: selected.contentCid, isBookmarked: false },
      {
        onSuccess: () => {
          successNotification(
            intl.formatMessage({
              id: 'bookmarks.remove.success',
            }),
          );
          queryClient.resetQueries('bookmarks');
          setSelected(null);
        },
        onError: (err) => errorNotification(err.response.data.message),
      },
    );
  };

  return (
    <Gate permissions={[COACH_VIEW]} redirectOnDenied>
      <Layout showNavigation align="center">
        {!isLoading && (
          <Paper
            elevation={0}
            variant="outlined"
            sx={{
              width: { xs: '100%', md: 600 },
              margin: 'auto',
              borderRadius: 2,
            }}
          >
            {error || data?.length === 0 ? (
              <Box textAlign="center" p={12}>
                <EmptyStateIcon sx={{ fontSize: 80 }} />
                <Typography variant="h4" sx={{ mt: 5 }}>
                  <FormattedMessage id="bookmarks.empty.title" />
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    p: 3,
                    bgcolor: 'primary.main',
                    color: 'common.white',
                    borderRadius: '8px 8px 0px 0px',
                  }}
                >
                  <FormattedMessage id="bookmarks.title" />
                </Typography>
                <Box p={3} pt={1}>
                  {data.map(({ bookmarkedAt, ...deck }, idx) => {
                    const utc = moment
                      .utc(bookmarkedAt, 'MM-DD-YYYY hh:mm:ss')
                      .toDate();
                    const local = moment(utc).local();
                    const fromNow = local.fromNow();

                    return (
                      <CoachListItem
                        key={idx}
                        secondaryText={fromNow}
                        secondaryActionIcon={BookmarkIcon}
                        onSecondaryActionClick={onDialogOpen(deck)}
                        {...deck}
                      />
                    );
                  })}
                </Box>
                <Dialog open={!!selected} onClose={onDialogClose} maxWidth="xs">
                  <DialogContent sx={{ textAlign: 'center', py: 4 }}>
                    <Typography variant="h6">
                      <FormattedMessage id="bookmarks.remove.title" />
                    </Typography>
                    <Typography>
                      <FormattedMessage
                        id="bookmarks.remove.description"
                        values={{ title: selected?.endUserTitle }}
                      />
                    </Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={onRemoveBookmark}
                      sx={{ mt: 4, mb: 1 }}
                    >
                      <FormattedMessage id="action.remove" />
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={onDialogClose}
                    >
                      <FormattedMessage id="action.cancel" />
                    </Button>
                  </DialogContent>
                </Dialog>
              </Box>
            )}
          </Paper>
        )}
        {isLoading && <EmptyState isLoading />}
      </Layout>
    </Gate>
  );
};

Bookmarks.displayName = 'Bookmarks';
export default Bookmarks;
