/**
 * [key, value] tuples of timezone labels and their values in the DB
 */
export const TIMEZONE_OPTIONS = [
  ['(UTC-11:00) Samoa Standard Time', 'Pacific/Samoa'],
  ['(UTC-10:00) Hawaii Standard Time', 'Pacific/Honolulu'],
  ['(UTC-09:00) Alaska Standard Time', 'US/Alaska'],
  ['(UTC-08:00) Pacific Standard Time', 'America/Los_Angeles'],
  ['(UTC-08:00) Baja California', 'Mexico/BajaNorte'],
  ['(UTC-07:00) Arizona', 'US/Arizona'],
  ['(UTC-07:00) Mountain Standard Time', 'US/Mountain'],
  ['(UTC-07:00) Chihuahua, La Paz', 'America/Chihuahua'],
  ['(UTC-06:00) Central Standard Time', 'America/Chicago'],
  ['(UTC-06:00) Central America', 'America/Costa_Rica'],
  ['(UTC-06:00) Mexico City, Monterrey', 'America/Mexico_City'],
  ['(UTC-06:00) Saskatchewan', 'Canada/Saskatchewan'],
  ['(UTC-05:00) Bogota, Lima', 'America/Bogota'],
  ['(UTC-05:00) Eastern Standard Time', 'America/New_York'],
  ['(UTC-04:00) Venezuela Time', 'America/Caracas'],
  ['(UTC-04:00) Paraguay Time', 'America/Asuncion'],
  ['(UTC-04:00) Amazon Time', 'America/Cuiaba'],
  ['(UTC-04:00) Atlantic Standard Time', 'America/Halifax'],
  ['(UTC-04:00) Bolivia Time', 'America/La_Paz'],
  ['(UTC-04:00) Chile Time', 'America/Santiago'],
  ['(UTC-03:30) Newfoundland Standard Time', 'America/St_Johns'],
  ['(UTC-03:00) Brasilia Time', 'America/Araguaina'],
  ['(UTC-03:00) Argentine Time', 'America/Argentina/Buenos_Aires'],
  ['(UTC-03:00) French Guiana Time', 'America/Cayenne'],
  ['(UTC-03:00) Greenland Time', 'America/Godthab'],
  ['(UTC-03:00) Uruguay Time]', 'America/Montevideo'],
  ['(UTC-02:00) GMT-02:00', 'Etc/GMT+2'],
  ['(UTC-01:00) Azores Time', 'Atlantic/Azores'],
  ['(UTC-01:00) Cape Verde Time', 'Atlantic/Cape_Verde'],
  ['(UTC) Coordinated Universal Time', 'Etc/UTC'],
  ['(UTC) Reykjavik', 'Atlantic/Reykjavik'],
  ['(UTC) Western European Time', 'Europe/London'],
  ['(UTC+01:00) Casablanca', 'Africa/Casablanca'],
  ['(UTC+01:00) Central European Time', 'CET'],
  ['(UTC+02:00) Eastern European Time', 'Europe/Bucharest'],
  ['(UTC+02:00) South Africa Standard Time', 'Africa/Johannesburg'],
  ['(UTC+02:00) Beirut', 'Asia/Beirut'],
  ['(UTC+02:00) Cairo', 'Africa/Cairo'],
  ['(UTC+02:00) Israel Standard Time', 'Asia/Jerusalem'],
  ['(UTC+02:00) Minsk', 'Europe/Minsk'],
  ['(UTC+03:00) Moscow Standard Time', 'Europe/Moscow'],
  ['(UTC+03:00) Eastern African Time', 'Africa/Nairobi'],
  ['(UTC+05:00) Pakistan Time', 'Asia/Karachi'],
  ['(UTC+05:30) India Standard Time', 'Asia/Kolkata'],
  ['(UTC+05:30) Indochina Time', 'Asia/Bangkok'],
  ['(UTC+08:00) China Standard Time', 'Asia/Shanghai'],
  ['(UTC+08:00) Malaysia Time', 'Asia/Kuala_Lumpur'],
  ['(UTC+08:00) Western Standard Time (Australia)', 'Australia/Perth'],
  ['(UTC+08:00) Taiwan', 'Asia/Taipei'],
  ['(UTC+09:00) Japan Standard Time', 'Asia/Tokyo'],
  ['(UTC+09:00) Korea Standard Time', 'Asia/Seoul'],
  ['(UTC+09:30) Central Standard Time (South Australia)', 'Australia/Adelaide'],
  [
    '(UTC+09:30) Central Standard Time (Northern Territory)',
    'Australia/Darwin',
  ],
  ['(UTC+10:00) Eastern Standard Time (Queensland)', 'Australia/Brisbane'],
  ['(UTC+10:00) Eastern Standard Time (New South Wales)', 'Australia/Canberra'],
  ['(UTC+10:00) Chamorro Standard Time', 'Pacific/Guam'],
  ['(UTC+12:00) New Zealand Standard Time', 'Pacific/Auckland'],
];

/** Service login request statuses */
export const SERVICE_REQUEST_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  EXPIRED: 'Expired',
  REVOKED: 'Revoked',
};
