import React from 'react';
import PropTypes from 'prop-types';

import DraggableBlockBank from './DraggableBlockBank';
import DraggableChipBank from './DraggableChipBank';

const DraggableBank = ({ type, ...props }) => {
  const Component = type === 'Chip' ? DraggableChipBank : DraggableBlockBank;

  return <Component {...props} />;
};

DraggableBank.propTypes = {
  type: PropTypes.oneOf(['Chip', 'Block']).isRequired,
};

DraggableBank.displayName = 'DraggableBank';
export default DraggableBank;
