import React from 'react';

import BackArrow from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NextArrow from '@mui/icons-material/ArrowForwardRounded';

const DesktopContainer = ({
  paginate,
  disableNavigation,
  activeStep,
  totalSteps,
  children,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <IconButton
        onClick={() => paginate(-1)}
        disabled={disableNavigation}
        sx={{
          mr: { sm: 1, md: 5 },
          visibility: activeStep !== 0 ? 'visible' : 'hidden',
          border: 2,
          borderColor: 'common.white',
          color: 'common.white',
        }}
      >
        <BackArrow />
      </IconButton>
      <Box
        width={{ xs: '100%', md: '600px' }}
        height="100%"
        position="relative"
      >
        {children}
      </Box>
      <IconButton
        type="submit"
        disabled={disableNavigation}
        sx={{
          ml: { sm: 1, md: 5 },
          visibility:
            activeStep !== 0 && activeStep !== totalSteps
              ? 'visible'
              : 'hidden',
          border: 2,
          borderColor: 'common.white',
          bgcolor: 'common.white',
          color: 'primary.main',
          '&:hover': {
            bgcolor: 'background.hover',
          },
        }}
      >
        <NextArrow />
      </IconButton>
    </Box>
  );
};

DesktopContainer.displayName = 'DesktopContainer';
export default DesktopContainer;
