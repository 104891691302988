import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { activityTypeToComponent } from './activities';

const Activity = ({ id, getSourceDataValue, sourceData, activity }) => {
  const { id: activityId, contentType, ...activityFields } = activity;
  const sourceDataValue = getSourceDataValue(sourceData, contentType);

  const Component = activityTypeToComponent[contentType];

  if (Component) {
    return (
      <Component
        id={id}
        sourceDataValue={sourceDataValue}
        {...activityFields}
      />
    );
  } else {
    return (
      <Alert severity="warning">
        <AlertTitle>
          Unimplemented Embeddable of type <b>{contentType}</b>
        </AlertTitle>
        {JSON.stringify(activityFields)}
      </Alert>
    );
  }
};

Activity.propTypes = {
  id: PropTypes.string.isRequired,
  getSourceDataValue: PropTypes.func.isRequired,
  sourceData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  activity: PropTypes.object.isRequired,
};
Activity.displayName = 'Activity';
export default Activity;
