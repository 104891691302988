import { useEffect } from 'react';

const AdminAppRedirect = () => {
  useEffect(() => {
    const adminAppBaseUrl = process.env.REACT_APP_ADMIN_APP_UI_BASE || '//' + window.location.hostname + '/admin';
      const fullPath = window.location.pathname + window.location.search; // /admin/x/y/z?q=foo
      const path = fullPath.split('/').slice(2).join('/'); // /x/y/z
      window.location.href = `${adminAppBaseUrl}/${path}`; // admin.domain.com/x/y/z or app.domain.com/admin/x/y/z

  });
  return null;
};

export default AdminAppRedirect;
