import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import Alerts from 'components/Alerts';
import Badges from './Badges';
import Circles from './Circles';
import Coach from './Coach';
import EmptyState from 'components/EmptyState';
import EmptyStateImage from 'assets/shared/panda-ladder.svg';
import ErrorBoundary from 'components/ErrorBoundary';
import Gate from 'components/Gate';
import Interests from './Interests';
import Layout from 'layouts/Layout';
import Onboarding from './Onboarding';
import Paths from './Paths';
import Skills from './Skills';
import { useStores } from 'core/hooks/useStores';
import { useGetFeed } from 'core/api/feed';
import { useBooleanState } from 'core/hooks/useBooleanState';
import {
  COACH_VIEW,
  LEARNING_VIEW,
  INTERNAL_MANAGE_ORG_PARAMS,
} from 'core/utils/permissions';

const Home = observer(() => {
  const { data, isLoading, error } = useGetFeed();

  const { userStore } = useStores();
  const { user, hasPermission } = userStore;

  const [isOnboarding, toggleIsOnboarding] = useBooleanState(
    !user.hasOnboarded,
  );

  return (
    <Layout showNavigation showFooter>
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Typography variant="body2">
            <Moment format="dddd, MMM D">{moment()}</Moment>
          </Typography>
          <Typography
            variant="h1"
            sx={{ pb: 3 }}
            onClick={
              hasPermission({ requiredPerms: [INTERNAL_MANAGE_ORG_PARAMS] }) &&
              toggleIsOnboarding
            }
          >
            <FormattedMessage
              id="home.title"
              values={{ preferredName: user.user.preferredName }}
            />
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={8}>
              {data.growContent.currentPrograms.length > 0 && (
                <Gate permissions={[LEARNING_VIEW]}>
                  <Paths programs={data.growContent.currentPrograms} />
                  <Circles programs={data.growContent.currentPrograms} />
                </Gate>
              )}
              {data.growContent.currentPrograms.length === 0 &&
                !hasPermission({ requiredPerms: [COACH_VIEW] }) && (
                  <Gate permissions={[LEARNING_VIEW]}>
                    <EmptyState
                      description={
                        <FormattedMessage
                          id={`home.grow.empty.${
                            data.growContent.pastPrograms.length > 0
                              ? 'old'
                              : 'new'
                          }`}
                        />
                      }
                      image={<img src={EmptyStateImage} width={300} alt="" />}
                    />
                  </Gate>
                )}
              <Gate permissions={[COACH_VIEW]}>
                <Coach decks={data.coachContent.coachContent} />
              </Gate>
            </Grid>
            <Grid xs={12} md={4}>
              <Skills />
              <Gate permissions={[LEARNING_VIEW]}>
                <Badges />
              </Gate>
              <Gate permissions={[COACH_VIEW]}>
                <Interests />
              </Gate>
            </Grid>
          </Grid>
          <Gate permissions={[COACH_VIEW]}>
            <Onboarding
              open={isOnboarding}
              onClose={toggleIsOnboarding}
              username={user.user.preferredName}
            />
          </Gate>
          <Alerts />
        </ErrorBoundary>
      )}
      {isLoading && <EmptyState isLoading />}
    </Layout>
  );
});

Home.displayName = 'Home';
export default Home;
