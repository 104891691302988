import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ContentRenderer from 'components/ContentRenderer';

const Page = ({ content }) => {
  return (
    <Box my={3} mx={{ xs: 0, md: 3 }}>
      <ContentRenderer content={content} />
    </Box>
  );
};

Page.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Page;
