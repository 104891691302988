import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Box from '@mui/material/Box';

import TaggableChip from './TaggableChip';

const Tagging = ({ id, options, sourceDataValue = [] }) => {
  const [{ value: selected = [] }, , helpers] = useField({ name: id });

  const onClick = (targetWord) => () => {
    if (selected.includes(targetWord)) {
      helpers.setValue(selected.filter((word) => word !== targetWord));
    } else {
      helpers.setValue([...selected, targetWord]);
    }
  };

  return (
    <Box>
      {(options || sourceDataValue).map(({ label, value }) => (
        <TaggableChip
          key={value}
          label={label}
          value={value}
          onClick={onClick}
          isTagged={selected.includes(value)}
        />
      ))}
    </Box>
  );
};

Tagging.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  sourceDataValue: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
};

Tagging.displayName = 'Tagging';
export default Tagging;
