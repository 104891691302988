import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import Bullet from '@mui/icons-material/Stop';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import OpenIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ErrorBoundary from 'components/ErrorBoundary';
import FilterButton from 'components/Filters/FilterButton';
import FilterList from 'components/Filters/FilterList';
import SearchBar from 'components/SearchBar';
import { useBooleanState } from 'core/hooks/useBooleanState';
import {
  useGetExperienceReferences,
  useGetPathReferences,
  useSaveEvent,
} from 'core/api/path';

const useStyles = makeStyles((theme) => ({
  item: {
    alignItems: 'start',
  },
  bullet: {
    minWidth: theme.spacing(4),
    marginTop: 4,
  },
  filterButton: {
    marginLeft: theme.spacing(6),
  },
  filterList: {
    marginTop: theme.spacing(2),
  },
}));

const References = observer(
  ({ type, programId, contentId, className, parentContentId }) => {
    const intl = useIntl();

    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [filtersExpanded, toggleFiltersExpanded] = useBooleanState(false);
    const [activeFilters, setActiveFilters] = useState({ type: '' });

    const { mutate: saveEvent } = useSaveEvent();

    if (programId !== 'preview') {
      useEffect(() => {
        saveEvent({
          programId,
          contentType: 'REFERENCE',
          contentId,
          parentContentId,
          event: 'opened',
        });
      }, [programId, contentId, saveEvent]);
    }

    const { data: references, isLoading, isSuccess, error } =
      type === 'path'
        ? useGetPathReferences(contentId)
        : useGetExperienceReferences(contentId);

    const onDownloadResource = () => () => {
      if (programId !== 'preview') {
        saveEvent({
          programId,
          contentType: 'REFERENCE',
          contentId,
          parentContentId,
          event: 'downloaded',
        });
      }
    };

    const onSearch = (e) => setSearchTerm(e.target.value);

    const containsSearchTerm = ({ title, authors }) =>
      [title.toLowerCase(), authors.toLowerCase()].some((value) =>
        value.includes(searchTerm.toLowerCase()),
      );

    const isType = ({ type }) =>
      activeFilters.type ? activeFilters.type === type : true;

    const filters = [
      {
        label: intl.formatMessage({ id: 'paths.references.type' }),
        name: 'type',
        options: [
          {
            label: intl.formatMessage({
              id: 'paths.references.article',
            }),
            value: 'Article',
          },
          {
            label: intl.formatMessage({
              id: 'paths.references.book',
            }),
            value: 'Book',
          },
          {
            label: intl.formatMessage({
              id: 'paths.references.guide',
            }),
            value: 'Guide',
          },
          {
            label: intl.formatMessage({
              id: 'paths.references.journal',
            }),
            value: 'Journal',
          },
          {
            label: intl.formatMessage({
              id: 'paths.references.credit',
            }),
            value: 'Credit',
          },
          {
            label: intl.formatMessage({
              id: 'paths.references.video',
            }),
            value: 'Video',
          },
        ],
      },
    ];

    return (
      <ErrorBoundary error={error}>
        <div className={className}>
          {isLoading && (
            <Box m={10} textAlign="center">
              <CircularProgress size={20} />
            </Box>
          )}
          {isSuccess && (
            <>
              <Box display="flex">
                <Box flex="1 1 auto">
                  <SearchBar
                    onChange={onSearch}
                    placeholder={intl.formatMessage({
                      id: 'placeholder.search',
                    })}
                  />
                </Box>
                <FilterButton
                  className={classes.filterButton}
                  onClick={toggleFiltersExpanded}
                  active={filtersExpanded}
                  count={activeFilters['type'] ? 1 : 0}
                />
              </Box>
              <Collapse in={filtersExpanded}>
                <FilterList
                  className={classes.filterList}
                  filters={filters}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                />
              </Collapse>
              <Box mt={2}>
                <List disablePadding>
                  {references
                    .filter(
                      (reference) =>
                        containsSearchTerm(reference) && isType(reference),
                    )
                    .map(
                      ({
                        id,
                        title,
                        authors,
                        type,
                        publicationYear,
                        url,
                        asset,
                      }) => (
                        <ListItem
                          key={id}
                          disableGutters
                          className={classes.item}
                        >
                          <ListItemIcon className={classes.bullet}>
                            <Bullet fontSize="inherit" color="primary" />
                          </ListItemIcon>
                          <Box>
                            <Typography>
                              {`${[authors, title, publicationYear]
                                .filter(Boolean)
                                .join(' ')}.`}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {type}
                            </Typography>
                          </Box>
                          <ListItemSecondaryAction>
                            {(asset || url) && (
                              <a
                                onClick={asset ? onDownloadResource(id) : null}
                                href={asset ? asset.file.url : url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <OpenIcon fontSize="small" color="primary" />
                              </a>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      ),
                    )}
                </List>
              </Box>
            </>
          )}
        </div>
      </ErrorBoundary>
    );
  },
);

References.defaultProps = {
  type: 'path',
};

References.propTypes = {
  type: PropTypes.oneOf(['experience', 'path']),
  programId: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  className: PropTypes.string,
  parentContentId: PropTypes.string.isRequired,
};

References.displayName = 'References';
export default References;
