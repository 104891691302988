import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';

import Card from 'components/Card';

const CustomContentCard = ({
  closeAt,
  description,
  openAt,
  src,
  title,
  uid,
}) => {
  const subtitle = closeAt
    ? `${moment.utc(openAt).format('ll')} - ${moment.utc(closeAt).format('ll')}`
    : moment.utc(openAt).format('ll');

  const actions = (
    <Button variant="outlined" component={Link} to={`/paths/${uid}`}>
      <FormattedMessage id="action.view" />
    </Button>
  );

  return (
    <Card
      title={title}
      subtitle={subtitle}
      content={description}
      image={src}
      actions={actions}
    />
  );
};

CustomContentCard.propTypes = {
  closeAt: PropTypes.string,
  description: PropTypes.string.isRequired,
  openAt: PropTypes.string.isRequired,
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};

CustomContentCard.displayName = 'CustomContentCard';
export default CustomContentCard;
