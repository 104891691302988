import Checkbox from 'components/FormField/Checkbox';
import CheckboxWithLabel from 'components/FormField/CheckboxWithLabel';
import FormControl from './FormControl';
import FormControlLabel from './FormControlLabel';
import FormGroup from './FormGroup';
import DatePicker from './DatePicker';
import PasswordField from './PasswordField';
import PhoneField from './PhoneField';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Switch from './Switch';
import TextField from './TextField';

/**
 * The components within this directory wrap Material UI fields with Formik
 * functionality using hooks.
 *
 */

export {
  Checkbox,
  CheckboxWithLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  DatePicker,
  RadioGroup,
  PasswordField,
  PhoneField,
  Select,
  Switch,
  TextField,
};

export default TextField;
