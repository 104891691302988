import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, FormControlLabel, FormGroup } from 'components/FormField';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  ul: {
    '& > div': {
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    marginBottom: theme.spacing(2),
  },
}));

const MultiSelectGridMobile = ({ id, rowList, columns }) => {
  const classes = useStyles();

  const [currIdx, setCurrIdx] = useState(null);
  const [field, { value: response = {} }] = useField({ name: id });
  const { isSubmitting } = useFormikContext();

  const answerMap = columns.reduce(
    (acc, col) => ({ ...acc, [col.value]: col.label }),
    {},
  );

  const handleCloseQuestionDialog = () => {
    setCurrIdx(null);
  };

  const handleStartQuestion = () => {
    setCurrIdx(0);
  };

  const handleRowClick = (idx) => {
    setCurrIdx(idx);
  };

  const handlePrevious = () => {
    setCurrIdx(currIdx - 1);
  };

  const handleNext = () => {
    if (currIdx === rowList.length - 1) {
      setCurrIdx(null);
    } else {
      setCurrIdx(currIdx + 1);
    }
  };

  return (
    <FormGroup {...field}>
      {Object.keys(response).length === 0 ? (
        <Button onClick={handleStartQuestion} variant="contained">
          Start
        </Button>
      ) : (
        <>
          <List className={classes.ul}>
            {rowList.map((row, idx) => (
              <ListItemButton
                disableGutters
                key={row.value}
                onClick={() => handleRowClick(idx)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText
                  primary={row.label}
                  secondary={
                    response && response[row.value]
                      ? Object.keys(response[row.value])
                          .filter((resVal) => !!response[row.value][resVal])
                          .map((res) => answerMap[res])
                          .join(', ')
                      : null
                  }
                  primaryTypographyProps={{ variant: 'h6' }}
                />
                <NavigateNext />
              </ListItemButton>
            ))}
          </List>
        </>
      )}
      <Dialog
        open={currIdx === 0 || !!currIdx}
        onClose={handleCloseQuestionDialog}
        TransitionComponent={Transition}
        TransitionProps={{
          name: `${id}.${rowList[currIdx]?.value}`,
          id: `${id}.${rowList[currIdx]?.value}`,
        }}
        fullScreen
      >
        <IconButton
          id={`${id}.${rowList[currIdx]?.value}`}
          name={`${id}.${rowList[currIdx]?.value}`}
          onClick={handleCloseQuestionDialog}
          size="large"
          sx={{ justifyContent: 'flex-start' }}
          disableRipple
        >
          <CloseIcon
            id={`${id}.${rowList[currIdx]?.value}`}
            name={`${id}.${rowList[currIdx]?.value}`}
          />
        </IconButton>
        <DialogContent dividers sx={{ padding: '1rem' }}>
          {rowList.map(({ value, label }, idx) => (
            <Box style={{ display: currIdx === idx ? 'inline' : 'none' }}>
              <Typography
                component="h5"
                sx={{ fontWeight: 'bold' }}
                gutterBottom
              >
                {label}
              </Typography>
              <FormControl name={`${id}.${value}`}>
                {rowList[currIdx] &&
                  columns.map(({ value: colValue, label: colLabel }) => {
                    return (
                      <FormControlLabel
                        key={colValue}
                        label={colLabel}
                        checked={response[value] && response[value][colValue]}
                        control={
                          <Checkbox
                            name={`${id}.${value}.${colValue}`}
                            value={!!response[value]?.colValue}
                          />
                        }
                        disabled={isSubmitting}
                      />
                    );
                  })}
              </FormControl>
            </Box>
          ))}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            disabled={currIdx < 1}
            name={`${id}.${rowList[currIdx]?.value}`}
            id={`${id}.${rowList[currIdx]?.value}`}
            onClick={handlePrevious}
            variant="contained"
          >
            <NavigateBefore />
          </Button>
          <Button
            name={`${id}.${rowList[currIdx]?.value}`}
            id={`${id}.${rowList[currIdx]?.value}`}
            onClick={handleNext}
            variant="contained"
          >
            <NavigateNext />
          </Button>
        </DialogActions>
      </Dialog>
    </FormGroup>
  );
};

MultiSelectGridMobile.propTypes = {
  id: PropTypes.string.isRequired,
  rowList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

MultiSelectGridMobile.displayName = 'MultiSelectGridMobile';
export default MultiSelectGridMobile;
