import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function createSvgIcon(path, displayName, other) {
  const Component = React.memo(
    React.forwardRef((props, ref) => (
      <SvgIcon
        {...other}
        {...props}
        data-mui-test={`${displayName}Icon`}
        ref={ref}
      >
        {path}
      </SvgIcon>
    )),
  );

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  return Component;
}
