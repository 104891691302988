import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/FormField/TextField';

const Input = ({ id, placeholder }) => {
  return (
    <TextField
      name={id}
      rows="4"
      placeholder={placeholder}
      multiline
      fullWidth
    />
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

Input.displayName = 'Input';
export default Input;
