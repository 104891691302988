import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { embeddableTypeToComponent } from 'components/ContentRenderer/Embeddable/embeddables';

const Embeddable = ({ id, type, fields, ...otherProps }) => {
  //Emebddables can sometimes be used outside of FormikContext.
  const { values: responses } = useFormikContext() || {};

  const getSourceDataValue = (sourceDataList, toType) => {
    let sourceDataValue = undefined;

    if (sourceDataList && sourceDataList.length > 0) {
      sourceDataList.forEach(({ id: fromId, contentType, ...fields }, idx) => {
        // source activity type or question type (e.g. questionSingleSelect)
        const fromType = fields[contentType].contentType;
        // source activity or question fields
        const sourceDataFields = fields[contentType];
        // source activity or question response
        const response = responses[fromId];

        // format response based on fromType and toType before passing to rendering component
        if (response) {
          switch (fromType) {
            case 'questionInputList': {
              switch (toType) {
                case 'activityDragDrop': {
                  sourceDataValue = sourceDataValue || {};
                  sourceDataValue.sourceOptions = [
                    ...(sourceDataValue.sourceOptions || []),
                    ...response.map((v) => ({ label: v, value: v })),
                  ];

                  break;
                }
                case 'activityTagging':
                case 'questionMultiSelectGrid': {
                  sourceDataValue = sourceDataValue || [];
                  sourceDataValue.push(
                    ...response.map((v) => ({ label: v, value: v })),
                  );

                  break;
                }
                case 'savableAnswerList': {
                  sourceDataValue = sourceDataValue || {};
                  sourceDataValue.items = [
                    ...(sourceDataValue.items || []),
                    ...response.map((v) => ({ value: v })),
                  ];

                  break;
                }
                case 'questionInputList': {
                  sourceDataValue = sourceDataValue || [];
                  sourceDataValue.push(...response);

                  break;
                }
                default:
                  break;
              }
              break;
            }
            case 'questionMultiSelectGrid': {
              switch (toType) {
                case 'contentDynamicTable': {
                  sourceDataValue = sourceDataValue || {};
                  sourceDataValue.contentType = 'check';
                  sourceDataValue.rows = Object.entries(
                    response,
                  ).map(([initials, response]) => [
                    initials,
                    ...sourceDataFields.columns.map(({ value }) =>
                      value in response && response[value] ? true : '',
                    ),
                  ]);

                  break;
                }
                default:
                  break;
              }
              break;
            }
            case 'activityDragDrop': {
              switch (toType) {
                case 'activityDragDrop': {
                  sourceDataValue = sourceDataValue || {};
                  sourceDataValue.sourceSelected = response;

                  break;
                }
                case 'contentDynamicTable': {
                  sourceDataValue = sourceDataValue || {};
                  sourceDataValue.rows = [
                    ...Array(
                      Math.max(...response.map(({ items }) => items.length)),
                    ).keys(),
                  ].map((idx) =>
                    response.map(
                      ({ items }) =>
                        (
                          sourceDataFields.options.find(
                            (option) => option.value === items[idx],
                          ) || {}
                        ).label,
                    ),
                  );

                  break;
                }
                default:
                  break;
              }
              break;
            }
            case 'activitySingleSelectTableToggle': {
              switch (toType) {
                case 'contentDynamicTable': {
                  sourceDataValue = sourceDataValue || {};
                  sourceDataValue.contentType = 'equal';
                  sourceDataValue.map =
                    sourceDataValue.map ||
                    Object.keys(response).reduce(
                      (acc, cur) => ({ ...acc, [cur]: Array(2).fill('') }),
                      {},
                    );

                  Object.keys(sourceDataValue.map).forEach((key) => {
                    sourceDataValue.map[key][idx] = response[key] || '';
                  });
                  Object.keys(response).forEach((key) => {
                    if (!sourceDataValue.map[key]) {
                      sourceDataValue.map[key] = Array(2).fill('');
                      sourceDataValue.map[key][idx] = response[key] || '';
                    }
                  });

                  sourceDataValue.rows = Object.values(sourceDataValue.map)
                    .filter((row) => !row.every((cell) => cell === ''))
                    .map((row) => [
                      row[0],
                      row.includes('') ? '' : row[0] === row[1],
                      row[1],
                    ]);

                  break;
                }
                default:
                  break;
              }
              break;
            }
            default:
              break;
          }
        }
      });
    }
    return sourceDataValue;
  };

  const Component = embeddableTypeToComponent[type];

  return (
    <Component
      id={id}
      type={type}
      getSourceDataValue={getSourceDataValue}
      {...fields}
      {...otherProps}
    />
  );
};

Embeddable.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
};
Embeddable.displayName = 'Embeddable';
export default Embeddable;
