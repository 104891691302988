import React from 'react';
import { useFormikContext } from 'formik';

import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';

import TextField from 'components/FormField';

const DropdownMultiSelect = ({ id, options }) => {
  const { values } = useFormikContext();

  const response = values[id] || [];
  const renderValue = () =>
    response.map((value, idx) => {
      const item = options.find((option) => option.value === value);
      return (
        item && (idx === response.length - 1 ? item.label : `${item.label}, `)
      );
    });

  const MenuProps = {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'top',
    },
    transformOrigin: {
      horizontal: 'left',
      vertical: 'top',
    },
  };

  return (
    <TextField
      id={id}
      name={id}
      margin="dense"
      size="small"
      value={response}
      select
      fullWidth
      variant="standard"
      SelectProps={{ multiple: true, renderValue, MenuProps }}
    >
      {options.map(({ value, label }) => (
        <ListItem key={value} value={value}>
          <Checkbox checked={response.includes(value)} />
          {label}
        </ListItem>
      ))}
    </TextField>
  );
};

DropdownMultiSelect.displayName = 'DropdownMultiSelect';
export default DropdownMultiSelect;
