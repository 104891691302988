import ApiService from './ApiService';

let newTokenRequest;

export const register = (data) => ApiService.post('auth/register', data);

export const sendRegistrationInvite = (email) =>
  ApiService.post('auth/register/invite', { email });

export const sendRegistrationInviteByToken = (token) =>
  ApiService.post('auth/registration-token/regenerate', { token });

export const checkRegistrationToken = (token) =>
  ApiService.get(`auth/register/token/${token}/check`);

export const verifyEmail = (token) =>
  ApiService.get(`auth/verify-email`, { params: { token } });

export const recoverPassword = (email) =>
  ApiService.post('auth/password/recover', { email });

export const updatePassword = async (data) =>
  ApiService.patch('auth/password/update', data);

export const resetPasswordForced = (identifier, currentPassword, newPassword) =>
  ApiService.post('auth/password/reset-forced', {
    identifier,
    currentPassword,
    newPassword,
  });

export const resetPassword = (token, password) =>
  ApiService.post('auth/password/reset', { token, password });

export const checkUsername = (username) =>
  ApiService.get(`auth/username/${username}/check`);

export const getLoginType = (identifier, subdomain) =>
  ApiService.get(`auth/login/${identifier}/type`, {
    params: { subdomain: subdomain },
  });

export const login = (identifier, password, organizationUid) =>
  ApiService.post('auth/login', { identifier, password, organizationUid });

// private, do not use outside AuthService
const forceGetNewToken = async () => {
  const newTokens = await ApiService.post('auth/token/new');
  newTokenRequest = null;
  return newTokens;
};

export const getNewToken = async () => {
  // Only request new tokens once from the server
  // If a request is in progress, return the existing promise
  // so that the subsequent calls await the same initial request
  if (!newTokenRequest) {
    newTokenRequest = forceGetNewToken();
  }
  return newTokenRequest;
};

export const logout = async () => {
  await ApiService.post('auth/logout');
  window.location.href = '/login';
};

export const getEmployeeLoginList = () =>
  ApiService.get('auth/login/request/employee');

export const getUserLoginList = () => ApiService.get('auth/login/request/user');

export const revokeUserLogin = (id) =>
  ApiService.patch(`auth/login/request/user/${id}/revoke`);

export const addUserLogin = (params) =>
  ApiService.post('auth/login/request/user', params);

export const checkServiceAccount = (username) =>
  ApiService.get(`auth/login/request/user/${username}/check`);
