import React from 'react';

import BackArrowMobile from '@mui/icons-material/KeyboardArrowLeft';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NextArrowMobile from '@mui/icons-material/KeyboardArrowRight';

const MobileContainer = ({
  paginate,
  disableNavigation,
  activeStep,
  totalSteps,
  children,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box
        width={{ xs: '100%', md: '600px' }}
        height="100%"
        position="relative"
      >
        {children}
        {activeStep !== 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={60}
            bgcolor="common.white"
            borderTop={1}
            borderColor="divider"
            position="absolute"
            width="100%"
            bottom={0}
          >
            <IconButton
              onClick={() => paginate(-1)}
              disabled={disableNavigation}
              sx={{
                ml: 2,
                border: 2,
                borderColor: 'primary.main',
              }}
              color="primary"
            >
              <BackArrowMobile />
            </IconButton>
            {activeStep !== totalSteps && (
              <IconButton
                type="submit"
                disabled={disableNavigation}
                sx={{
                  mr: 2,
                  border: 2,
                  borderColor: 'primary.main',
                  bgcolor: 'primary.main',
                  color: 'common.white',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
              >
                <NextArrowMobile />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

MobileContainer.displayName = 'MobileContainer';
export default MobileContainer;
