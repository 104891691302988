import React from 'react';
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import AddIcon from '@mui/icons-material/Add';
import BackArrow from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'layouts/Layout';
import { TextField } from 'components/FormField';
import { useGetAttribute, useUpdateAttribute } from 'core/api/user';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

Yup.addMethod(Yup.object, 'isUnique', function (propertyName, errorMessage) {
  return this.test('isUnique', errorMessage, function (item) {
    if (!item || !item[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter((i) => i !== item)
        .some((i) => i[propertyName] === item[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

const ExperienceDialog = ({ open, onClose }) => {
  const intl = useIntl();

  const { data, isLoading, error } = useGetAttribute('identities');
  const { identities } = data || {};

  const { mutateAsync } = useUpdateAttribute();

  const validationSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object()
        .shape({
          value: Yup.string().required(
            intl.formatMessage({ id: 'error.input.empty' }),
          ),
        })
        .isUnique('value', intl.formatMessage({ id: 'error.identity.exists' })),
    ),
  });

  const onSubmit = async (values) =>
    await mutateAsync(
      { type: 'identities', values: values.fields },
      {
        onSuccess: () =>
          successNotification(
            intl.formatMessage({
              id: 'profile.experiences.identities.update.success',
            }),
          ),
        onError: (err) => errorNotification(err.response.data.message),
        onSettled: () => onClose(),
      },
    );

  const emptyRow = { value: '', shareTypes: [] };
  const initialValues = {
    fields: identities?.length ? identities : [emptyRow],
  };

  return (
    <Dialog open={open} fullScreen>
      <Layout showNavigation align="center">
        {!isLoading && (
          <ErrorBoundary error={error}>
            <IconButton
              onClick={onClose}
              sx={{
                position: { xs: 'relative', md: 'absolute' },
                mb: { xs: 1, md: 0 },
              }}
            >
              <BackArrow />
            </IconButton>
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                width: { xs: '100%', md: 600 },
                margin: 'auto',
                borderRadius: 2,
                minHeight: '100%',
              }}
            >
              <Box
                p={3}
                bgcolor="primary.main"
                color="common.white"
                borderRadius="8px 8px 0px 0px"
              >
                <Typography variant="h2" color="inherit">
                  <FormattedMessage id="profile.experiences.title" />
                </Typography>
              </Box>
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {({ values, isValid, isSubmitting }) => (
                  <Form>
                    <FieldArray
                      name="fields"
                      render={(arrayHelpers) => (
                        <Box p={3}>
                          {values.fields.map((row, idx) => (
                            <Box display="flex" alignItems="center">
                              <TextField
                                label={
                                  <FormattedMessage id="profile.experiences.identity" />
                                }
                                name={`fields.${idx}.value`}
                                fullWidth
                                margin="dense"
                              />
                              <IconButton
                                onClick={() => arrayHelpers.remove(idx)}
                                sx={{ ml: 1 }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          ))}
                          <Button
                            startIcon={<AddIcon />}
                            onClick={() => arrayHelpers.push(emptyRow)}
                          >
                            <FormattedMessage id="action.addNew" />
                          </Button>
                          <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            sx={{ mt: 3 }}
                            disabled={
                              !isValid ||
                              isSubmitting ||
                              (!identities.length && !values.fields.length)
                            }
                          >
                            <FormattedMessage id="action.save" />
                          </Button>
                        </Box>
                      )}
                    />
                  </Form>
                )}
              </Formik>
            </Paper>
          </ErrorBoundary>
        )}
      </Layout>
    </Dialog>
  );
};

ExperienceDialog.displayName = 'ExperienceDialog';
export default ExperienceDialog;
