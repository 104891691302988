import React, { useState } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';

import Chip from 'components/ContentRenderer/Embeddable/embeddables/components/Chip';

const InputList = ({ id, placeholder, helperText, sourceDataValue = [] }) => {
  const [{ value: words = [] }, , { setValue: setWords }] = useField({
    name: id,
  });
  const [word, setWord] = useState('');

  const addWord = (wordToAdd) => {
    if (
      wordToAdd &&
      !words.includes(wordToAdd) &&
      !sourceDataValue.includes(wordToAdd)
    ) {
      setWords([wordToAdd, ...words]);
    }
    setWord('');
  };

  const deleteWord = (wordToDelete) => {
    setWords(words.filter((w) => w !== wordToDelete));
  };

  return (
    <>
      <TextField
        helperText={helperText}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => addWord(word)} size="large">
                <AddIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        value={word}
        onChange={(e) => setWord(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            addWord(word);
          }
        }}
      />
      <Box mt={2}>
        {words.map((word, index) => (
          <Chip key={index} label={word} onDelete={() => deleteWord(word)} />
        ))}
        {sourceDataValue.map((word, index) => (
          <Chip key={index} label={word} />
        ))}
      </Box>
    </>
  );
};
InputList.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  sourceDataValue: PropTypes.arrayOf(PropTypes.string.isRequired),
};

InputList.displayName = 'InputList';
export default InputList;
