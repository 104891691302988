import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import BackArrow from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';

import Layout from 'layouts/Layout';
import SearchInput from './SearchInput';
import IconButton from '@mui/material/IconButton';

const Search = () => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  const openSearch = () => {
    setIsOpen(true);
    inputRef.current.blur();
  };

  const closeSearch = () => setIsOpen(false);

  return (
    <Box>
      <OutlinedInput
        fullWidth
        onFocus={openSearch}
        inputRef={inputRef}
        placeholder={intl.formatMessage({ id: 'placeholder.search' })}
        sx={{ bgcolor: 'white', color: 'text.secondary' }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon htmlColor="#525252" />
          </InputAdornment>
        }
      />
      <Dialog open={isOpen} fullScreen>
        <Layout showNavigation color="light">
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
          >
            <IconButton onClick={closeSearch} sx={{ mb: 1 }}>
              <BackArrow />
            </IconButton>
            <SearchInput onClose={closeSearch} />
          </Box>
        </Layout>
      </Dialog>
    </Box>
  );
};

Search.displayName = 'Search';
export default Search;
