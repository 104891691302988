import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import DropContainer from 'components/ContentRenderer/Embeddable/embeddables/components/DropContainer';
import Draggable from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/DragDrop/components/DraggableBankBlock';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
}));

const DraggableBlockBank = ({ options, unselected, isDisabled }) => {
  const classes = useStyles();

  return (
    <DropContainer
      droppableId="unselected"
      isDropDisabled={isDisabled}
      classes={{ root: classes.root }}
    >
      {unselected.map((value, index) => {
        const option = options.find((option) => option.value === value);
        return (
          <Draggable
            key={index}
            index={index}
            isDisabled={isDisabled}
            {...option}
          />
        );
      })}
    </DropContainer>
  );
};

DraggableBlockBank.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string,
      color: PropTypes.string,
      avatar: PropTypes.string,
    }),
  ).isRequired,
  unselected: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
};

DraggableBlockBank.displayName = 'DraggableBlockBank';
export default DraggableBlockBank;
