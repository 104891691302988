import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      padding: 7,
      '& .MuiSwitch-switchBase': {
        // checked & enabled
        '&.Mui-checked': {
          '+ .MuiSwitch-track': {
            backgroundColor: palette.primary.main,
            opacity: 1,
          },
        },
        // unchecked & disabled
        '&.Mui-disabled': {
          '+ .MuiSwitch-track': {
            backgroundColor: '#D4D4D4',
            opacity: 1,
          },
        },
        // checked & disabled
        '&.Mui-checked.Mui-disabled +.MuiSwitch-track': {
          backgroundColor: '#B9CACC',
          opacity: 1,
        },
      },
      '& .MuiSwitch-thumb': {
        color: palette.common.white,
        boxShadow: 'none',
      },
      // unchecked & enabled
      '& .MuiSwitch-track': {
        borderRadius: 20,
        backgroundColor: '#A3A3A3',
        opacity: 1,
      },
    },
  },
};
