import { useStores } from 'core/hooks/useStores';

import { useEffect } from 'react';

const Logout = () => {
  const { authStore } = useStores();
  const { logout } = authStore;

  useEffect(() => {
    logout();
  }, [logout]);
  return null;
};

export default Logout;
