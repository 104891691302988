import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

import CardFace from 'components/ContentRenderer/Embeddable/embeddables/CardFlip/Card/CardFace';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    position: 'relative',
  },
}));

const Card = ({ faces }) => {
  const classes = useStyles();

  const [state, setState] = useState({ isFlipped: false, activeFace: 'front' });

  const onClick = (value) => () =>
    setState((prevState) => ({
      activeFace: value,
      isFlipped: !prevState.isFlipped,
    }));

  return (
    <div className={classes.container}>
      {faces.map((face, idx) => (
        <CardFace key={idx} onClick={onClick} {...state} {...face} />
      ))}
    </div>
  );
};

Card.propTypes = {
  faces: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.string,
      color: PropTypes.oneOf(['teal', 'orange', 'purple']),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          displayName: PropTypes.string,
        }),
      ),
    }),
  ),
};

Card.displayName = 'Card';
export default Card;
