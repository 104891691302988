import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import FormWizard, { FormWizardContext } from './FormWizard';

const FormWizardActions = ({
  cancelButtonLabel,
  cancelButtonProps,
  nextButtonLabel,
  nextButtonProps,
  previousButtonLabel,
  previousButtonProps,
  submitButtonLabel,
  submitButtonProps,
  secondaryAction,
  children,
}) => {
  const {
    isFirstStep,
    isLastStep,
    previousStep,
    isSubmitting,
    onCancel,
    totalSteps,
  } = useContext(FormWizardContext);

  if (children)
    return children({
      isFirstStep,
      isLastStep,
      previousStep,
      isSubmitting,
      onCancel,
      totalSteps,
    });

  return (
    <>
      {onCancel && (
        <Button onClick={onCancel} {...cancelButtonProps}>
          {cancelButtonLabel}
        </Button>
      )}
      {totalSteps > 1 && (
        <Button
          disabled={isFirstStep}
          onClick={previousStep}
          {...previousButtonProps}
        >
          {previousButtonLabel}
        </Button>
      )}
      {!isLastStep && (
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          {...nextButtonProps}
        >
          {isSubmitting ? <CircularProgress size={15} /> : nextButtonLabel}
        </Button>
      )}
      {isLastStep && (
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          {...submitButtonProps}
        >
          {isSubmitting ? <CircularProgress size={15} /> : submitButtonLabel}
        </Button>
      )}
      {!!secondaryAction && secondaryAction}
    </>
  );
};

FormWizardActions.defaultProps = {
  cancelButtonLabel: 'Cancel',
  nextButtonLabel: 'Next',
  previousButtonLabel: 'Back',
  submitButtonLabel: 'Save',
};

FormWizard.propTypes = {
  cancelButtonLabel: PropTypes.string,
  cancelButtonProps: PropTypes.object,
  nextButtonLabel: PropTypes.string,
  nextButtonProps: PropTypes.object,
  previousButtonLabel: PropTypes.string,
  previousButtonProps: PropTypes.object,
  submitButtonLabel: PropTypes.string,
  submitButtonProps: PropTypes.object,
  secondaryAction: PropTypes.node,
};

FormWizardActions.displayName = 'FormWizardActions';
export default FormWizardActions;
