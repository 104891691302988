import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Employees from './Employees';
import Gate from 'components/Gate';
import Users from './Users';
import { INTERNAL_MANAGE_ORG_PARAMS } from 'core/utils/permissions';

const Service = () => {
  return (
    <Box flex="1 1 auto" ml={{ xs: 0, sm: 5, md: 7 }}>
      <Typography variant="h1" sx={{ mb: 5 }}>
        <FormattedMessage id="profile.settings.service" />
      </Typography>
      <Users />
      <Gate permissions={[INTERNAL_MANAGE_ORG_PARAMS]}>
        <Employees />
      </Gate>
    </Box>
  );
};

Service.displayName = 'Service';
export default Service;
