import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import Layout from 'layouts/BasicLayout';
import Welcome from './Welcome';
import Categories from './Categories';
import Skills from './Skills';
import Loading from './Loading';

const Onboarding = ({ open, ...props }) => {
  const [step, setStep] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '.MuiDialog-paper': { bgcolor: 'background.default' },
        zIndex: (theme) => theme.zIndex.modal + 3,
      }}
    >
      <Layout color={isMobile ? 'light' : 'dark'}>
        <Paper
          elevation={0}
          variant={isMobile ? 'elevation' : 'outlined'}
          sx={{
            maxWidth: { xs: '100%', sm: 600 },
            minHeight: { xs: '100%', sm: 600 },
            borderRadius: { xs: 0, sm: 4 },
            p: 5,
            pt: { xs: 13, sm: 5 },
            margin: 'auto',
            mt: { xs: 0, sm: 15 },
          }}
        >
          {step === 0 && <Welcome setStep={setStep} {...props} />}
          {step === 1 && <Categories setStep={setStep} {...props} />}
          {step === 2 && <Skills setStep={setStep} {...props} />}
          {step === 3 && <Loading setStep={setStep} {...props} />}
        </Paper>
      </Layout>
    </Dialog>
  );
};

Onboarding.propTypes = {
  ...Dialog.propTypes,
};

Onboarding.displayName = 'Onboarding';
export default Onboarding;
