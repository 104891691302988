import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

const AccessibilityDialog = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={isMobile} {...props}>
      <DialogTitle>
        <Box pl={2}>
          <Typography
            variant="h3"
            color="primary"
            component="h1"
            data-test="privacy-title"
          >
            <FormattedMessage id="accessibility.title" />
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box px={2}>
          <Typography paragraph>
            <FormattedMessage id="accessibility.description" />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="contained"
          data-test="close-button"
        >
          <FormattedMessage id="action.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccessibilityDialog.propTypes = {
  ...Dialog.propTypes,
};

AccessibilityDialog.displayName = 'AccessibilityDialog';

export default AccessibilityDialog;
