import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';

import Box from '@mui/material/Box';

import Step from './Step';
import { errorNotification } from 'components/Notifications';

import { useSaveEvent } from 'core/api/circle';
import { useSaveResponses } from 'core/api/circle';

const Steps = ({ content: { steps }, ...program }) => {
  const [responses, setResponses] = useState(program.responses);
  const [expandedStepId, setExpandedStepId] = useState();
  const { mutate: saveEvent } = useSaveEvent();
  const intl = useIntl();
  const { mutateAsync: saveResponses, isLoading } = useSaveResponses();

  const handleExpandChange = async (stepId) => {
    setExpandedStepId(stepId !== expandedStepId ? stepId : null);
    if (!!stepId) {
      await saveEvent(
        {
          programId: program.id,
          contentType: 'LEARNING_CIRCLE_STEP',
          contentId: stepId,
          parentContentId: program.contentId,
          event: 'completed',
        },
        {
          onSuccess: () => {
            // update program properties with new values instead of re-fetching from api
            program.itemStatus[stepId] = { completedAt: new Date() };
          },
          onError: () =>
            errorNotification(intl.formatMessage({ id: 'error.generic' })),
        },
      );
    }
  };

  const handleNext = (stepId) => () => {
    const stepIdx = steps.findIndex((s) => s.id === stepId);
    if (stepIdx === steps.length - 1) {
      handleExpandChange(null);
    } else {
      handleExpandChange(steps[stepIdx + 1].id);
    }
  };
  const isClosed = program.isClosed;
  const isPreview = program.isPreview;

  const onSubmit = async (values) => {
    if (isPreview || isClosed) {
      handleNext();
      return;
    }
    if (Object.keys(values).length > 0) {
      await saveResponses(
        {
          programId: program.id,
          contentType: 'circle',
          contentId: program.contentId,
          responses: values,
        },
        {
          onError: () =>
            errorNotification(intl.formatMessage({ id: 'error.generic' })),
          onSuccess: () => {
            setResponses(values);
          },
        },
      );
    }
  };

  return (
    <Box my={3}>
      {steps.map((step, idx) => (
        <Formik key={idx} initialValues={responses || {}} onSubmit={onSubmit}>
          {(props) => (
            <Form noValidate>
              <Step
                key={step.id}
                program={program}
                step={step}
                expanded={step.id === expandedStepId}
                onExpandChange={() => handleExpandChange(step.id)}
                hasNext={step.id !== steps[steps.length - 1].id}
                onNext={handleNext(step.id)}
                isLoading={isLoading}
                {...props}
              />
            </Form>
          )}
        </Formik>
      ))}
    </Box>
  );
};

Steps.propTypes = {
  content: PropTypes.shape({
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        duration: PropTypes.string,
        content: PropTypes.object.isRequired,
      }),
    ).isRequired,
  }),
};

Steps.displayName = 'Steps';
export default Steps;
