import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import ArrowIcon from 'components/SvgIcons/Arrow';
import DotIndicator from './DotIndicator';

import { MenuContext } from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/SingleSelectTableDropdown';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'pre-wrap',
    },
  },
  arrow: {
    color: theme.palette.primary.main,
    transform: 'rotate(90deg)',
    width: 18,
  },
  menuItem: {
    whiteSpace: 'pre-wrap',
  },
}));

const Menu = ({ options, placeholder, isUnique }) => {
  const {
    value,
    answers,
    rowValue,
    columnValue,
    type,
    showActual,
    onChange,
  } = useContext(MenuContext);
  const classes = useStyles();
  const onMenuItemClick = (e) => onChange(e.target.value);

  const disabledOptions = Object.entries(answers).reduce(
    (acc, [row, column]) => {
      if (row !== rowValue) acc.push(column[columnValue]);
      return acc;
    },
    [],
  );

  if (showActual && type === 'answer') {
    const option = options.find(
      (o) => o.value === answers[rowValue][columnValue],
    );
    return (option && option.label) || '-';
  }

  return (
    <MuiSelect
      variant="standard"
      className={classes.input}
      classes={{ select: classes.select }}
      value={value}
      onChange={onMenuItemClick}
      displayEmpty
      disableUnderline
      IconComponent={({ className }) => (
        <ArrowIcon className={cn(classes.arrow, className)} />
      )}
    >
      <MenuItem value="" className={classes.menuItem}>
        {placeholder}
      </MenuItem>
      {options.map(({ label, value, color }) => (
        <MenuItem
          key={value}
          value={value}
          disabled={isUnique && disabledOptions.includes(value)}
          className={classes.menuItem}
        >
          <Grid container sx={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <Grid xs="auto">{color && <DotIndicator color={color} />}</Grid>
            <Grid xs="auto">
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {label}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

Menu.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string,
      color: PropTypes.oneOf([
        'green',
        'orange',
        'teal',
        'purple',
        'yellow',
        'navy',
        'burgundy',
      ]),
      avatar: PropTypes.string,
    }),
  ).isRequired,
  isUnique: PropTypes.bool,
};

Menu.displayName = 'Menu';
export default Menu;
