import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import PersonalDataIcon from 'components/SvgIcons/PersonalDataIcon';
import { questionTypeToComponent } from './questions';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'middle',
  },
}));

const Question = ({
  id,
  getSourceDataValue,
  sourceData,
  question,
  saveToProfile,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { id: questionId, title, contentType, ...questionFields } = question;
  const sourceDataValue = getSourceDataValue(sourceData, contentType);

  const Component = questionTypeToComponent[contentType];

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Typography variant="h6" display="inline">
          {title}
        </Typography>
        {saveToProfile && (
          <Tooltip title={intl.formatMessage({ id: 'tooltip.personalData' })}>
            <PersonalDataIcon className={classes.icon} viewBox="0 0 22 22" />
          </Tooltip>
        )}
      </Box>
      <Component
        id={id}
        sourceDataValue={sourceDataValue}
        {...questionFields}
      />
    </Box>
  );
};

Question.propTypes = {
  id: PropTypes.string.isRequired,
  getSourceDataValue: PropTypes.func.isRequired,
  sourceData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  question: PropTypes.object.isRequired,
  saveToProfile: PropTypes.bool,
};
Question.displayName = 'Question';
export default Question;
