import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DotIcon from '@mui/icons-material/FiberManualRecord';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import Chart from 'components/Chart';
import ErrorBoundary from 'components/ErrorBoundary';
import Label from 'components/Label';
import { useGetQuestionInsights } from 'core/api/circle';

const CHART_COLORS = [
  '#00E396',
  '#775DD0',
  '#FF9800',
  '#FF4560',
  '#2B908F',
  '#5A2A27',
  '#2E294E',
];

const QuestionInsights = observer(({ sourceQuestions, programId }) => {
  const [refreshInterval, setRefreshInterval] = useState(1000 * 7);
  const authRefresh = refreshInterval !== 0;
  const toggleAutoRefresh = () => {
    setRefreshInterval(refreshInterval === 0 ? 1000 * 7 : 0);
  };
  const isPreview = programId === 'preview';

  const { data: questionInsights, isLoading, error } = isPreview
    ? { data: { questionInsights: {} } }
    : useGetQuestionInsights(
        programId,
        sourceQuestions.map((q) => q.id),
        refreshInterval,
      );

  return isLoading ? (
    <Box m={20} textAlign="center">
      <CircularProgress />
    </Box>
  ) : (
    <Box m={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          <FormattedMessage id="questionInsights.pollCount" />:{' '}
          {questionInsights?.pollCount || 0}
        </Typography>
        <Box>
          <Switch
            checked={authRefresh}
            onChange={toggleAutoRefresh}
            name="autoRefresh"
          />
          <Label color={authRefresh ? 'success' : 'disabled'}>
            <FormattedMessage id="questionInsights.live" />
          </Label>
        </Box>
      </Box>

      <ErrorBoundary error={error}>
        {questionInsights?.pollCount ? (
          sourceQuestions.map(({ id, question: { title, options } }, idx) => {
            const series = options
              .map((option) => ({
                ...option,
                percent:
                  questionInsights?.[id]?.find(
                    ({ value }) => value === option.value,
                  )?.percent || 0,
              }))
              .sort(
                (a, b) =>
                  parseFloat(b.percent) - parseFloat(a.percent) === 0
                    ? a.label.localeCompare(b.label) // sorting alphabetically
                    : parseFloat(b.percent) - parseFloat(a.percent), // sorting by percent
              );

            const chartSeries = series.map((o) => o.percent);
            const chartLabels = series.map((o) => o.label);
            const topResponses = series.slice(0, 3);

            return (
              <Box my={2} key={`question-${idx}`}>
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
                <Box mt={2}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid>
                      <Chart
                        width={170}
                        type="donut"
                        series={chartSeries}
                        options={{
                          colors: CHART_COLORS,
                          labels: chartLabels,
                          tooltip: {
                            y: {
                              formatter: (v) => v.toFixed() + '%',
                            },
                          },
                          chart: {
                            sparkline: {
                              enabled: true,
                            },
                            selection: {
                              enabled: false,
                            },
                          },
                          states: {
                            hover: {
                              filter: {
                                type: 'none',
                              },
                            },
                            active: {
                              filter: {
                                type: 'none',
                              },
                            },
                          },
                          plotOptions: {
                            pie: {
                              expandOnClick: false,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            width: 0.5,
                          },
                        }}
                      />
                    </Grid>
                    <Grid md>
                      <List dense>
                        {topResponses.map((o, idx) => (
                          <ListItem disableGutters key={`${o.label}-${idx}`}>
                            <ListItemIcon>
                              <DotIcon style={{ color: CHART_COLORS[idx] }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={o.label}
                              secondary={`${o.percent.toFixed()}%`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            );
          })
        ) : (
          <Alert
            severity="warning"
            sx={{ mt: 2, px: 1, py: 0, borderRadius: '6px' }}
          >
            <FormattedMessage id="questionInsights.empty" />
          </Alert>
        )}
      </ErrorBoundary>
    </Box>
  );
});

QuestionInsights.propTypes = {
  sourceQuestions: PropTypes.array,
  programId: PropTypes.string.isRequired,
};

export default QuestionInsights;
