import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import CircleIcon from '@mui/icons-material/Circle';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';

import AccessibilityDialog from 'components/AccessibilityDialog';
import Logo from 'components/Logo';
import PrivacyDialog from 'components/PrivacyDialog';
import { useStores } from 'core/hooks/useStores';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5),
  },
  logo: {
    position: 'absolute',
    right: theme.spacing(2),
  },
}));

const Footer = () => {
  const classes = useStyles();

  const { userStore } = useStores();
  const { user } = userStore;
  const { organizationLogo } = user ? user.organization.params : '';

  const [openDialogName, setOpenDialogName] = useState();
  const openDialog = (name) => () => setOpenDialogName(name);
  const closeDialog = () => setOpenDialogName(null);

  return (
    <footer role="contentinfo" className={classes.footer}>
      <Link
        component="button"
        onClick={openDialog('privacy')}
        color="primary"
        underline="hover"
        variant="body2"
      >
        <FormattedMessage id="navigation.privacy" />
      </Link>
      <CircleIcon sx={{ fontSize: 6, color: 'divider', mx: 1.5 }} />
      <Link
        component="button"
        onClick={openDialog('accessibility')}
        color="primary"
        underline="hover"
        variant="body2"
      >
        <FormattedMessage id="navigation.accessibility" />
      </Link>
      {openDialogName === 'accessibility' && (
        <AccessibilityDialog open onClose={closeDialog} />
      )}
      {openDialogName === 'privacy' && (
        <PrivacyDialog open onClose={closeDialog} maxWidth="md" />
      )}
      {organizationLogo && (
        <Logo alt="" useDefault height={18} className={classes.logo} />
      )}
    </footer>
  );
};

Footer.displayName = 'Footer';
export default Footer;
