import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useStores } from 'core/hooks/useStores';

import Tracker from 'core/utils/tracker';

const UnauthenticatedRoute = ({ setTargetUrl, component, ...rest }) => {
  const { userStore, authStore } = useStores();
  const loggedIn = userStore.loggedIn;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn) {
          const query = new URLSearchParams(window.location.search);
          const targetUrl = query.get('targetUrl') || authStore.popTargetUrl();
          return <Redirect to={targetUrl} />;
        } else {
          Tracker.trackPageView(
            props.location.pathname + props.location.search,
          );
          return React.createElement(component, { ...props });
        }
      }}
    />
  );
};

UnauthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

UnauthenticatedRoute.displayName = 'UnauthenticatedRoute';

export default UnauthenticatedRoute;
