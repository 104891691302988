import ReactGA from 'react-ga';

/**
 * Wrapper for tracking website stats to Google Analytics.
 *
 * Test mode is enabled in all non-production environments.
 * To enable debug mode, append "?ga=true" to the url.
 */
export default class Tracker {
  static initialize = (trackingId, testMode) =>
    ReactGA.initialize(trackingId, {
      debug: window.location.search.includes('ga'),
      testMode,
    });

  static set = ReactGA.set;

  static trackPageView = (path) => {
    try {
      ReactGA.pageview(path);
    } catch (e) {
      console.error('Tracker error: Unable to fire page view');
    }
  };

  static trackEvent = (category, action, label) => {
    try {
      ReactGA.event({
        category,
        action,
        label,
      });
    } catch (e) {
      console.error('Tracker error: Unable to fire event');
    }
  };
}
