import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <svg width="24" height="24" fill="none">
    <rect
      x="5.625"
      y="7.125"
      width="12.75"
      height="12.75"
      rx="6.375"
      stroke="#212C2C"
      strokeWidth="2.25"
    />
    <rect
      x="15.22"
      y="11.469"
      width="4.5"
      height="1.5"
      rx=".75"
      transform="rotate(135 15.22 11.469)"
      fill="#212C2C"
    />
    <rect
      x="9.75"
      y="3.75"
      width="4.5"
      height="1.5"
      rx=".75"
      fill="#D8D8D8"
      stroke="#212C2C"
      strokeWidth="1.5"
    />
    <rect
      x="11.25"
      y="3.75"
      width="1.5"
      height="3"
      rx=".75"
      fill="#D8D8D8"
      stroke="#212C2C"
      strokeWidth="1.5"
    />
  </svg>,
  'TimerIcon',
);
