import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';

const Coach = ({ decks }) => {
  return (
    <Paper
      sx={{ mb: 2, p: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      <List
        disablePadding
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">
              <FormattedMessage id="home.coach.title" />
            </Typography>
            <Button component={Link} to="/discover">
              <FormattedMessage id="action.viewAll" />
            </Button>
          </ListSubheader>
        }
      >
        {decks.map((deck, idx) => (
          <CoachListItem key={idx} {...deck} />
        ))}
      </List>
    </Paper>
  );
};

Coach.displayName = 'Coach';
export default Coach;
