import { action, observable } from 'mobx';
import { task } from 'mobx-task';

import { AuthService } from 'core/services';
import ApiService, { statusCodes } from 'core/services/ApiService';

export default class AuthStore {
  @observable
  targetUrl = '/';

  constructor(userService) {
    ApiService.setResponseInterceptor(null, this.onResponseError);
    this.userService = userService;
  }

  @action
  onResponseError = async (error) => {
    const { response = {}, config } = error;
    const status = response.status || statusCodes.INTERNAL_SERVER_ERROR;

    // If the user is logged in and there is an authorization error
    if (status === statusCodes.UNAUTHORIZED) {
      // And their jwt token expired...
      if (response.data.code === 'ERROR_TOKEN_EXPIRED') {
        // Then use the refresh token to get a new jwt token
        await AuthService.getNewToken();
        // And retry the original request, but with the new jwt token
        return ApiService.request(config);
      } else {
        // And if they're just unauthorized w/ an invalid or old token, then logout
        if (this.userService.loggedIn) {
          await this.logout();
        }
      }
    }
    return Promise.reject(error);
  };

  @action
  setTargetUrl = (url) => {
    this.targetUrl = url || '/';
  };

  @action
  popTargetUrl = () => {
    const value = this.targetUrl;
    this.setTargetUrl(null);
    return value;
  };

  @task.resolved
  login = async (identifier, password, organizationUid) => {
    const data = await AuthService.login(identifier, password, organizationUid);
    if (!!data.loggedIn) {
      this.userService.setLoggedIn(true);
    } else {
      return data;
    }
  };

  @action
  getNewToken = AuthService.getNewToken;

  @task
  getLoginType = (identifier, subdomain) =>
    AuthService.getLoginType(identifier, subdomain);

  @task.resolved
  register = (data) => AuthService.register(data);

  @task.resolved
  sendRegistrationInviteByToken = async (token) =>
    AuthService.sendRegistrationInviteByToken(token);

  @task.resolved
  sendRegistrationInvite = async (email) =>
    AuthService.sendRegistrationInvite(email);

  @task.resolved
  recoverPassword = async (email) => AuthService.recoverPassword(email);

  @task
  updatePassword = async (data) => AuthService.updatePassword(data);

  @task.resolved
  resetPassword = async (token, password) =>
    AuthService.resetPassword(token, password);

  @task.resolved
  resetPasswordForced = async (identifier, currentPassword, newPassword) =>
    AuthService.resetPasswordForced(identifier, currentPassword, newPassword);

  @task.resolved
  checkRegistrationToken = async (token) =>
    AuthService.checkRegistrationToken(token);

  @task
  verifyEmail = async (token) => AuthService.verifyEmail(token);

  @task.resolved
  checkUsername = async (username) => {
    try {
      await AuthService.checkUsername(username);
      return true;
    } catch (e) {
      return false;
    }
  };

  @task.resolved
  logout = async () => {
    await AuthService.logout();
  };
}
