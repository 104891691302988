import React from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Gate from 'components/Gate';

const NavigationItem = ({
  pathname,
  isActive,
  activeIcon: ActiveIcon,
  icon: Icon,
  label,
  GateProps,
  ButtonProps,
  TypographyProps,
  color,
}) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Gate {...GateProps}>
      <ListItemButton
        component={NavLink}
        to={{
          pathname,
          search: window.location.search,
        }}
        onClick={() => location.pathname === pathname && history.go(0)}
        {...ButtonProps}
      >
        <ListItemIcon sx={{ minWidth: 32 }}>
          {isActive ? <ActiveIcon color="primary" /> : <Icon />}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            sx: {
              color: isActive ? 'primary.main' : color,
              fontWeight: isActive ? 700 : 400,
            },
            ...TypographyProps,
          }}
        />
      </ListItemButton>
    </Gate>
  );
};

NavigationItem.displayName = 'NavigationItem';
export default NavigationItem;
