import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useStores } from 'core/hooks/useStores';
import { PasswordField } from 'components/FormField';

const PasswordResetForm = ({ identifier, password, onSuccess }) => {
  const intl = useIntl();
  const { authStore } = useStores();
  const { resetPasswordForced, login } = authStore;

  const onSubmit = async ({ newPassword }, { setSubmitting, setErrors }) => {
    try {
      setErrors({});
      await resetPasswordForced(identifier, password, newPassword);
      await login(identifier, newPassword);
      onSuccess();
    } catch (e) {
      const error = e.response.data?.error;
      if (error === 'ERROR_RATE_LIMITED') {
        setErrors({
          newPassword: 'Too many login attempts. Try again in 60 seconds.',
        });
      } else if (error === 'ERROR_UNAUTHENTICATED') {
        setErrors({
          newPassword: intl.formatMessage(
            { id: 'error.input.invalid' },
            { field: 'password' },
          ),
        });
      } else {
        setErrors({
          newPassword: intl.formatMessage({ id: 'error.generic' }),
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{ newPassword: '', confirmPassword: '' }}
      validationSchema={Yup.object({
        newPassword: Yup.string()
          .min(8, intl.formatMessage({ id: 'error.password.length' }))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
            intl.formatMessage({ id: 'error.password.characters' }),
          )
          .required(intl.formatMessage({ id: 'error.input.empty' })),
        confirmPassword: Yup.string()
          .oneOf(
            [Yup.ref('newPassword'), null],
            intl.formatMessage({ id: 'error.password.match' }),
          )
          .required(intl.formatMessage({ id: 'error.input.empty' })),
      })}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Typography variant="h1">
            <FormattedMessage id="login.title" />
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 1, mb: 3 }}
          >
            Your password must be reset. Please choose a new password to
            continue.
          </Typography>
          <Box>
            <PasswordField
              name="newPassword"
              label={intl.formatMessage({ id: 'user.password.new' })}
              fullWidth
              autoFocus
              inputProps={{
                'aria-label': 'New password',
              }}
            />
          </Box>
          <Box mt={2}>
            <PasswordField
              name="confirmPassword"
              label={intl.formatMessage({ id: 'user.password.confirm' })}
              fullWidth
              inputProps={{
                'aria-label': 'Confirm password',
              }}
            />
          </Box>
          <Button
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            sx={{ mt: 2 }}
            startIcon={isSubmitting && <CircularProgress size={15} />}
            disabled={isSubmitting}
          >
            <FormattedMessage id="action.continue" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

PasswordResetForm.displayName = 'PasswordResetForm';
export default PasswordResetForm;
