import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import AppBar from '@mui/material/AppBar';
import ActiveBookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkIcon from '@mui/icons-material/BookmarkBorder';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import NavigationMenu from './NavigationMenu';
import Gate from 'components/Gate';
import Logo from 'components/Logo';
import { COACH_VIEW } from 'core/utils/permissions';
import { useStores } from 'core/hooks/useStores';

const getContrast = (color) => {
  if (color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 180;
  }
};

const Appbar = observer(({ toggleNavigationOpen, isMobile }) => {
  const location = useLocation();

  const { userStore } = useStores();
  const { user } = userStore;

  const { organizationColor } = user ? user.organization.params : '';
  const contrast = getContrast(organizationColor);

  const Icon =
    location.pathname === '/bookmarks' ? ActiveBookmarkIcon : BookmarkIcon;

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) =>
          isMobile ? theme.zIndex.modal + 2 : theme.zIndex.drawer + 2,
        color: contrast ? 'common.white' : 'common.black',
        backgroundColor: organizationColor || 'background.paper',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Link to="/home">
          <Logo color={contrast ? 'white' : 'default'} height={25} />
        </Link>
        <Box display="flex" alignItems="center">
          <Gate permissions={[COACH_VIEW]}>
            <IconButton
              sx={{ mr: { xs: 0, lg: 1 } }}
              component={Link}
              to="/bookmarks"
            >
              <Icon color="primary" />
            </IconButton>
          </Gate>
          {isMobile ? (
            <IconButton color="inherit" onClick={toggleNavigationOpen}>
              <MenuIcon />
            </IconButton>
          ) : (
            <NavigationMenu />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
});

Appbar.propTypes = {};

Appbar.displayName = 'Appbar';
export default Appbar;
