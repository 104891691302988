import React, { useCallback, useState } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Check from 'components/SvgIcons/Check';
import Lock from 'components/SvgIcons/Lock';
import ProgressIndicator from 'components/ProgressIndicator';
import Timer from 'components/SvgIcons/Timer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const useStyles = makeStyles((theme) => ({
  imageContainer: ({ isLocked }) => ({
    display: 'flex',
    flex: '0 0 288px',
    height: 198,
    borderRadius: 8,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isLocked ? 'rgba(0,0,0,0.8)' : 'transparent',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  image: ({ isLocked }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: isLocked ? 0.2 : 1,
    filter: isLocked ? 'blur(3px)' : 'none',
  }),
  icon: {
    width: '3em',
    height: '3em',
    position: 'absolute',
    color: theme.palette.background.dark,
  },
  status: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginLeft: theme.spacing(),
  },
  tabs: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
}));

const ProgramContainer = ({
  breadcrumbs,
  tabs,
  openAt,
  closeAt,
  totalItems,
  completedItems,
  isLocked,
  isClosed,
  isPreview,
  contentType,
  title: programTitle,
  content: { endUserTitle, title, description, image, experiences },
  itemStatus,
}) => {
  const classes = useStyles({ isLocked });

  const status =
    contentType === 'path' && itemStatus[experiences.slice(-1)[0].id];

  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const onTabChange = useCallback((e, v) => setActiveTab(v), []);

  const { component: ProgramContent, props } = tabs.find(
    (tab) => tab.value === activeTab,
  );

  return (
    <>
      {(isClosed || isPreview) && (
        <Alert
          severity="warning"
          sx={{ mb: 3, px: 1, py: 0, borderRadius: '6px' }}
        >
          <FormattedMessage
            id={isClosed ? 'program.expired' : 'program.preview'}
            values={{ date: moment(closeAt).format('ll') }}
          />
        </Alert>
      )}
      <Breadcrumbs aria-label="breadcrumbs">
        {breadcrumbs.map(({ label, path }) => (
          <Link
            key={label}
            variant="caption"
            color="textSecondary"
            component={NavLink}
            to={path}
            underline="hover"
          >
            {label}
          </Link>
        ))}
      </Breadcrumbs>
      <Typography variant="h4" sx={{ pt: 1 }}>
        {programTitle}
      </Typography>
      <Box
        display="flex"
        mt={{ xs: 2, md: 5 }}
        mb={4}
        alignItems="center"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box className={classes.imageContainer}>
          <img src={image.file.url} alt="" className={classes.image} />
          {isLocked && <Lock className={classes.icon} />}
        </Box>
        <Box px={{ xs: 0, md: 3 }} pt={{ xs: 2, md: 0 }}>
          <Typography variant="h5" gutterBottom>
            {endUserTitle || title}
          </Typography>
          {!isPreview && (
            <Typography variant="caption" display="block" gutterBottom>
              {closeAt
                ? `${moment.utc(openAt).format('ll')} - 
              ${moment.utc(closeAt).format('ll')}`
                : moment.utc(openAt).format('ll')}
            </Typography>
          )}
          <Box display="flex" alignItems="center">
            <Typography color="textSecondary" sx={{ mr: 1 }}>
              {description}
            </Typography>
            {!isLocked && contentType === 'path' && (
              <ProgressIndicator total={totalItems} fraction={completedItems} />
            )}
          </Box>
          {contentType === 'path' && totalItems === completedItems ? (
            <Box mt={2} display="flex" alignItems="center" color="primary.main">
              <Check />
              <Typography
                variant="h6"
                color="inherit"
                className={classes.status}
              >
                <FormattedMessage
                  id="date.completed"
                  values={{
                    completedAt: moment.utc(status['completedAt']).format('ll'),
                  }}
                />
              </Typography>
            </Box>
          ) : isClosed ? (
            <Box mt={2} display="flex" alignItems="center">
              <Lock />
              <Typography className={classes.status}>
                <FormattedMessage id="program.closed" />
              </Typography>
            </Box>
          ) : isLocked ? (
            <Box mt={2} display="flex" alignItems="center">
              <Timer />
              <Typography variant="h6" className={classes.status}>
                <FormattedMessage id="program.locked" />
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Tabs
        value={activeTab}
        onChange={onTabChange}
        variant="scrollable"
        classes={{ flexContainer: classes.tabs }}
        sx={{ display: contentType === 'custom' && 'none' }}
      >
        {tabs.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            label={label}
            sx={{ fontWeight: activeTab === value ? 'bold' : 'regular' }}
          />
        ))}
      </Tabs>
      <ProgramContent {...props} />
    </>
  );
};

ProgramContainer.defaultProps = {
  isLocked: false,
  isClosed: false,
  isPreview: false,
};
ProgramContainer.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      path: PropTypes.string,
    }),
  ),
  openAt: PropTypes.string,
  closeAt: PropTypes.string,
  totalItems: PropTypes.number,
  completedItems: PropTypes.number,
  isLocked: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  contentType: PropTypes.oneOf(['path', 'circle', 'custom']).isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    endUserTitle: PropTypes.string,
    description: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    experiences: PropTypes.array,
  }).isRequired,
  itemStatus: PropTypes.objectOf(
    PropTypes.shape({
      completedAt: PropTypes.string,
    }),
  ),
};

ProgramContainer.displayName = 'ProgramContainer';
export default ProgramContainer;
