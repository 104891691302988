import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';

import DraggableBank from './DraggableBank';
import DraggableTarget from './DraggableTarget';
import DragDropWrapper from './DragDropWrapper';
import ShowActualSwitch from 'components/ContentRenderer/Embeddable/embeddables/components/ShowActualSwitch';
import { useBooleanState } from 'core/hooks/useBooleanState';
import { useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '1 1 auto',
  },
  draggableBankContainer: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      padding: 6,
      maxHeight: '50%',
      minHeight: '10%',
    },
  },
  draggableTargetContainer: {
    position: 'relative',
  },
  image: {
    position: 'absolute',
    maxWidth: '100%',
    height: '100%',
  },
  quadrantTarget: {
    height: '50%',
    '&:nth-child(1), &:nth-child(2)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '&:nth-child(1), &:nth-child(3)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  stackedTarget: {
    width: '100%',
  },
  switch: {
    [theme.breakpoints.down('md')]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(1, 0),
    },
  },
}));

const DragDrop = (props) => {
  const {
    sections,
    draggableType,
    sectionType,
    backgroundImage,
    hasCorrectAnswer,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(() => theme.breakpoints.down('md'));

  const [showCorrect, toggleShowCorrect] = useBooleanState(false);
  const [openDialog, toggleOpenDialog] = useBooleanState(false);

  return (
    <DragDropWrapper type={draggableType} {...props}>
      {({ options, selected, originalSelection, unselected, onDragEnd }) => {
        const component = (
          <Grid
            container
            direction={isSmall ? 'column-reverse' : 'row'}
            className={classes.container}
          >
            <Grid item md={8} className={classes.draggableBankContainer}>
              <DraggableBank
                type={draggableType}
                options={options}
                unselected={unselected}
                isDisabled={showCorrect}
              />
            </Grid>
            <Grid
              item
              container
              xs
              md={4}
              direction="column"
              alignItems="center"
            >
              {hasCorrectAnswer && (
                <Grid item className={classes.switch}>
                  <ShowActualSwitch
                    checked={showCorrect}
                    onChange={toggleShowCorrect}
                    typographyProps={{ color: 'inherit' }}
                    color={isSmall ? 'default' : 'primary'}
                  />
                </Grid>
              )}
              <Grid
                item
                container
                xs
                alignItems="center"
                direction={sectionType === 'Quadrant' ? 'row' : 'column'}
                className={classes.draggableTargetContainer}
              >
                {backgroundImage && (
                  <img
                    src={backgroundImage.file.url}
                    alt="background"
                    className={classes.image}
                  />
                )}
                {sections.map(({ correctAnswer = [], ...section }, index) => (
                  <Grid
                    item
                    xs={sectionType === 'Quadrant' ? 6 : true}
                    key={index}
                    className={cn({
                      [classes.quadrantTarget]: sectionType === 'Quadrant',
                      [classes.stackedTarget]: sectionType === 'Stacked',
                    })}
                  >
                    <DraggableTarget
                      type={draggableType}
                      droppableId={index.toString()}
                      options={options}
                      selected={selected[index]}
                      originalSelection={originalSelection?.[index]}
                      isDisabled={showCorrect}
                      correctAnswer={correctAnswer.map(
                        (option) => option.value,
                      )}
                      {...section}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        );

        return (
          <DragDropContext onDragEnd={onDragEnd}>
            {isSmall ? (
              <Box textAlign="center">
                <Button variant="contained" onClick={toggleOpenDialog}>
                  <FormattedMessage id="action.getStarted" />
                </Button>
                <Dialog fullScreen open={openDialog} onClose={toggleOpenDialog}>
                  <Box textAlign="right">
                    <FormattedMessage id="action.cancel">
                      {(text) => (
                        <IconButton
                          aria-label={text}
                          onClick={toggleOpenDialog}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </FormattedMessage>
                  </Box>
                  {component}
                </Dialog>
              </Box>
            ) : (
              component
            )}
          </DragDropContext>
        );
      }}
    </DragDropWrapper>
  );
};

DragDrop.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      color: PropTypes.string,
      avatar: PropTypes.string,
    }),
  ),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      image: PropTypes.object,
      correctAnswer: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string }),
      ),
    }).isRequired,
  ).isRequired,
  backgroundImage: PropTypes.object,
  draggableType: PropTypes.oneOf(['Block', 'Chip']).isRequired,
  sectionType: PropTypes.oneOf(['Quadrant', 'Stacked']).isRequired,
  hasCorrectAnswer: PropTypes.bool,
};

DragDrop.displayName = 'DragDrop';
export default DragDrop;
