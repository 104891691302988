import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    strokeLinecap="round"
  >
    <g transform="translate(-592.000000, -82.000000)" stroke="#D64E00">
      <g transform="translate(594.000000, 84.000000)">
        <path
          d="M25,1 C14.1991862,10.6960843 6.19918622,18.6960843 1,25"
          strokeWidth="3"
          strokeLinejoin="round"
          transform="translate(13.000000, 13.000000) rotate(-4.000000) translate(-13.000000, -13.000000) "
        />
        <line
          x1="22.8466136"
          y1="17.2406706"
          x2="3"
          y2="17.1444023"
          strokeWidth="4"
        />
        <line
          x1="23.0766932"
          y1="7.59626823"
          x2="3.23007963"
          y2="7.5"
          strokeWidth="4"
        />
      </g>
    </g>
  </g>,
  'UnequalIcon',
);
