import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import BackArrow from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'layouts/Layout';
import {
  useGetCategories,
  useGetSkills,
  useUpdateEmployeeInterests,
} from 'core/api/feed';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const InterestDialog = ({ open, onClose, interests }) => {
  const intl = useIntl();

  const {
    data: skills,
    isLoading: isSkillsLoading,
    error: skillsError,
  } = useGetSkills();

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    error: categoriesError,
  } = useGetCategories();

  const { mutateAsync } = useUpdateEmployeeInterests();

  const [selected, setSelected] = useState(interests);

  const handleSelect = (section, targetId) => () => {
    if (selected[section].includes(targetId)) {
      setSelected({
        ...selected,
        [section]: selected[section].filter((id) => id !== targetId),
      });
    } else {
      setSelected({ ...selected, [section]: [...selected[section], targetId] });
    }
  };

  const onSubmit = async () =>
    await mutateAsync(
      { interests: selected },
      {
        onSuccess: () =>
          successNotification(
            intl.formatMessage({ id: 'profile.interests.update.success' }),
          ),
        onError: (err) => errorNotification(err.response.data.message),
        onSettled: () => onClose(),
      },
    );

  return (
    <Dialog open={open} fullScreen>
      <Layout showNavigation align="center">
        <IconButton
          onClick={onClose}
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            mb: { xs: 1, md: 0 },
          }}
        >
          <BackArrow />
        </IconButton>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{
            width: { xs: '100%', md: 600 },
            margin: 'auto',
            borderRadius: 2,
            minHeight: '100%',
          }}
        >
          <Box
            p={3}
            bgcolor="primary.main"
            color="common.white"
            borderRadius="8px 8px 0px 0px"
          >
            <Typography variant="h2" color="inherit">
              <FormattedMessage id="interests.title" />
            </Typography>
          </Box>
          <Box p={3}>
            <Box>
              <Typography variant="h4">
                <FormattedMessage id="interests.categories" />
              </Typography>
              <Typography color="textSecondary" sx={{ mb: 3 }}>
                <FormattedMessage id="profile.interests.categories.description" />
              </Typography>
              {!isCategoriesLoading && (
                <ErrorBoundary error={categoriesError}>
                  {categories
                    .filter(
                      ({ hideFromUserInterests }) => !hideFromUserInterests,
                    )
                    .map(({ title, id }) => (
                      <Chip
                        key={id}
                        label={title}
                        variant="outlined"
                        color={
                          selected?.categories?.includes(id)
                            ? 'secondary'
                            : 'default'
                        }
                        sx={{ mr: 1, mb: 1 }}
                        onClick={handleSelect('categories', id)}
                      />
                    ))}
                </ErrorBoundary>
              )}
            </Box>
            <Box pt={4}>
              <Typography variant="h4">
                <FormattedMessage id="interests.skills" />
              </Typography>
              <Typography color="textSecondary" sx={{ mb: 3 }}>
                <FormattedMessage id="profile.interests.skills.description" />
              </Typography>
              {!isSkillsLoading && (
                <ErrorBoundary error={skillsError}>
                  {skills
                    .filter(
                      ({ hideFromUserInterests }) => !hideFromUserInterests,
                    )
                    .map(({ title, id }) => (
                      <Chip
                        key={id}
                        label={title}
                        variant="outlined"
                        color={
                          selected?.skills?.includes(id)
                            ? 'secondary'
                            : 'default'
                        }
                        sx={{ mr: 1, mb: 1 }}
                        onClick={handleSelect('skills', id)}
                      />
                    ))}
                </ErrorBoundary>
              )}
            </Box>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              onClick={onSubmit}
            >
              <FormattedMessage id="action.save" />
            </Button>
          </Box>
        </Paper>
      </Layout>
    </Dialog>
  );
};

InterestDialog.defaultProps = {
  interests: { categories: [], skills: [] },
};

InterestDialog.displayName = 'InterestDialog';
export default InterestDialog;
