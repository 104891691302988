import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import { useBooleanState } from 'core/hooks/useBooleanState';
import { useReadAlerts } from 'core/api/feed';

const Alert = ({ icon, title, description, uids }) => {
  const [isOpen, toggleIsOpen] = useBooleanState(true);
  const { mutate } = useReadAlerts();

  const onClick = () => {
    mutate({ uids });
    toggleIsOpen();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={toggleIsOpen}
      sx={{
        '& .MuiDialog-paper': {
          p: 5,
          alignItems: 'center',
          textAlign: 'center',
        },
      }}
    >
      {icon}
      <Typography variant="h4" sx={{ my: 2 }}>
        {title}
      </Typography>
      <Typography color="textSecondary" sx={{ mb: 2 }}>
        {description}
      </Typography>
      <Button fullWidth variant="contained" onClick={onClick}>
        <FormattedMessage id="action.gotIt" />
      </Button>
    </Dialog>
  );
};

Alert.displayName = 'Alert';
export default Alert;
