import React from 'react';
import { useHistory } from 'react-router-dom';

import BackArrow from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';
import EmptyState from 'components/EmptyState';
import ErrorBoundary from 'components/ErrorBoundary';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import { useGetCollection } from 'core/api/coach';
import { COACH_VIEW } from 'core/utils/permissions';

const CollectionDetail = ({
  match: {
    params: { collectionId },
  },
}) => {
  const { data, isLoading, error } = useGetCollection(collectionId);
  const { collection, coachContent } = data || {};

  const history = useHistory();

  return (
    <Gate permissions={[COACH_VIEW]} redirectOnDenied>
      <Layout showNavigation align="center">
        {!isLoading && (
          <ErrorBoundary error={error}>
            <IconButton
              onClick={() => history.goBack()}
              sx={{
                position: { xs: 'relative', md: 'absolute' },
                mb: { xs: 1, md: 0 },
              }}
            >
              <BackArrow />
            </IconButton>
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                width: { xs: '100%', md: 600 },
                margin: 'auto',
                borderRadius: 2,
              }}
            >
              <Box
                p={3}
                bgcolor="info.dark"
                color="common.white"
                borderRadius="8px 8px 0px 0px"
              >
                <Typography variant="h2" color="inherit">
                  {collection.title}
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  {collection.description}
                </Typography>
              </Box>
              <Box p={3} pt={1}>
                {coachContent.map((deck, idx) => (
                  <CoachListItem key={idx} {...deck} />
                ))}
              </Box>
            </Paper>
          </ErrorBoundary>
        )}
        {isLoading && <EmptyState isLoading />}
      </Layout>
    </Gate>
  );
};

CollectionDetail.displayName = 'CollectionDetail';
export default CollectionDetail;
