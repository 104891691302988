import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

/**
 * Render an image. The image will take the full width of the container and will
 * get cropped at the bottom.
 *
 */
const Image = ({ description, file: { url } = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img alt={description || ''} src={url} className={classes.image} />
    </div>
  );
};

Image.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

Image.displayName = 'Image';
export default Image;
