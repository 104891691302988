import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import BackArrow from '@mui/icons-material/KeyboardArrowLeft';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import ExpandArrow from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import NextArrow from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';

import Resources from '../Resources';
import { FormWizardActions } from 'components/FormWizard';

const DesktopNavigation = ({
  isPreview,
  title,
  pathId,
  experienceId,
  programId,
  program,
  programLink,
  ...props
}) => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const onOpenResources = (e) => setAnchorEl(e.currentTarget);
  const onCloseResources = () => setAnchorEl(null);

  const breadcrumbs = isPreview
    ? [
        {
          label: intl.formatMessage({ id: 'experience.navigation.preview' }),
          path: '/admin/experiences/paths',
        },
        { label: title },
      ]
    : [
        {
          label: intl.formatMessage({ id: 'navigation.paths' }),
          path: '/paths',
        },
        { label: program.title, path: programLink },
        { label: title },
      ];

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
    >
      <Box display="flex" alignItems="center">
        <IconButton
          component={NavLink}
          to={isPreview ? '/admin/experiences/paths' : programLink}
        >
          <CloseIcon />
        </IconButton>
        <Breadcrumbs aria-label="breadcrumbs">
          {breadcrumbs.map(({ label, path }) =>
            path ? (
              <Link
                key={label}
                variant="caption"
                color="textSecondary"
                component={NavLink}
                to={path}
                underline="hover"
              >
                {label}
              </Link>
            ) : (
              <Typography variant="caption" color="textSecondary">
                {label}
              </Typography>
            ),
          )}
        </Breadcrumbs>
      </Box>
      <FormWizardActions>
        {({
          isFirstStep,
          isLastStep,
          previousStep,
          isSubmitting,
          totalSteps,
        }) => (
          <Box display="flex" alignItems="center">
            {totalSteps > 1 && (
              <Button
                variant="outlined"
                disabled={isFirstStep}
                onClick={previousStep}
                startIcon={<BackArrow />}
                sx={{ mr: 1 }}
              >
                <FormattedMessage id="action.back" />
              </Button>
            )}
            {!isLastStep && (
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                endIcon={<NextArrow />}
              >
                {isSubmitting ? (
                  <CircularProgress size={15} />
                ) : (
                  <FormattedMessage id="action.next" />
                )}
              </Button>
            )}
            {isLastStep && (
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <CircularProgress size={15} />
                ) : (
                  <FormattedMessage id="action.done" />
                )}
              </Button>
            )}
            <Box
              display="inline-flex"
              borderLeft={1}
              borderColor="divider"
              ml={2}
              pl={2}
            >
              <Button
                aria-controls="menu"
                aria-haspopup="true"
                onClick={onOpenResources}
                endIcon={<ExpandArrow />}
              >
                <FormattedMessage id="experience.tools.title" />
              </Button>
            </Box>
            <Resources
              anchorEl={anchorEl}
              onClose={onCloseResources}
              programId={programId}
              contentId={experienceId}
              parentContentId={program?.contentId}
              {...props}
            />
          </Box>
        )}
      </FormWizardActions>
    </Box>
  );
};

DesktopNavigation.displayName = 'DesktopNavigation';
export default DesktopNavigation;
