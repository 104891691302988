import React from 'react';
import { FormattedMessage } from 'react-intl';

import EmptyState from 'components/EmptyState';
import EmptyStateImage from 'assets/shared/panda-search.svg';
import Layout from 'layouts/Layout';

const Error = () => (
  <Layout showNavigation align="center">
    <EmptyState
      image={<img src={EmptyStateImage} width={300} alt="" />}
      title={<FormattedMessage id="404.title" />}
      description={<FormattedMessage id="404.subtitle" />}
    />
  </Layout>
);

Error.displayName = 'Error';
export default Error;
