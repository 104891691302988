import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import {
  useGetNotificationPreferences,
  useSetNotificationPreference,
} from 'core/api/user';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const Notifications = () => {
  const intl = useIntl();

  const { data, isLoading, error } = useGetNotificationPreferences();
  const { mutateAsync } = useSetNotificationPreference();

  const onChange = (
    activityTypeId,
    notificationTypeId,
    notificationIndex,
  ) => async (e) =>
    await mutateAsync(
      {
        activityTypeId,
        notificationTypeId,
        notificationIndex,
        value: e.target.checked,
      },
      {
        onSuccess: () =>
          successNotification(
            intl.formatMessage({
              id: 'profile.settings.update.success',
            }),
          ),
        onError: (err) => errorNotification(err.response.data.message),
      },
    );

  return (
    <Box flex="1 1 auto" ml={{ xs: 0, sm: 5, md: 7 }}>
      <Box pb={2}>
        <Typography variant="h1">
          <FormattedMessage id="profile.settings.notifications" />
        </Typography>
        <Typography color="textSecondary">
          <FormattedMessage id="profile.settings.notifications.subtitle" />
        </Typography>
      </Box>
      {!isLoading && (
        <ErrorBoundary error={error}>
          {data
            .filter((activity) => !activity.isHidden)
            .map((activity) => (
              <Box pt={3} key={activity.id}>
                <Box pb={2}>
                  <Typography variant="h6">{activity.displayName}</Typography>
                  <Typography variant="body2">
                    {activity.description}
                  </Typography>
                </Box>
                <FormGroup>
                  {activity.notifications.map((notification, idx) => (
                    <FormControlLabel
                      key={notification.id}
                      control={
                        <Switch
                          edge="start"
                          onChange={onChange(activity.id, notification.id, idx)}
                          checked={notification.value}
                          disabled={!notification.isChangeable}
                          sx={{ ml: 0 }}
                        />
                      }
                      label={notification.displayName}
                    />
                  ))}
                </FormGroup>
              </Box>
            ))}
        </ErrorBoundary>
      )}
    </Box>
  );
};

Notifications.displayName = 'Notifications';
export default Notifications;
