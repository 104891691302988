import React, { useRef, useState, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ViewLessIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewMoreIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';

import ErrorBoundary from 'components/ErrorBoundary';
import { useGetEmployeeSkills } from 'core/api/feed';
import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
    backgroundColor: theme.palette.muted.orange,
    border: 'none',
  },
}));

const MAX_HEIGHT = 38 * 5; // 5 rows of chips

const Skills = () => {
  const classes = useStyles();

  const { data, isLoading, error } = useGetEmployeeSkills();

  const [height, setHeight] = useState(0);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isExpanded, toggleIsExpanded] = useBooleanState(false);

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight) {
      setHeight(Math.min(MAX_HEIGHT, ref.current?.clientHeight));
      setIsOverflowing(ref.current?.clientHeight > MAX_HEIGHT);
    }
  }, [data]);

  return (
    <Paper
      sx={{ p: 2, mb: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Typography variant="h6">
            <FormattedMessage
              id="profile.skills.title"
              values={{ count: data.length }}
            />
          </Typography>
          {data.length > 0 ? (
            <Box>
              <Collapse in={isExpanded} collapsedSize={height}>
                <Box ref={ref}>
                  {data.map(({ contentCid, contentTitle }) => (
                    <Chip
                      className={classes.chip}
                      key={contentCid}
                      label={contentTitle}
                    />
                  ))}
                </Box>
              </Collapse>
              {isOverflowing && (
                <Button
                  sx={{ display: 'flex', m: 'auto', mt: 2 }}
                  onClick={toggleIsExpanded}
                  endIcon={isExpanded ? <ViewLessIcon /> : <ViewMoreIcon />}
                >
                  {isExpanded ? 'View less' : 'View more'}
                </Button>
              )}
            </Box>
          ) : (
            <Typography color="textSecondary">
              <FormattedMessage id="profile.skills.empty" />
            </Typography>
          )}
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Skills.displayName = 'Skills';
export default Skills;
