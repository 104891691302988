import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import FormField from 'components/FormField';
import { useStores } from 'core/hooks/useStores';

const IdentifierForm = ({ onSuccess }) => {
  const intl = useIntl();
  const { authStore } = useStores();
  const { getLoginType } = authStore;
  const subdomain = window.location.host.split('.')[0];

  const onSubmit = async ({ identifier }, { setSubmitting, setErrors }) => {
    try {
      setErrors({});
      const authConfig = await getLoginType(identifier, subdomain);
      onSuccess(identifier, authConfig);
    } catch (e) {
      const error = e.response.data?.code;
      if (error === 'ERROR_USER_NOT_REGISTERED') {
        setErrors({
          identifier:
            'You have not registered yet. Please register using the link below.',
        });
      } else {
        setErrors({
          identifier: intl.formatMessage({ id: 'error.generic' }),
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{ identifier: '' }}
      validationSchema={Yup.object({
        identifier: Yup.string()
          .trim()
          .required(intl.formatMessage({ id: 'error.input.empty' })),
      })}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Typography variant="h1">
            <FormattedMessage id="login.title" />
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 1, mb: 3 }}
          >
            Use your Eskalera account to log in
          </Typography>
          <FormField
            name="identifier"
            label="Username or email"
            fullWidth
            autoFocus
            inputProps={{
              'aria-label': 'Username or email',
            }}
          />
          <Button
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            sx={{ my: 2 }}
            startIcon={isSubmitting && <CircularProgress size={15} />}
            disabled={isSubmitting}
          >
            <FormattedMessage id="action.continue" />
          </Button>
          <Box>
            <Typography variant="body1" display="inline" color="textSecondary">
              Don't have an account?{' '}
            </Typography>
            <Typography
              sx={{ textDecoration: 'none' }}
              component={Link}
              to="/register/invite"
              display="inline"
              color="primary"
            >
              Register
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

IdentifierForm.displayName = 'IdentifierForm';
export default IdentifierForm;
