import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ErrorBoundary from 'components/ErrorBoundary';
import StarIcon from 'components/SvgIcons/Star';
import { useGetEmployeeSkills } from 'core/api/feed';

const VISIBLE_COUNT = 6;

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
    backgroundColor: theme.palette.muted.orange,
    border: 'none',
  },
}));

const Skills = () => {
  const history = useHistory();
  const classes = useStyles();

  const { data, isLoading, error } = useGetEmployeeSkills();

  return (
    <Paper
      sx={{ mb: 2, p: 2, width: '100%', borderRadius: 2, cursor: 'pointer' }}
      elevation={0}
      variant="outlined"
      onClick={() => history.push('/profile')}
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Box display="flex" alignItems="center" mb={2}>
            <StarIcon />
            <Typography variant="h6" sx={{ ml: 1 }}>
              <FormattedMessage
                id="home.skills.title"
                values={{ count: data.length }}
              />
            </Typography>
          </Box>
          {data.length > 0 ? (
            <>
              {data
                .slice(0, VISIBLE_COUNT)
                .map(({ contentCid, contentTitle }) => (
                  <Chip
                    className={classes.chip}
                    key={contentCid}
                    label={contentTitle}
                  />
                ))}
              {data.length > VISIBLE_COUNT && (
                <Chip
                  className={classes.chip}
                  label={`+${data.length - VISIBLE_COUNT}`}
                />
              )}
            </>
          ) : (
            <Typography color="textSecondary">
              <FormattedMessage id="home.skills.empty" />
            </Typography>
          )}
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Skills.displayName = 'Skills';
export default Skills;
