import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g>
    <path
      d="M24.6875 33.8359V36.4844C24.6875 38.4296 23.1173 40 21.1719 40H18.8281C16.8827 40 15.3125 38.4296 15.3125 36.4844V33.8359C15.3125 33.1796 15.828 32.5859 16.4844 32.5859H23.5156C24.172 32.5859 24.6875 33.1796 24.6875 33.8359Z"
      fill="#00A2AE"
    />
    <path
      opacity="0.220494"
      d="M24.6875 33.8359V36.4844C24.6875 38.4296 23.1173 40 21.1719 40H20V32.5859H23.5156C24.172 32.5859 24.6875 33.1796 24.6875 33.8359Z"
      fill="#1B1A19"
    />
    <path
      d="M20.0002 9.45312C19.1565 9.45312 18.3128 9.5468 17.4689 9.7578C13.719 10.625 10.6956 13.6015 9.7815 17.3281C8.86744 21.078 9.92212 24.8749 12.6173 27.5234C13.5783 28.4844 14.1408 29.914 14.1408 31.4609V31.7188C14.1408 33.664 15.7111 35.3125 17.6565 35.3125H22.344C24.2894 35.3125 25.8596 33.664 25.8596 31.7188V31.4609C25.8596 29.9374 26.4455 28.4609 27.4768 27.453C29.4456 25.4609 30.5471 22.8125 30.5471 20C30.5471 14.0937 25.7659 9.45312 20.0002 9.45312Z"
      fill="#FFE986"
    />
    <path
      d="M30.5469 20C30.5469 22.8125 29.4454 25.4609 27.4766 27.4531C26.4453 28.4609 25.8594 29.9375 25.8594 31.4609V31.7188C25.8594 33.664 24.2891 35.3125 22.3438 35.3125H20V9.45312C25.7657 9.45312 30.5469 14.0937 30.5469 20Z"
      fill="#FEDB41"
    />
    <path
      d="M9.22735 10.8849L5.91313 7.57072C5.45539 7.11299 5.45539 6.37135 5.91313 5.91361C6.37086 5.45588 7.1125 5.45588 7.57024 5.91361L10.8845 9.22783C11.3422 9.6856 11.3422 10.4272 10.8845 10.8849C10.4268 11.3428 9.68516 11.3428 9.22735 10.8849Z"
      fill="#FEA832"
    />
    <path
      d="M5.9375 21.1719H1.17188C0.524141 21.1719 0 20.6477 0 20C0 19.3523 0.524141 18.8281 1.17188 18.8281H5.9375C6.58523 18.8281 7.10938 19.3523 7.10938 20C7.10938 20.6477 6.58523 21.1719 5.9375 21.1719Z"
      fill="#E28237"
    />
    <path
      d="M38.8281 21.1719H34.0625C33.4148 21.1719 32.8906 20.6477 32.8906 20C32.8906 19.3523 33.4148 18.8281 34.0625 18.8281H38.8281C39.4759 18.8281 40 19.3523 40 20C40 20.6477 39.4759 21.1719 38.8281 21.1719Z"
      fill="#E28237"
    />
    <path
      d="M29.1153 10.8849C28.6575 10.4272 28.6575 9.6856 29.1153 9.22783L32.4295 5.91361C32.8872 5.45588 33.6289 5.45588 34.0866 5.91361C34.5443 6.37135 34.5443 7.11299 34.0866 7.57072L30.7724 10.8849C30.3146 11.3428 29.573 11.3428 29.1153 10.8849Z"
      fill="#FE9923"
    />
    <path
      d="M21.1719 1.17188V5.9375C21.1719 6.59367 20.6563 7.10938 20 7.10938C19.3437 7.10938 18.8281 6.59367 18.8281 5.9375V1.17188C18.8281 0.51555 19.3437 0 20 0C20.6563 0 21.1719 0.51555 21.1719 1.17188Z"
      fill="#E28237"
    />
    <path
      opacity="0.28704"
      d="M21.1719 1.17188V5.9375C21.1719 6.59367 20.6563 7.10938 20 7.10938V0C20.6563 0 21.1719 0.51555 21.1719 1.17188Z"
      fill="#FE9923"
    />
  </g>,
  'LightbulbIcon',
  {
    viewBox: '0 0 40 40',
  },
);
