import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useStores } from 'core/hooks/useStores';
import LogoImage from './images/logo.svg';
import LogoMobileImage from './images/logo-mobile.png';
import LogoMobileWhiteImage from './images/logo-mobile-white.png';
import LogoWhiteImage from './images/logo-white.png';
import { useTheme } from '@mui/material';

const MOBILE_IMAGES = { default: LogoMobileImage, white: LogoMobileWhiteImage };
const DESKTOP_IMAGES = { default: LogoImage, white: LogoWhiteImage };

const Logo = ({ color, useDefault, fullWidth, ...other }) => {
  const { userStore } = useStores();
  const { organizationLogo } = userStore.user
    ? userStore.user.organization.params
    : '';

  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
  const eskaleraLogo =
    isMobile && !fullWidth ? MOBILE_IMAGES[color] : DESKTOP_IMAGES[color];

  return (
    <img
      alt=""
      src={useDefault ? eskaleraLogo : organizationLogo || eskaleraLogo}
      {...other}
    />
  );
};

Logo.defaultProps = {
  color: 'default',
};

Logo.propTypes = {
  color: PropTypes.oneOf(['white', 'default']),
  useDefault: PropTypes.bool, // use default Eskalera logo regardless of organization logo
};

export default Logo;
