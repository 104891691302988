import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Alert from './Alert';
import BadgeCountIcon from 'components/SvgIcons/BadgeCount';
import ErrorBoundary from 'components/ErrorBoundary';
import StreakIcon from 'components/SvgIcons/Streak';
import { useGetAlerts } from 'core/api/feed';

const useStyles = makeStyles((theme) => ({
  badge: {
    width: 80,
    height: 80,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(-3),
    },
  },
}));

const Alerts = () => {
  const queryClient = useQueryClient();

  const classes = useStyles();
  const { data, isLoading, error } = useGetAlerts();

  useEffect(() => {
    return () => {
      queryClient.resetQueries(['alerts']);
    };
  }, []);

  const badges = data?.alerts.filter((alert) => alert.type === 'badge');
  const badge = data?.alerts.find((alert) => alert.type === 'badge');
  const streak = data?.alerts.find((alert) => alert.type.includes('streak'));

  return (
    <ErrorBoundary error={error}>
      {/** streak alert */
      !isLoading && streak && (
        <Alert
          icon={
            <Box display="flex" justifyContent="center" position="relative">
              <StreakIcon sx={{ height: 80, width: 80 }} />
              <Typography
                variant="h4"
                sx={{ position: 'absolute', bottom: 12 }}
              >
                +1
              </Typography>
            </Box>
          }
          title={
            <FormattedMessage
              id={`alert.${streak.type}.title`}
              values={{ count: streak.params.streak }}
            />
          }
          description={<FormattedMessage id="alert.streak.description" />}
          uids={[streak.uid]}
        />
      )}
      {/** badge alert */
      !isLoading && badges.length === 1 && (
        <Alert
          icon={
            <img src={badge.params.imageUrl} alt="" height={80} width={80} />
          }
          title={
            <FormattedMessage
              id="alert.badge_single.title"
              values={{ title: badge.params.badgeTitle }}
            />
          }
          description={badge.params.badgeDescription}
          uids={[badge.uid]}
        />
      )}
      {/** badge multiple alert */
      !isLoading && badges.length > 1 && (
        <Alert
          icon={
            <Box display="flex">
              {(badges.length > 4 ? badges.slice(0, 3) : badges).map(
                (badge) => (
                  <img
                    src={badge.params.imageUrl}
                    alt=""
                    className={classes.badge}
                  />
                ),
              )}
              {badges.length > 4 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  ml={-3}
                >
                  <BadgeCountIcon sx={{ height: 73, width: 80 }} />
                  <Typography variant="h4" sx={{ position: 'absolute' }}>
                    {`+${badges.length - 3}`}
                  </Typography>
                </Box>
              )}
            </Box>
          }
          title={
            <FormattedMessage
              id="alert.badge_multiple.title"
              values={{ count: badges.length }}
            />
          }
          description={badges.map((badge, idx) =>
            idx === badges.length - 1
              ? badge.params.badgeTitle
              : `${badge.params.badgeTitle}, `,
          )}
          uids={badges.map((badge) => badge.uid)}
        />
      )}
    </ErrorBoundary>
  );
};

Alerts.displayName = 'Alerts';
export default Alerts;
