import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import TextField from 'components/FormField/TextField';

import { useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: 'pre-wrap',
  },
}));

const DropdownSingleSelect = ({ id, title, options }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <TextField
      id={id}
      name={id}
      margin="dense"
      size="small"
      label={title}
      value={values[id] || ''}
      select
      fullWidth
    >
      {options.map(({ value, label }) => (
        <MenuItem
          key={`single-select-option-${value}`}
          value={value}
          label={label}
          className={classes.menuItem}
        >
          <Typography variant="inherit">{label}</Typography>
        </MenuItem>
      ))}
    </TextField>
  );
};

DropdownSingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
};

DropdownSingleSelect.displayName = 'DropdownSingleSelect';
export default DropdownSingleSelect;
