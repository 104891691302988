import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    width: '100%',
  },
  button: {
    width: '100%',
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&$selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  selected: {},
}));

const Likert = ({ id, minLabel, maxLabel, disabled, points, startAtZero }) => {
  const classes = useStyles();

  const [field, , helpers] = useField({ name: id });
  const onChange = (e, value) => helpers.setValue(value);

  const choices = Array.from(
    new Array(startAtZero ? points + 1 : points),
    (x, i) => '' + (startAtZero ? i : i + 1),
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        {minLabel && <Typography variant="caption">{minLabel}</Typography>}
        {maxLabel && <Typography variant="caption">{maxLabel}</Typography>}
      </Box>
      <ToggleButtonGroup
        value={field.value}
        onChange={onChange}
        className={classes.buttonGroup}
        exclusive
        size="small"
      >
        {choices.map((choice) => (
          <ToggleButton
            key={choice}
            value={choice}
            classes={{ root: classes.button, selected: classes.selected }}
            aria-label={choice}
            disabled={disabled}
          >
            {choice}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

Likert.propTypes = {
  id: PropTypes.string.isRequired,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  disabled: PropTypes.bool,
  points: PropTypes.number,
  startAtZero: PropTypes.bool,
};

Likert.defaultProps = {
  points: 5,
  startAtZero: false,
};

Likert.displayName = 'Likert';
export default Likert;
