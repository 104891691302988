import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import Container from 'containers/auth/components/Container';
import EmptyState from 'components/EmptyState';
import FormField from 'components/FormField';
import { useStores } from 'core/hooks/useStores';

const RecoverPassword = observer(() => {
  const intl = useIntl();

  const [success, setSuccess] = useState(false);

  const { authStore } = useStores();
  const { recoverPassword } = authStore;

  const onSubmit = async ({ email }, { setSubmitting, setErrors }) => {
    try {
      setErrors({});
      await recoverPassword(email);
      setSuccess(true);
    } catch (e) {
      setErrors({
        email: intl.formatMessage({ id: 'error.generic' }),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      {success ? (
        <EmptyState
          title="Check your email"
          description="We've sent you an email with instructions to reset your password."
        />
      ) : (
        <Formik
          validateOnBlur={false}
          onSubmit={onSubmit}
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: Yup.string()
              .trim()
              .required(intl.formatMessage({ id: 'error.input.empty' }))
              .email(intl.formatMessage({ id: 'error.email.invalid' })),
          })}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Typography variant="h1">Forgot password?</Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ mt: 1, mb: 3 }}
              >
                Enter the email associated with your account and we'll send you
                a link with instructions to reset it.
              </Typography>
              <FormField
                name="email"
                label="Email"
                fullWidth
                autoFocus
                inputProps={{
                  'aria-label': 'Email',
                }}
              />
              <Button
                size="large"
                fullWidth
                variant="contained"
                type="submit"
                sx={{ my: 2 }}
                startIcon={isSubmitting && <CircularProgress size={15} />}
                disabled={isSubmitting}
              >
                <FormattedMessage id="action.continue" />
              </Button>
              <Typography
                sx={{ textDecoration: 'none' }}
                variant="body1"
                component={Link}
                to="/login"
                display="inline"
                color="primary"
              >
                Back to login
              </Typography>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
});

RecoverPassword.displayName = 'RecoverPassword';
export default RecoverPassword;
