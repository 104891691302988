import React from 'react';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input';

import FilledInput from '@mui/material/FilledInput';
import StandardInput from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import makeStyles from '@mui/styles/makeStyles';

const INPUT_TYPES = {
  standard: StandardInput,
  outlined: OutlinedInput,
  filled: FilledInput,
};

const useStyles = makeStyles((theme) => ({
  input: ({ variant, disabled, label }) => ({
    position: 'relative',
    marginTop: variant === 'standard' && label && 16,
    '& div.PhoneInputCountry': {
      display: disabled ? 'none' : 'flex',
      position: 'absolute',
      height: '100%',
      margin: 'auto',
      zIndex: 1,
      left: variant === 'outlined' ? 14 : variant === 'filled' ? 12 : 0,
    },
    '& input': {
      marginLeft: variant === 'outlined' ? theme.spacing() : 0,
    },
  }),
}));

const InputComponent = React.forwardRef(
  ({ variant, disabled, ...props }, ref) => {
    const Component = INPUT_TYPES[variant];
    return (
      <Component
        {...props}
        disabled={disabled}
        inputRef={ref}
        inputProps={{
          style: { paddingLeft: disabled ? 0 : 48 },
          'aria-label': 'Phone',
        }}
        sx={{ '& div[data-lastpass-icon-root]': { display: 'none' } }}
      />
    );
  },
);

const PhoneInput = ({ variant, disabled, label, ...props }) => {
  const classes = useStyles({ variant, disabled, label });

  return (
    <Input
      defaultCountry="US"
      variant={variant}
      label={label}
      disabled={disabled}
      inputComponent={InputComponent}
      className={classes.input}
      {...props}
    />
  );
};

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
