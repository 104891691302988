import React from 'react';
import cn from 'classnames';

import makeStyles from '@mui/styles/makeStyles';

import Chip from 'components/ContentRenderer/Embeddable/embeddables/components/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F3E3A0',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 80,
      height: 4,
      opacity: 0.7,
      background: '#FFF',
      borderRadius: 2,
      transform: 'rotate(-35deg)',
    },
  },
}));

const StrikedChip = ({ className, ...props }) => {
  const classes = useStyles();

  return <Chip className={cn(className, classes.root)} {...props} />;
};

StrikedChip.propTypes = { ...Chip.propTypes };

StrikedChip.displayName = 'StrikedChip';
export default StrikedChip;
