import React from 'react';
import PropTypes from 'prop-types';

import DragDropBlockWrapper from './DragDropBlockWrapper';
import DragDropChipWrapper from './DragDropChipWrapper';

const DragDropWrapper = ({ type, ...props }) => {
  const Component =
    type === 'Chip' ? DragDropChipWrapper : DragDropBlockWrapper;

  return <Component {...props} />;
};

DragDropWrapper.propTypes = {
  type: PropTypes.oneOf(['Chip', 'Block']).isRequired,
};

DragDropWrapper.displayName = 'DragDropWrapper';
export default DragDropWrapper;
