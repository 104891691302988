import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ErrorBoundary from 'components/ErrorBoundary';
import FlagIcon from 'components/SvgIcons/Flag';
import { useGetEmployeeInterests } from 'core/api/feed';

const VISIBLE_COUNT = 6;

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
    backgroundColor: theme.palette.muted.blue,
    border: 'none',
  },
}));

const Interests = () => {
  const history = useHistory();

  const intl = useIntl();
  const classes = useStyles();

  const {
    data: skills,
    isLoading: isSkillsLoading,
    error: skillsError,
  } = useGetEmployeeInterests('skill');

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    error: categoriesError,
  } = useGetEmployeeInterests('category');

  return (
    <Paper
      sx={{ mb: 2, p: 2, width: '100%', borderRadius: 2, cursor: 'pointer' }}
      elevation={0}
      variant="outlined"
      onClick={() => history.push('/profile')}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <FlagIcon />
        <Typography variant="h6" sx={{ ml: 1 }}>
          <FormattedMessage id="interests.title" />
        </Typography>
      </Box>
      {!isCategoriesLoading && (
        <ErrorBoundary error={categoriesError}>
          <Typography
            variant="body2"
            color="textPrimary"
            sx={{ fontWeight: 'bold' }}
          >
            {`${intl.formatMessage({ id: 'interests.categories' })} (${
              categories.length
            })`}
          </Typography>
          {categories
            .slice(0, VISIBLE_COUNT)
            .map(({ contentCid, contentTitle }) => (
              <Chip
                className={classes.chip}
                key={contentCid}
                label={contentTitle}
              />
            ))}
          {categories.length > VISIBLE_COUNT && (
            <Chip
              className={classes.chip}
              label={`+${categories.length - VISIBLE_COUNT}`}
            />
          )}
        </ErrorBoundary>
      )}
      {!isSkillsLoading && (
        <ErrorBoundary error={skillsError}>
          <Typography
            variant="body2"
            color="textPrimary"
            sx={{ fontWeight: 'bold', mt: 2 }}
          >
            {`${intl.formatMessage({ id: 'interests.skills' })} (${
              skills.length
            })`}
          </Typography>
          {skills
            .slice(0, VISIBLE_COUNT)
            .map(({ contentCid, contentTitle }) => (
              <Chip
                className={classes.chip}
                key={contentCid}
                label={contentTitle}
              />
            ))}
          {skills.length > VISIBLE_COUNT && (
            <Chip
              className={classes.chip}
              label={`+${skills.length - VISIBLE_COUNT}`}
            />
          )}
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Interests.displayName = 'Interests';
export default Interests;
