import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    strokeLinecap="round"
  >
    <g
      transform="translate(-640.000000, -80.000000)"
      stroke="#007674"
      strokeWidth="4"
    >
      <path d="M665.76992,98.6444023 L645.923307,98.5481341 M666,89 L646.153386,88.9037318" />
    </g>
  </g>,
  'EqualIcon',
);
