import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material';

import { useGetDeckProgress } from 'core/api/coach';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(),
    marginTop: theme.spacing(2),
    '& .MuiListItemSecondaryAction-root': {
      right: theme.spacing(),
      top: 26,
    },
  },
  action: ({ categoryColorText, categoryColorBg }) => ({
    padding: 0,
    color: `rgba(${categoryColorText})`,
    '&:hover, &:focus': {
      color: alpha(`rgba(${categoryColorBg})`, 0.8),
    },
  }),
  progress: ({ categoryColorBg }) => ({
    backgroundColor: `rgba(${categoryColorBg})`,
    position: 'absolute',
    width: 'calc(100% - 24px)',
    top: '50%',
    height: 3,
    borderRadius: theme.spacing(),
  }),
  bar: ({ categoryColorText }) => ({
    backgroundColor: `rgba(${categoryColorText})`,
  }),
}));

const CoachListItem = React.forwardRef(
  (
    {
      endUserTitle,
      contentCid,
      categoryTitle,
      categoryColorText,
      categoryColorBg,
      duration,
      secondaryActionIcon: SecondaryActionIcon,
      onSecondaryActionClick,
      secondaryText,
      style,
    },
    ref,
  ) => {
    const classes = useStyles({ categoryColorText, categoryColorBg });
    const { mutate, data } = useGetDeckProgress();

    useEffect(() => {
      mutate(contentCid);
    }, []);

    return (
      <ListItem
        ref={ref}
        disablePadding
        className={classes.container}
        sx={{ ...style }}
        secondaryAction={
          !!SecondaryActionIcon && (
            <IconButton
              disableRipple
              className={classes.action}
              onClick={onSecondaryActionClick}
            >
              <SecondaryActionIcon />
            </IconButton>
          )
        }
      >
        <ListItemButton
          component={Link}
          to={`/coach/deck/${contentCid}`}
          sx={{ p: 1.5, display: 'block' }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={1.5}
            mb={1.5}
          >
            <Chip
              label={categoryTitle}
              sx={{
                bgcolor: `rgba(${categoryColorBg})`,
                color: `rgba(${categoryColorText})`,
                cursor: 'pointer',
              }}
            />
            <Typography color="textSecondary">
              {secondaryText ? (
                secondaryText
              ) : (
                <FormattedMessage id="time.minutes" values={{ duration }} />
              )}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={Math.min(data?.progress?.[contentCid] || 0, 100)}
            classes={{ root: classes.progress, bar: classes.bar }}
          />
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontWeight: 'bold',
                mr: data?.progress?.[contentCid] >= 100 ? 4 : 0,
              }}
            >
              {endUserTitle}
            </Typography>
            {data?.progress?.[contentCid] >= 100 && (
              <CheckIcon
                sx={{
                  position: 'absolute',
                  right: 8,
                  color: `rgba(${categoryColorText})`,
                }}
              />
            )}
          </Box>
        </ListItemButton>
      </ListItem>
    );
  },
);

CoachListItem.displayName = 'CoachListItem';
export default CoachListItem;
