import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g>
    <path
      d="m109.447 128.084c-9.5398-5.8727-14.0921-16.5808-15.8966-29.3323l-13.7028-.0002c-2.0519 14.484-7.3208 24.0531-15.8965 29.3325l21.6535 2.54z"
      fill="#ffbc08"
    />
    <path
      d="m113.924 149.796v-21.712h-4.477-45.4964-4.4777v21.712z"
      fill="#474e5e"
    />
    <path
      d="m108.899 87.2902 6.9204-8.9113c6.2963-9.9069 10.3233-22.3852 12.0798-37.4361l.9863-22.2469c-.0192-1.0501-.0467-2.1097-.0824-3.1786-.0872-2.6103-2.1231-4.6801-4.6036-4.6801h-75.0011c-2.4803 0-4.5168 2.07-4.6037 4.6801-.0357 1.0689-.0631 2.1285-.0823 3.1787l.9861 22.247c1.7565 15.0509 5.7837 27.5291 12.0801 37.436l6.9201 8.9108c4.9364 5.2137 10.7855 9.3934 17.5479 12.5381 3.0722 1.4287 6.2324 1.429 9.3048 0 6.7621-3.1449 12.6112-7.3242 17.5476-12.5377z"
      fill="#ffdc0b"
    />
    <path d="m48.4718 148.526h76.4532v14.034h-76.4532z" fill="#353943" />
    <g fill="#ffbc08">
      <path d="m37.8503 36.0753c-1.3331 2.2267-.75 5.2044 1.3024 6.6507 2.0525 1.4462 4.7971.8136 6.1302-1.4131 2.4586-4.1227 3.3333-8.8996 2.7401-13.4415-1.7117-13.1036-14.6124-20.7918-25.8209-15.1719-8.3803 4.2019-12.209 13.6789-11.2093 23.3609 1.3907 13.4695 13.5012 31.1956 43.1105 50.0922 3.1295 1.6277 1.8338 6.6086-2.1375 5.5446-2.3018-.9156-4.8521.3665-5.6961 2.8637-1.1256 3.3303.9472 5.9762 3.9739 6.4183 13.9691 2.0424 20.5543-15.349 7.136-22.7142-26.7087-17.0455-36.4357-32.3218-37.565-43.2596-.5687-5.5086 1.2333-11.1219 6.1038-13.564 5.7451-2.8806 12.4361 1.0012 13.3175 7.7487.3058 2.342-.1361 4.79-1.3856 6.8852z" />
      <path d="m135.547 36.0753c1.3331 2.2267.75 5.2044-1.3024 6.6507-2.0525 1.4462-4.7971.8136-6.1302-1.4131-2.4586-4.1227-3.3333-8.8996-2.7401-13.4415 1.7117-13.1036 14.6124-20.7918 25.8209-15.1719 8.3803 4.2019 12.209 13.6789 11.2093 23.3609-1.3907 13.4695-13.5012 31.1956-43.1105 50.0922-3.1295 1.6277-1.8338 6.6086 2.1375 5.5446 2.3018-.9156 4.8521.3665 5.6961 2.8637 1.1256 3.3303-.9472 5.9762-3.9739 6.4183-13.9691 2.0424-20.5543-15.349-7.136-22.7142 26.7087-17.0455 36.4357-32.3218 37.565-43.2596.5687-5.5086-1.2333-11.1219-6.1038-13.564-5.7451-2.8806-12.4361 1.0012-13.3175 7.7487-.3058 2.342.1361 4.79 1.3856 6.8852z" />
    </g>
    <path
      d="m86.6986 22.5355 6.6295 13.9737 15.3389 1.9869-11.2416 10.6232 2.8496 15.2016-13.5764-7.4081-13.5769 7.4081 2.8501-15.2016-11.2411-10.6232 15.3384-1.9869z"
      fill="#ff9e05"
    />
    <path
      d="m114.966 46.7357.5877-25.6699h4.9963s-1.1754 19.6619-5.584 25.6699z"
      fill="#f9f9f9"
    />
  </g>,
  'TrophyIcon',
  {
    viewBox: '0 0 174 174',
  },
);
