/**
 * Omit specific props from a props object. Useful when passing props to nested component
 * Example:
 *    <WrappedComponent
 *      {...omit(this.props, 'excludeProp1', 'excludeProp2')}
 *    />
 */
export const omit = (obj, ...keysToOmit) =>
  Object.keys(obj).reduce((acc, key) => {
    if (keysToOmit.indexOf(key) === -1) acc[key] = obj[key];
    return acc;
  }, {});

/**
 * Gets the value at path of object. If the resolved value is null or undefined,
 * the defaultValue is returned in its place.
 * @param {Object} object
 * @param {String|Array} path
 * @param {*} defaultVal
 */
export const getProp = (object, path, defaultVal) => {
  const _path = Array.isArray(path)
    ? path
    : path.split('.').filter((i) => i.length);

  if (!_path.length) {
    return object == null ? defaultVal : object;
  }
  return getProp(object ? object[_path.shift()] : {}, _path, defaultVal);
};

/**
 * Debounce function that swallows repetitive calls within a predefined timeframe
 * @param fn The function to apply debounce to
 * @param time in ms
 * @returns {Function} The modified function
 */
export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

/**
 * effectively Object.assign - here for IE compatibility
 * @param colDef
 * @param from
 * @returns {*}
 */
export const assign = (colDef, from) =>
  [from].reduce(function (r, o) {
    Object.keys(o).forEach(function (k) {
      r[k] = o[k];
    });
    return r;
  }, colDef);

export const isProduction = () => {
  // Temporarily hardcoding the hostnames
  // TODO: https://eskalera.atlassian.net/browse/OPS-445
  return (
    window.location.hostname === 'app.eskalera.com' ||
    window.location.hostname === 'web.eskalera.com'
  );
};
