import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import LightbulbIcon from 'components/SvgIcons/Lightbulb';
import PrivacyDialog from 'components/PrivacyDialog';
import PuzzleIcon from 'components/SvgIcons/Puzzle';
import RocketIcon from 'components/SvgIcons/Rocket';
import ShieldIcon from 'components/SvgIcons/Shield';
import { useBooleanState } from 'core/hooks/useBooleanState';

const Welcome = ({ setStep, username }) => {
  const handleNext = () => setStep(1);
  const [dialogIsOpen, toggleDialogOpen] = useBooleanState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Box textAlign="center" mb={5}>
          <Typography>
            <FormattedMessage
              id="onboarding.welcome.title"
              values={{ username }}
            />
          </Typography>
          <Typography variant="h1" sx={{ mt: 1, whiteSpace: 'pre-line' }}>
            <FormattedMessage id="onboarding.welcome.subtitle" />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={3}>
          <LightbulbIcon sx={{ height: 40, width: 40 }} />
          <Box ml={3}>
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
              <FormattedMessage id="onboarding.welcome.1.title" />
            </Typography>
            <Typography color="textSecondary">
              <FormattedMessage id="onboarding.welcome.1.description" />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={3}>
          <PuzzleIcon sx={{ height: 40, width: 40 }} />
          <Box ml={3}>
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
              <FormattedMessage id="onboarding.welcome.2.title" />
            </Typography>
            <Typography color="textSecondary">
              <FormattedMessage id="onboarding.welcome.2.description" />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={3}>
          <RocketIcon sx={{ height: 40, width: 40 }} />
          <Box ml={3}>
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
              <FormattedMessage id="onboarding.welcome.3.title" />
            </Typography>
            <Typography color="textSecondary">
              <FormattedMessage id="onboarding.welcome.3.description" />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={3}>
          <ShieldIcon sx={{ height: 40, width: 40 }} />
          <Box ml={3}>
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
              <FormattedMessage id="onboarding.welcome.4.title" />
            </Typography>
            <Typography color="textSecondary" display="inline">
              <FormattedMessage
                id="onboarding.welcome.4.description"
                values={{
                  action: (
                    <Link
                      display="inline"
                      sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={toggleDialogOpen}
                    >
                      <FormattedMessage id="action.learnMore" />
                    </Link>
                  ),
                }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        onClick={handleNext}
        sx={{ display: 'block', mx: 'auto' }}
      >
        <FormattedMessage id="action.getStarted" />
      </Button>
      {dialogIsOpen && (
        <PrivacyDialog open onClose={toggleDialogOpen} maxWidth="md" />
      )}
    </Box>
  );
};

Welcome.displayName = 'Welcome';
export default Welcome;
