import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import FormField from 'components/FormField';
import { useStores } from 'core/hooks/useStores';

const OrganizationPickerForm = ({
  identifier,
  password,
  organizations,
  onSuccess,
}) => {
  const intl = useIntl();
  const { authStore } = useStores();
  const { login } = authStore;

  const onSubmit = async (
    { organizationUid },
    { setSubmitting, setErrors },
  ) => {
    try {
      setErrors({});
      await login(identifier, password, organizationUid);
      onSuccess();
    } catch (e) {
      const error = e.response.data?.error;
      if (error === 'ERROR_RATE_LIMITED') {
        setErrors({
          organizationUid: 'Too many login attempts. Try again in 60 seconds.',
        });
      } else if (error === 'ERROR_UNAUTHENTICATED') {
        setErrors({
          organizationUid: intl.formatMessage(
            { id: 'error.input.invalid' },
            { field: 'password' },
          ),
        });
      } else {
        setErrors({
          organizationUid: intl.formatMessage({ id: 'error.generic' }),
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{ organizationUid: '' }}
      validationSchema={Yup.object({
        organizationUid: Yup.string().required(
          intl.formatMessage({ id: 'error.input.empty' }),
        ),
      })}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Typography variant="h1">
            <FormattedMessage id="login.title" />
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 1, mb: 3 }}
          >
            Select the organization you'd like to access
          </Typography>
          <FormField
            select
            name="organizationUid"
            label="Organization"
            fullWidth
            autoFocus
            sx={{ textAlign: 'left' }}
          >
            {organizations.map((o) => (
              <MenuItem key={o.uid} value={o.uid}>
                <Typography variant="body2">
                  {o.displayName}
                  {o.employee && (
                    <span style={{ fontStyle: 'italic', marginLeft: 8 }}>
                      {`(Granted by: ${o.employee.firstName} ${o.employee.lastName})`}
                    </span>
                  )}
                </Typography>
              </MenuItem>
            ))}
          </FormField>
          <Typography
            variant="caption"
            component={Link}
            to="/"
            color="primary"
            sx={{ textDecoration: 'none', mt: 1 }}
          >
            {`Not ${identifier}?`}
          </Typography>
          <Button
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            sx={{ mt: 2 }}
            startIcon={isSubmitting && <CircularProgress size={15} />}
            disabled={isSubmitting}
          >
            <FormattedMessage id="action.continue" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

OrganizationPickerForm.displayName = 'OrganizationPickerForm';
export default OrganizationPickerForm;
