import React from 'react';

import MuiContainer from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import Layout from 'layouts/BasicLayout';
import Image from 'assets/shared/signup.svg';

const Container = ({ children }) => {
  return (
    <Layout>
      <MuiContainer maxWidth="md" sx={{ pt: 15 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={12}
        >
          <Grid xs={12} md={7}>
            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                textAlign: 'center',
                borderRadius: 4,
                py: 10,
                px: 4,
                maxWidth: 600,
                margin: 'auto',
              }}
            >
              {children}
            </Paper>
          </Grid>
          <Grid xs sx={{ display: { xs: 'none', md: 'block' } }}>
            <img src={Image} alt="" height={400} width={420} />
          </Grid>
        </Grid>
      </MuiContainer>
    </Layout>
  );
};

Container.displayName = 'Container';
export default Container;
