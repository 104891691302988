import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Droppable } from 'react-beautiful-dnd';

const DropContainer = ({ droppableId, isDropDisabled, classes, children }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={cn(classes.root, {
            [classes.isDraggingOver]: snapshot.isDraggingOver,
          })}
          {...provided.droppableProps}
        >
          {children}
          <div style={{ visibility: 'hidden', height: 0 }}>
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

DropContainer.propTypes = {
  droppableId: PropTypes.string.isRequired,
  isDropDisabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    isDraggingOver: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DropContainer.displayName = 'DropContainer';
export default DropContainer;
