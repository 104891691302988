import axios from 'axios';

export const statusCodes = {
  ABORTED: 0,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  REDIRECT: 302,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  PRECONDITION_FAILED: 412,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const successCodes = [
  statusCodes.OK,
  statusCodes.CREATED,
  statusCodes.ACCEPTED,
  statusCodes.NON_AUTHORITATIVE_INFORMATION,
  statusCodes.NO_CONTENT,
  statusCodes.RESET_CONTENT,
  statusCodes.PARTIAL_CONTENT,
  statusCodes.MULTI_STATUS,
  statusCodes.ALREADY_REPORTED,
  statusCodes.IM_USED,
];

const ApiService = {
  init() {
    this.BASE_URL = process.env.REACT_APP_API_BASE;
    axios.create({ baseURL: this.BASE_URL });
    this.setHeader('Content-Type', 'application/json');
    this.setResponseInterceptor((response) => response.data);
  },

  setHeader(key, value) {
    axios.defaults.headers.common[key] = value;
  },

  removeHeader(key) {
    axios.defaults.headers.common[key] = {};
  },

  setResponseInterceptor(...args) {
    axios.interceptors.response.use(...args);
  },

  setRequestInterceptor(...args) {
    axios.interceptors.request.use(...args);
  },

  /**
   * Part of hack in init. Will use this until local services are exposed over
   * a single gateway.
   */
  createFullURL(url) {
    return `${this.BASE_URL}/${url}`;
  },

  request(...args) {
    return axios.request(...args);
  },

  get(url, ...args) {
    const fullUrl = this.createFullURL(url);
    return axios.get(fullUrl, ...args);
  },

  post(url, ...args) {
    const fullUrl = this.createFullURL(url);
    return axios.post(fullUrl, ...args);
  },

  put(url, ...args) {
    const fullUrl = this.createFullURL(url);
    return axios.put(fullUrl, ...args);
  },

  patch(url, ...args) {
    const fullUrl = this.createFullURL(url);
    return axios.patch(fullUrl, ...args);
  },

  delete(url, ...args) {
    const fullUrl = this.createFullURL(url);
    return axios.delete(fullUrl, ...args);
  },
};

export default ApiService;
