import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import { useGetCategories, useUpdateEmployeeInterests } from 'core/api/feed';

const Categories = ({ setStep }) => {
  const { data, isLoading, error } = useGetCategories();
  const { mutate } = useUpdateEmployeeInterests();

  const [selected, setSelected] = useState([]);

  const handleSelect = (targetId) => () => {
    if (selected.includes(targetId)) {
      setSelected(selected.filter((id) => id !== targetId));
    } else {
      setSelected([...selected, targetId]);
    }
  };

  const handleNext = () => {
    mutate({ interests: { categories: selected } });
    setStep(2);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Typography variant="h2" sx={{ mb: 1 }}>
          <FormattedMessage id="onboarding.categories.title" />
        </Typography>
        <Typography color="textSecondary">
          <FormattedMessage id="onboarding.interests.description" />
        </Typography>
        {!isLoading && (
          <ErrorBoundary error={error}>
            <Box mt={4} mb={3}>
              {data
                .filter(({ hideFromUserInterests }) => !hideFromUserInterests)
                .map(({ title, id }) => (
                  <Chip
                    key={id}
                    label={title}
                    variant="outlined"
                    color={selected.includes(id) ? 'secondary' : 'default'}
                    sx={{ mr: 1, mb: 1 }}
                    onClick={handleSelect(id)}
                  />
                ))}
            </Box>
          </ErrorBoundary>
        )}
      </Box>
      <Button
        variant="contained"
        onClick={handleNext}
        sx={{ display: 'block', mx: 'auto' }}
      >
        <FormattedMessage id="action.continue" />
      </Button>
    </Box>
  );
};

Categories.displayName = 'Categories';
export default Categories;
