import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <path
    d="M35.4244 77.2899C37.6755 78.4146 40.3245 78.4146 42.5756 77.2899L66.8564 65.1584C69.0175 64.0787 70.5749 62.0802 71.0939 59.7208L77.1964 31.9814C77.704 29.674 77.1658 27.2603 75.726 25.3872L58.7571 3.31084C57.2428 1.34079 54.8991 0.186203 52.4143 0.186203H25.5857C23.1009 0.186203 20.7572 1.34078 19.2429 3.31084L2.27396 25.3872C0.834185 27.2603 0.295965 29.674 0.803569 31.9814L6.90607 59.7208C7.42512 62.0802 8.98254 64.0787 11.1436 65.1584L35.4244 77.2899Z"
    fill="#EAEAEA"
  />,
  'BadgeCountIcon',
  { viewBox: '0 -2 78 79' },
);
