import React, { useEffect } from 'react';
import { observer, useObserver } from 'mobx-react';
import { QueryClient, QueryClientProvider } from 'react-query';

import ConnectedIntlProvider from 'components/ConnectedIntlProvider';
import Routes from 'components/Routes';
import Notifications from 'components/Notifications';
import { useStores } from 'core/hooks/useStores';
import { isProduction } from 'core/utils/common';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main = observer(() => {
  const { userStore } = useStores();

  const { loggedIn, getUser, user } = useObserver(() => ({
    loggedIn: userStore.loggedIn,
    getUser: userStore.get,
    user: userStore.user,
  }));

  useEffect(() => {
    const fetch = async () => {
      if (!user) {
        // Start fetching the user data but don't await it to make sure <Routes/> only renders once
        getUser();
      }
    };
    fetch();
  }, [loggedIn, getUser, user]);

  //Embed Churn Zero on Page Load, script source is in the index.html file, loaded in the dom
  //https://support.churnzero.net/hc/en-us/articles/360004683552-Integrate-ChurnZero-using-Javascript
  useEffect(() => {
    if (user && isProduction()) {
      window.ChurnZero.push([
        'setAppKey',
        '1!Wl6C8jxt50Elw6zQNznih08XeWK4kQvnc0zsqWy00p4tFFC',
      ]);
      window.ChurnZero.push([
        'setContact',
        user.organization.uid,
        user.employee.id,
      ]);
    }
  }, [user]);

  return (
    <QueryClientProvider client={queryClient}>
      <ConnectedIntlProvider>
        <Routes />
        <Notifications />
      </ConnectedIntlProvider>
    </QueryClientProvider>
  );
});

export default Main;
