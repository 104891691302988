import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from './Checkbox';

export function CheckboxWithLabel({ ...props }) {
  return <FormControlLabel control={<Checkbox />} {...props} />;
}

CheckboxWithLabel.displayName = 'CheckboxWithLabel';
export default CheckboxWithLabel;
