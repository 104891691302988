import PropTypes from 'prop-types';
import React from 'react';
import { useField, useFormikContext } from 'formik';

import Checkbox from '@mui/material/Checkbox';

import { FormControl, FormControlLabel, FormGroup } from 'components/FormField';

const CheckboxMultiSelect = ({ id, options }) => {
  const [, { value: response = [] }] = useField({ name: id });
  const { isSubmitting } = useFormikContext();

  return (
    <FormGroup name={id}>
      <FormControl name={id}>
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            label={label}
            checked={response.includes(value)}
            control={<Checkbox name={id} value={value} />}
            disabled={isSubmitting}
            sx={{ '.MuiFormControlLabel-label': { py: '9px' } }}
          />
        ))}
      </FormControl>
    </FormGroup>
  );
};

CheckboxMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

CheckboxMultiSelect.displayName = 'CheckboxMultiSelect';
export default CheckboxMultiSelect;
