import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import Container from 'containers/auth/components/Container';
import EmptyState from 'components/EmptyState';
import PasswordField from 'components/FormField/PasswordField';
import { useStores } from 'core/hooks/useStores';

const ResetPassword = observer(() => {
  const intl = useIntl();

  const [success, setSuccess] = useState(false);

  const { authStore } = useStores();
  const { resetPassword } = authStore;

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const onSubmit = async ({ password }, { setSubmitting, setErrors }) => {
    try {
      setErrors({});
      await resetPassword(token, password);
      setSuccess(true);
    } catch (e) {
      setErrors({
        password: intl.formatMessage({ id: 'error.generic' }),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      {success ? (
        <EmptyState
          title="Password changed"
          description="Your password has been successfully updated. You can now log in with your new password."
          action={
            <Button variant="contained" component={Link} to="/login">
              Login
            </Button>
          }
        />
      ) : (
        <Formik
          validateOnBlur={false}
          onSubmit={onSubmit}
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            password: Yup.string()
              .min(8, intl.formatMessage({ id: 'error.password.length' }))
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
                intl.formatMessage({ id: 'error.password.characters' }),
              )
              .required(intl.formatMessage({ id: 'error.input.empty' })),
            confirmPassword: Yup.string()
              .oneOf(
                [Yup.ref('password'), null],
                intl.formatMessage({ id: 'error.password.match' }),
              )
              .required(intl.formatMessage({ id: 'error.input.empty' })),
          })}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Typography variant="h1">Reset your password</Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ mt: 1, mb: 3 }}
              >
                Please enter a new password.
              </Typography>
              <Box mb={2}>
                <PasswordField
                  name="password"
                  label={intl.formatMessage({ id: 'user.password.new' })}
                  autoComplete="new-password"
                  fullWidth
                  autoFocus
                />
              </Box>
              <Box mb={2}>
                <PasswordField
                  name="confirmPassword"
                  label={intl.formatMessage({ id: 'user.password.confirm' })}
                  autoComplete="new-password"
                  fullWidth
                />
              </Box>
              <Button
                size="large"
                fullWidth
                variant="contained"
                type="submit"
                sx={{ mb: 2 }}
                startIcon={isSubmitting && <CircularProgress size={15} />}
                disabled={isSubmitting}
              >
                Reset password
              </Button>
              <Typography
                sx={{ textDecoration: 'none' }}
                variant="body1"
                component={Link}
                to="/login"
                display="inline"
                color="primary"
              >
                Back to login
              </Typography>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
});

ResetPassword.displayName = 'ResetPassword';
export default ResetPassword;
