import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g>
    <path
      d="M17.3279 10.9008C17.0996 10.5196 16.6753 10.3008 16.2323 10.3344L6.7093 11.0701C5.74516 11.1446 4.88634 11.6499 4.35291 12.4566L0.356456 18.5013C-0.0479937 19.1131 -0.112368 19.8786 0.184348 20.5492C0.480986 21.2198 1.09075 21.6871 1.81535 21.7994L9.53717 22.9954C9.59724 23.0047 9.65724 23.0093 9.71677 23.0093C10.1123 23.0093 10.4865 22.8086 10.704 22.4685L17.3099 12.134C17.5492 11.7595 17.5561 11.282 17.3279 10.9008Z"
      fill="#00A2AE"
    />
    <path
      d="M29.0996 22.6719C28.7183 22.4436 28.2408 22.4505 27.8664 22.6898L17.5319 29.2958C17.1406 29.5459 16.934 30.0036 17.005 30.4626L18.2009 38.1844C18.3132 38.9091 18.7805 39.5188 19.4512 39.8155C19.7301 39.9388 20.0254 39.9997 20.3193 39.9997C20.7319 39.9997 21.1418 39.8795 21.4991 39.6434L27.5438 35.6468C28.3505 35.1136 28.8559 34.2547 28.9303 33.2904L29.6659 23.7675C29.7002 23.3245 29.4808 22.9002 29.0996 22.6719Z"
      fill="#00A2AE"
    />
    <path
      d="M15.0676 32.209C15.5341 32.6754 16.1968 32.8936 16.8473 32.7848C26.3872 31.1896 33.9363 23.2909 36.8103 17.7345C40.0016 11.5647 40.1198 5.04475 39.953 1.98696C39.8958 0.93915 39.0606 0.103927 38.0128 0.0467401C34.955 -0.119978 28.4351 -0.00177479 22.2652 3.18953C16.7089 6.06349 8.81016 13.6126 7.21501 23.1525C7.10626 23.803 7.32439 24.4658 7.79079 24.9322L15.0676 32.209Z"
      fill="#CCEDEF"
    />
    <path
      d="M39.3544 0.645508L11.4292 28.5707L15.0677 32.2091C15.5341 32.6755 16.1968 32.8936 16.8473 32.7849C26.3873 31.1897 33.9363 23.291 36.8103 17.7347C40.0016 11.5649 40.1198 5.04493 39.953 1.98714C39.9244 1.46324 39.7013 0.992458 39.3544 0.645508Z"
      fill="#79C9D0"
    />
    <path
      d="M27.4009 18.4559C25.9002 18.4559 24.4001 17.8848 23.2577 16.7424C22.151 15.6358 21.5415 14.1643 21.5415 12.5993C21.5415 11.0342 22.151 9.5627 23.2577 8.45609C25.5421 6.17158 29.2594 6.1715 31.544 8.45609C32.6507 9.5627 33.2601 11.0342 33.2601 12.5993C33.2601 14.1643 32.6507 15.6358 31.544 16.7424C30.4019 17.8846 28.9011 18.4557 27.4009 18.4559Z"
      fill="#F4DB6B"
    />
    <path
      d="M7.21501 23.1527C7.10626 23.8032 7.32439 24.4659 7.79079 24.9323L15.0676 32.2092C15.534 32.6756 16.1968 32.8937 16.8473 32.7849C18.9232 32.4378 20.9046 31.7921 22.7642 30.9371L9.06281 17.2358C8.2079 19.0954 7.56212 21.0769 7.21501 23.1527Z"
      fill="#F4DB6B"
    />
    <path
      d="M1.19528 32.9637C1.49512 32.9637 1.79512 32.8493 2.02387 32.6205L5.84989 28.7945C6.30754 28.3369 6.30754 27.5948 5.84989 27.1372C5.39231 26.6795 4.65036 26.6795 4.19262 27.1372L0.36668 30.9632C-0.0909766 31.4208 -0.0909766 32.1628 0.36668 32.6205C0.595508 32.8494 0.895352 32.9637 1.19528 32.9637Z"
      fill="#E28237"
    />
    <path
      d="M9.35629 30.6435C8.89872 30.1859 8.15668 30.1859 7.69903 30.6435L0.343242 37.9993C-0.114414 38.4568 -0.114414 39.1989 0.343242 39.6565C0.57207 39.8854 0.871992 39.9997 1.17192 39.9997C1.47184 39.9997 1.77176 39.8853 2.00051 39.6564L9.35629 32.3007C9.81395 31.8431 9.81395 31.1011 9.35629 30.6435Z"
      fill="#E28237"
    />
    <path
      d="M12.8626 34.1498C12.405 33.6922 11.663 33.6922 11.2054 34.1498L7.37938 37.9758C6.92172 38.4333 6.92172 39.1754 7.37938 39.633C7.60821 39.8619 7.90813 39.9763 8.20797 39.9763C8.50782 39.9763 8.80782 39.8619 9.03657 39.633L12.8626 35.807C13.3203 35.3494 13.3203 34.6074 12.8626 34.1498Z"
      fill="#E28237"
    />
    <path
      opacity="0.376093"
      d="M0.34375 39.6567C0.572578 39.8855 0.8725 39.9999 1.17242 39.9999C1.47234 39.9999 1.77226 39.8854 2.00101 39.6566L9.35679 32.3008C9.81445 31.8432 9.81445 31.1012 9.35679 30.6436L0.34375 39.6567Z"
      fill="#FFD47C"
    />
    <path
      opacity="0.177734"
      d="M11.4292 28.5707L15.0677 32.2091C15.5341 32.6755 16.1968 32.8936 16.8473 32.7849C18.9232 32.4378 20.9046 31.792 22.7642 30.9371L15.9136 24.0864L11.4292 28.5707Z"
      fill="#7D7C7C"
    />
    <path
      opacity="0.174456"
      d="M23.2578 16.7425C24.4002 17.8849 25.9003 18.4561 27.401 18.4559C28.9012 18.4557 30.402 17.8846 31.5441 16.7425C32.6508 15.6357 33.2603 14.1643 33.2603 12.5993C33.2603 11.0342 32.6508 9.5627 31.5441 8.45605L23.2578 16.7425Z"
      fill="#7D7C7C"
    />
    <path
      d="M27.4004 9.08545C26.5002 9.08545 25.5999 9.42811 24.9146 10.1135C24.2506 10.7775 23.8848 11.6603 23.8848 12.5994C23.8848 13.5384 24.2506 14.4212 24.9146 15.0852C26.2853 16.4559 28.5157 16.456 29.8864 15.0852C30.5504 14.4212 30.9161 13.5383 30.9161 12.5993C30.9161 11.6602 30.5504 10.7774 29.8864 10.1134C29.2011 9.42811 28.3008 9.08545 27.4004 9.08545Z"
      fill="#F9FCFF"
    />
    <path
      opacity="0.257231"
      d="M29.8868 10.1133L24.915 15.0851C26.2857 16.4558 28.5162 16.4559 29.8868 15.0851C30.5508 14.421 30.9165 13.5382 30.9165 12.5991C30.9165 11.6601 30.5509 10.7773 29.8868 10.1133Z"
      fill="#4C97FF"
    />
  </g>,
  'RocketIcon',
  {
    viewBox: '0 0 40 40',
  },
);
