import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <path
    d="M13.1998 2.91105L12.8798 1.31105C12.7598 0.697718 12.2132 0.244385 11.5732 0.244385H1.99984C1.2665 0.244385 0.666504 0.844385 0.666504 1.57772V21.5777C0.666504 22.3111 1.2665 22.9111 1.99984 22.9111C2.73317 22.9111 3.33317 22.3111 3.33317 21.5777V13.5777H10.7998L11.1198 15.1777C11.2398 15.8044 11.7865 16.2444 12.4265 16.2444H19.3332C20.0665 16.2444 20.6665 15.6444 20.6665 14.9111V4.24438C20.6665 3.51105 20.0665 2.91105 19.3332 2.91105H13.1998Z"
    fill="#1061AD"
  />,
  'FlagIcon',
  { viewBox: '0 0 21 23' },
);
