import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import Card from './Card';
import DesktopContainer from './components/DesktopContainer';
import EmptyState from 'components/EmptyState';
import ErrorBoundary from 'components/ErrorBoundary';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import MobileContainer from './components/MobileContainer';
import { useGetDeck, useSaveResponse, useSaveEvent } from 'core/api/coach';
import { errorNotification } from 'components/Notifications';
import { COACH_VIEW } from 'core/utils/permissions';

const Deck = ({
  match: {
    params: { deckId },
  },
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const intl = useIntl();

  const { data, isLoading, error } = useGetDeck(deckId);
  const { id, cards, responses, isBookmarked, ...deck } = data || {};

  const { mutateAsync: saveEvent } = useSaveEvent();
  const { mutate: saveResponse } = useSaveResponse();

  const [[activeStep, direction], setActiveStep] = useState([0, 0]);

  const steps = !isLoading && [
    { ...deck, contentType: 'first' },
    ...cards,
    { ...deck, contentType: 'last' },
  ];

  const paginate = (direction) =>
    setActiveStep([activeStep + direction, direction]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const step = steps[activeStep];

    if (Object.keys(values).includes(step.id)) {
      try {
        await saveResponse({
          contentId: deckId,
          contentChildId: step.id,
          response: values[step.id],
          isSurvey: step.isSurvey || false,
        });
      } catch {
        errorNotification(intl.formatMessage({ id: 'error.generic' }));
      }
    }

    // save card event for steps with ids (i.e. all cards but first & last)
    if (step.id) {
      saveEvent({
        contentType: 'CARD',
        contentId: step.id,
        parentContentId: deckId,
        event: 'started+completed',
        isSurvey: step.isSurvey || false,
      });
    }

    paginate(1);
    setSubmitting(false);
  };

  useEffect(() => {
    setActiveStep([0, 0]);
  }, [deckId, isLoading]);

  const progress = (
    <LinearProgress
      value={(activeStep / (steps.length - 1)) * 100 || 0}
      variant="determinate"
      thickness={5}
      sx={{
        '.MuiLinearProgress-bar': {
          bgcolor: `rgba(${deck.categoryPrimary?.colorText})`,
        },
      }}
    />
  );

  const Container = isMobile ? MobileContainer : DesktopContainer;

  return (
    <Gate permissions={[COACH_VIEW]} redirectOnDenied>
      {!isLoading && (
        <Layout
          align="center"
          disablePadding={isMobile}
          color={`rgba(${deck.categoryPrimary.colorText})`}
        >
          <ErrorBoundary error={error}>
            <Formik
              initialValues={responses}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ isSubmitting }) => (
                <Form noValidate style={{ height: '100%' }}>
                  <Container
                    paginate={paginate}
                    disableNavigation={isSubmitting}
                    activeStep={activeStep}
                    totalSteps={steps.length - 1}
                  >
                    <Card
                      deckId={deckId}
                      progress={progress}
                      isBookmarked={isBookmarked}
                      direction={direction}
                      activeStep={activeStep}
                      color={`rgba(${deck.categoryPrimary.colorText})`}
                      {...steps[activeStep]}
                    />
                  </Container>
                </Form>
              )}
            </Formik>
          </ErrorBoundary>
        </Layout>
      )}
      {isLoading && (
        <Layout align="center" disablePadding={isMobile}>
          <EmptyState isLoading />
        </Layout>
      )}
    </Gate>
  );
};

Deck.displayName = 'Deck';
export default Deck;
