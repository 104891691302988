import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plyr from 'plyr';
import { FormattedMessage } from 'react-intl';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  video: {
    width: '100%',
  },
}));

const Video = ({ video, closedCaptions }) => {
  const classes = useStyles();

  // Use the plyr library to style videoplayer controls
  const videoRef = createRef();

  useEffect(() => {
    const plyr = new Plyr(video.current);

    return () => {
      plyr.pause();
      plyr.destroy();
    };
  }, [videoRef, video]);

  return (
    <video
      autoPlay
      controls
      controlsList="nodownload"
      className={classes.video}
      crossOrigin="anonymous"
      ref={videoRef}
    >
      <source src={video} type="video/mp4" />
      <FormattedMessage id="video.error" />
      <track kind="captions" src={closedCaptions} />
    </video>
  );
};

Video.propTypes = {
  video: PropTypes.string.isRequired,
  closedCaptions: PropTypes.string,
};

Video.displayName = 'Video';
export default Video;
