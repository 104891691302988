import palette from '../palette';

export default {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
  },
  styleOverrides: {
    contained: {
      '&:focus, &:focus-visible': {
        outline: `2px solid ${palette.secondary.main}`,
        outlineOffset: -2, // offset outline width to maintain button size
        backgroundColor: palette.primary.dark,
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
        backgroundColor: palette.background.disabled,
      },
    },
    containedSecondary: {
      color: palette.primary.main,
      backgroundColor: palette.secondary.light,
      '&:hover': {
        color: palette.primary.dark,
        backgroundColor: palette.secondary.light,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          color: palette.primary.main,
          backgroundColor: palette.secondary.light,
        },
      },
      '&:focus, &:focus-visible': {
        outline: `2px solid ${palette.secondary.main}`,
        outlineOffset: -2, // offset outline width to maintain button size
        backgroundColor: palette.primary.light,
        color: palette.primary.dark,
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
        backgroundColor: palette.background.disabled,
      },
    },
    outlined: {
      '&:hover': {
        color: palette.primary.dark,
        backgroundColor: palette.secondary.light,
        border: `1px solid ${palette.primary.dark}`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          color: palette.primary.main,
          backgroundColor: palette.common.white,
          border: `1px solid ${palette.primary.main}`,
        },
      },
      '&:focus, &:focus-visible': {
        outline: `2px solid ${palette.secondary.main}`,
        outlineOffset: -2, // offset outline width to maintain button size
        backgroundColor: palette.secondary.light,
        color: palette.primary.dark,
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
        borderColor: palette.background.disabled,
      },
    },
    text: {
      '&:hover, &:focus, &:focus-visible': {
        color: palette.primary.dark,
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
      },
    },
  },
};
