import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Formik, Form } from 'formik';

export const FormWizardContext = React.createContext(null);

const FormWizard = ({
  onSubmit,
  children,
  formProps,
  onCancel,
  startAtStep = 0,
  ...otherFormikProps
}) => {
  const [activeStep, setActiveStep] = useState(startAtStep);

  const stepsContainer = React.Children.toArray(children).find(
    (child) => child.type?.displayName === 'FormWizardSteps',
  );
  if (!stepsContainer) {
    throw new Error('<FormWizard> expects a direct child of <FormWizardSteps>');
  }
  const steps = React.Children.toArray(stepsContainer.props.children);

  const totalSteps = steps.length;
  const isLastStep = activeStep === totalSteps - 1;
  const isFirstStep = activeStep === 0;
  const previousStep = () => setActiveStep(Math.max(activeStep - 1, 0));
  const nextStep = () =>
    setActiveStep(Math.min(activeStep + 1, totalSteps - 1));

  const {
    validationSchema: stepValidationSchema,
    onSubmit: stepOnSubmit,
  } = steps[activeStep].props;

  const contextProps = {
    activeStep,
    stepTitles: steps.map((s) => s.props.title),
    totalSteps,
    isFirstStep,
    isLastStep,
    previousStep,
    nextStep,
    onCancel,
    onSubmit,
  };

  const handleSubmit = (values, bag) => {
    if (stepOnSubmit) {
      stepOnSubmit(values, {
        ...bag,
        ...contextProps,
      });
    } else if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      nextStep();
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={stepValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      {...otherFormikProps}
    >
      {(props) => (
        <Form noValidate {...formProps}>
          <FormWizardContext.Provider
            value={{
              ...props,
              ...contextProps,
            }}
          >
            {children}
          </FormWizardContext.Provider>
        </Form>
      )}
    </Formik>
  );
};

FormWizard.propTypes = {
  ...Formik.propTypes,
  children: PropTypes.node.isRequired,
  formProps: PropTypes.object,
  onCancel: PropTypes.func,
  startAtStep: PropTypes.number,
};

FormWizard.displayName = 'FormWizard';
export default FormWizard;
