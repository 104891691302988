import { useMutation, useQuery, useInfiniteQuery } from 'react-query';
import { CoachService } from 'core/services';

export const useGetDecks = (limit = 10) =>
  useInfiniteQuery(
    ['decks', limit],
    async ({ pageParam = 1 }) =>
      await CoachService.getDecks({ limit, page: pageParam }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === limit ? pages.length + 1 : undefined,
    },
  );

export const useGetDeck = (id) =>
  useQuery(['deck', id], () => CoachService.getDeck(id));

export const useGetDeckProgress = () =>
  useMutation((id) => CoachService.getDeckProgress([id]));

export const useGetNextDeck = (deckId) =>
  useQuery('next', () => CoachService.getNextDeck(deckId));

export const useSaveResponse = () => {
  return useMutation(({ contentId, contentChildId, response, isSurvey }) =>
    CoachService.saveResponse(contentId, contentChildId, response, isSurvey),
  );
};

export const useSaveEvent = () => {
  return useMutation(
    ({ contentType, contentId, parentContentId, event, isSurvey }) =>
      CoachService.saveEvent(
        contentType,
        contentId,
        parentContentId,
        event,
        isSurvey,
      ),
  );
};

export const useGetCategories = () =>
  useQuery('categories', () => CoachService.getCategories());

export const useGetCategory = (id) =>
  useQuery(['category', id], () => CoachService.getCategory(id));

export const useGetCollections = () =>
  useQuery('collections', () => CoachService.getCollections());

export const useGetCollection = (id) =>
  useQuery(['collection', id], () => CoachService.getCollection(id));

export const useSetBookmark = () =>
  useMutation(({ id, isBookmarked }) =>
    CoachService.setBookmark(id, isBookmarked),
  );

export const useGetBookmarks = () =>
  useQuery('bookmarks', () => CoachService.getBookmarks(), { retry: false });
