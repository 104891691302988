import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';

import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const PasswordField = ({ disabled, helperText, InputProps, ...props }) => {
  const [field, meta] = useField(props);
  const { isSubmitting } = useFormikContext();
  const [visible, toggleVisible] = useState(false);

  const showError = meta.touched && !!meta.error;

  return (
    <TextField
      type={visible ? 'text' : 'password'}
      disabled={isSubmitting || disabled}
      error={showError}
      helperText={showError ? meta.error : helperText}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password"
              onClick={() => toggleVisible(!visible)}
              size="large"
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...field}
      {...props}
    />
  );
};

PasswordField.displayName = 'PasswordField';

PasswordField.propTypes = { ...TextField.propTypes };

export default PasswordField;
