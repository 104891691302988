import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { useSaveEvent } from 'core/api/path';

const useStyles = makeStyles((theme) => ({
  text: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}));

const ExternalLink = ({ id, title, url, experienceId, programId }) => {
  const classes = useStyles();
  const { mutate } = useSaveEvent();

  const onClick = () =>
    mutate({
      programId,
      contentType: 'LINK',
      contentId: id,
      parentContentId: experienceId,
      event: 'clicked',
    });

  return (
    <Typography
      className={classes.text}
      href={url}
      onClick={onClick}
      component="a"
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </Typography>
  );
};

ExternalLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  experienceId: PropTypes.string,
  programId: PropTypes.string,
};

ExternalLink.displayName = 'ExternalLink';
export default ExternalLink;
