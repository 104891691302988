import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import ErrorBoundary from 'components/ErrorBoundary';
import { useGetCollections } from 'core/api/coach';

const Collections = () => {
  const { data, isLoading, error } = useGetCollections();

  return (
    <Paper
      sx={{
        p: 2,
        width: '100%',
        borderRadius: 2,
        whiteSpace: 'nowrap',
        overflowX: 'scroll',
      }}
      elevation={0}
      variant="outlined"
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Typography variant="h6" sx={{ mb: 1.5 }}>
            <FormattedMessage id="discover.collections.title" />
          </Typography>
          {data.map(({ id, title }) => (
            <Chip
              key={id}
              component={Link}
              to={`/coach/collections/${id}`}
              clickable
              label={title}
              sx={{
                margin: 0.5,
                bgcolor: 'muted.blue',
                color: 'info.dark',
                px: 1.5,
                fontWeight: 'bold',
                height: 88,
                borderRadius: 2,
              }}
            />
          ))}
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Collections.displayName = 'Collections';
export default Collections;
