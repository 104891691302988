import React from 'react';
import PropTypes from 'prop-types';

import CustomContentCard from './CustomContentCard';
import PathCard from './PathCard';

const ProgramCard = ({ contentType, heroImageUrl, ...props }) => {
  const image = JSON.parse(heroImageUrl);

  if (contentType === 'path') {
    return <PathCard src={image.url} {...props} />;
  } else if (contentType === 'custom') {
    return <CustomContentCard src={image.url} {...props} />;
  }
};

ProgramCard.propTypes = {
  contentType: PropTypes.oneOf(['path', 'custom']).isRequired,
  heroImageUrl: PropTypes.string,
};

ProgramCard.displayName = 'ProgramCard';
export default ProgramCard;
