import React from 'react';
import PropTypes from 'prop-types';
import ChartRenderer from 'components/Chart';

const options = {
  chart: {
    fontFamily: 'Lexend',
    toolbar: {
      show: false,
    },
    events: {
      mounted: (chart) => {
        chart.windowResizeHandler();
      },
    },
  },
  colors: ['#48a1a7'],
  fill: {
    opacity: 1,
  },
  dataLabels: {
    formatter: (val) => `${val}%`,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    axisTicks: {
      show: false,
    },
    labels: {
      formatter: (val) => `${val}%`,
    },
  },
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  tooltip: {
    enabled: false,
  },
};

const ColumnChart = ({ series }) => {
  return (
    <ChartRenderer height={400} type="bar" options={options} series={series} />
  );
};

ColumnChart.propTypes = {
  series: PropTypes.any.isRequired,
};
ColumnChart.displayName = 'ColumnChart';
export default ColumnChart;
