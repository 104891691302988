import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import ExperienceDialog from './ExperienceDialog';
import { useGetAttribute } from 'core/api/user';
import { useBooleanState } from 'core/hooks/useBooleanState';

const Experiences = () => {
  const [openDialog, toggleOpenDialog] = useBooleanState(false);

  const { data, isLoading, error } = useGetAttribute('identities');
  const { identities } = data || {};

  return (
    <>
      {!isLoading && !!identities.length && (
        <ErrorBoundary error={error}>
          <Paper
            sx={{ p: 2, mb: 2, width: '100%', borderRadius: 2 }}
            elevation={0}
            variant="outlined"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                <FormattedMessage id="profile.experiences.title" />
              </Typography>
              <Button onClick={toggleOpenDialog}>
                <FormattedMessage id="action.edit" />
              </Button>
            </Box>
            <Box bgcolor="background.default" py={3} px={2} borderRadius={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                <FormattedMessage
                  id="profile.experiences.identities"
                  values={{ count: identities.length }}
                />
              </Typography>
              {identities.map(({ id, value }) => (
                <Chip key={id} label={value} sx={{ mr: 1, mt: 1 }} />
              ))}
            </Box>
          </Paper>
        </ErrorBoundary>
      )}
      {openDialog && (
        <ExperienceDialog open={openDialog} onClose={toggleOpenDialog} />
      )}
    </>
  );
};

Experiences.displayName = 'Experiences';
export default Experiences;
