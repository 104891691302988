import React from 'react';
import { useFormikContext } from 'formik';

import MuiFormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const FormControl = ({ name, children, ...props }) => {
  const { getFieldMeta } = useFormikContext();
  const { touched, error } = getFieldMeta(name);
  const showError = touched && !!error;

  return (
    <MuiFormControl variant="standard" error={showError} {...props}>
      {children}
      {showError && <FormHelperText>{error}</FormHelperText>}
    </MuiFormControl>
  );
};

export default FormControl;
