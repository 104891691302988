import React, { useEffect } from 'react';
import moment from 'moment/moment';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';

import BackArrow from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'layouts/Layout';
import { useGetActivity } from 'core/api/user';

const ActivityDetail = ({ open, onClose }) => {
  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
  } = useGetActivity();

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <Dialog open={open} fullScreen>
      <Layout showNavigation align="center">
        <IconButton
          onClick={onClose}
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            mb: { xs: 1, md: 0 },
          }}
        >
          <BackArrow />
        </IconButton>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{
            width: { xs: '100%', md: 600 },
            margin: 'auto',
            borderRadius: 2,
            minHeight: '100%',
          }}
        >
          <Box
            p={3}
            bgcolor="primary.main"
            color="common.white"
            borderRadius="8px 8px 0px 0px"
          >
            <Typography variant="h2" color="inherit">
              <FormattedMessage id="profile.activity.title" />
            </Typography>
          </Box>
          {!isLoading && (
            <ErrorBoundary error={error}>
              <List sx={{ p: 2, pt: 1 }}>
                {data.pages.map((page) =>
                  page.map(({ lastActivity, ...deck }, idx) => {
                    const utc = moment
                      .utc(lastActivity, 'MM-DD-YYYY hh:mm:ss')
                      .toDate();
                    const local = moment(utc).local();
                    const fromNow = local.fromNow();

                    return (
                      <CoachListItem
                        ref={idx === page.length - 1 ? ref : null}
                        key={idx}
                        secondaryText={fromNow}
                        {...deck}
                      />
                    );
                  }),
                )}
              </List>
            </ErrorBoundary>
          )}
        </Paper>
      </Layout>
    </Dialog>
  );
};

ActivityDetail.displayName = 'ActivityDetail';
export default ActivityDetail;
