import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

/**
 * Switch used to toggle between a person's given answers (assumptions) and the
 * actual answers.
 */
const ShowActualSwitch = ({ typographyProps, ...props }) => {
  return (
    <Box>
      <Typography display="inline" {...typographyProps}>
        <FormattedMessage id="toggle.assumptions" />
      </Typography>
      <Switch
        inputProps={{
          role: 'switch',
          'aria-label': 'Toggle between your responses and actual',
        }}
        {...props}
      />
      <Typography display="inline" {...typographyProps}>
        <FormattedMessage id="toggle.actual" />
      </Typography>
    </Box>
  );
};

ShowActualSwitch.propTypes = {
  /* Whether or not the switch is checked */
  checked: PropTypes.bool.isRequired,
  /* Function to call when the switch is toggled */
  onChange: PropTypes.func.isRequired,
};

ShowActualSwitch.displayName = 'ShowActualSwitch';
export default ShowActualSwitch;
