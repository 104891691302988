import Accordion from './Accordion';
import Activity from './Activity';
import CardFlip from './CardFlip';
import Chart from './Chart';
import DynamicTable from './DynamicTable';
import Email from './Email';
import ExternalLink from './ExternalLink';
import GlossaryTerm from './GlossaryTerm';
import HiddenContent from './HiddenContent';
import Image from './Image';
import Icon from './Icon';
import Menu from './Activity/activities/SingleSelectTableDropdown/Menu';
import PrivacyPolicy from './PrivacyPolicy';
import Toggle from './Activity/activities/SingleSelectTableToggle/Toggle';
import Question from './Question';
import QuestionInsights from './QuestionInsights';
import Reference from './Reference';
import SavableAnswerList from './SavableAnswerList';
import SuperScript from './Superscript';
import Table from './Table';
import Video from './Video';

export const embeddableTypeToComponent = {
  activity: Activity,
  activitySingleSelectTableToggleButton: Toggle,
  contentAccordion: Accordion,
  contentCardFlip: CardFlip,
  contentChart: Chart,
  contentDynamicTable: DynamicTable,
  contentEmail: Email,
  contentExternalLink: ExternalLink,
  contentHidden: HiddenContent,
  contentPrivacyPolicy: PrivacyPolicy,
  contentTable: Table,
  glossaryTerm: GlossaryTerm,
  image: Image,
  icon: Icon,
  singleSelectTableMenu: Menu,
  question: Question,
  questionInsights: QuestionInsights,
  reference: Reference,
  savableAnswerList: SavableAnswerList,
  subscript: SuperScript,
  video: Video,
};
