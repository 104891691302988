import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <path
    d="M12.0001 18.3595L17.5334 21.7062C18.5468 22.3195 19.7868 21.4129 19.5201 20.2662L18.0534 13.9729L22.9468 9.73286C23.8401 8.95953 23.3601 7.49286 22.1868 7.39952L15.7468 6.85286L13.2268 0.906191C12.7734 -0.173809 11.2268 -0.173809 10.7734 0.906191L8.25343 6.83952L1.81343 7.38619C0.640097 7.47953 0.160097 8.94619 1.05343 9.71953L5.94676 13.9595L4.4801 20.2529C4.21343 21.3995 5.45343 22.3062 6.46676 21.6929L12.0001 18.3595Z"
    fill="#E0732F"
  />,
  'StarIcon',
  { viewBox: '0 0 24 22' },
);
