import palette from './palette';

const htmlFontSize = 16;
const fontSize = 14;
export const pxToRem = (size) =>
  `${(size / htmlFontSize) * (fontSize / 14)}rem`;

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemiBold = 600;
const fontWeightBold = 700;
const fontWeightExtraBold = 800;
const fontWeightBlack = 900;

export default {
  htmlFontSize,
  fontSize,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightSemiBold,
  fontWeightBold,
  fontWeightExtraBold,
  fontWeightBlack,
  fontFamily: 'Lexend',
  h1: {
    color: palette.text.primary,
    fontWeight: fontWeightBold,
    fontSize: pxToRem(40),
  },
  h2: {
    color: palette.text.primary,
    fontWeight: fontWeightBold,
    fontSize: pxToRem(32),
  },
  h3: {
    color: palette.text.primary,
    fontWeight: fontWeightBold,
    fontSize: pxToRem(28),
  },
  h4: {
    color: palette.text.primary,
    fontWeight: fontWeightBold,
    fontSize: pxToRem(24),
  },
  h5: {
    color: palette.text.primary,
    fontWeight: fontWeightBold,
    fontSize: pxToRem(22),
  },
  h6: {
    color: palette.text.primary,
    fontWeight: fontWeightBold,
    fontSize: pxToRem(20),
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: fontWeightRegular,
    fontSize: pxToRem(18),
  },
  body1: {
    color: palette.text.primary,
    fontWeight: fontWeightRegular,
    fontSize: pxToRem(16),
  },
  body2: {
    color: palette.text.secondary,
    fontWeight: fontWeightRegular,
    fontSize: pxToRem(14),
  },
  caption: {
    color: palette.text.secondary,
    fontWeight: fontWeightRegular,
    fontSize: pxToRem(13),
  },
  button: {
    color: palette.text.primary,
    fontWeight: fontWeightRegular,
    fontSize: pxToRem(16),
    textTransform: 'none',
  },
};
