import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const FilterList = ({
  filters,
  activeFilters,
  setActiveFilters,
  className,
}) => {
  const intl = useIntl();

  const hasActiveFilters =
    activeFilters && Object.values(activeFilters).some((value) => value !== '');

  const onChange = (e) =>
    setActiveFilters({ ...activeFilters, [e.target.name]: e.target.value });

  const onClear = () =>
    setActiveFilters(
      filters.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {}),
    );

  const renderValue = (label, options, value, isMultiSelect) => {
    // single select values/str
    const selectedValue = options.find((option) => option.value === value);
    const singleSelectedStr = `${label}: ${
      selectedValue
        ? selectedValue.label
        : intl.formatMessage({ id: 'paths.references.all' })
    }`;
    // multiselect values/str
    const multiSelectedValues = isMultiSelect
      ? options.filter((element) => value.includes(element.value))
      : [];
    const multiSelectedStrItems = multiSelectedValues.reduce(
      (accStr, curValue, index) => {
        return `${
          index + 1 === multiSelectedValues.length
            ? accStr + `${curValue.name}`
            : accStr + `${curValue.name}, `
        } `;
      },
      ``,
    );
    const fullMultiStr = `${label}: ${
      multiSelectedValues.length
        ? multiSelectedStrItems
        : intl.formatMessage({ id: 'paths.references.all' })
    }`;

    return !isMultiSelect ? singleSelectedStr : fullMultiStr;
  };

  return (
    <Box className={className} sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        onClick={onClear}
        disabled={!hasActiveFilters}
        startIcon={<ClearIcon />}
        sx={{ mr: 4 }}
      >
        <FormattedMessage id="action.clear" />
      </Button>
      {filters.map(({ label, name, options, type }) => {
        const hasMultiSelectEnabled = type === 'multiselect';

        return (
          <Select
            variant="standard"
            sx={{ mx: 1 }}
            key={name}
            name={name}
            value={
              hasMultiSelectEnabled
                ? [...activeFilters[name]]
                : activeFilters[name]
            }
            onChange={onChange}
            displayEmpty
            disableUnderline
            multiple={hasMultiSelectEnabled}
            renderValue={(value) =>
              renderValue(label, options, value, hasMultiSelectEnabled)
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      })}
    </Box>
  );
};

FilterList.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }),
  ).isRequired,
  activeFilters: PropTypes.object.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FilterList.displayName = 'FilterList';
export default FilterList;
