import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useField } from 'formik';

const DragDropChipWrapper = ({
  id,
  options: defaultOptions,
  sourceDataValue: { sourceOptions = [], sourceSelected = [] } = {},
  sections,
  children,
}) => {
  const options = defaultOptions || sourceOptions;

  const [
    {
      value: selected = sections.map((section) => ({
        section: section.id,
        items: [],
      })),
    },
    ,
    helpers,
  ] = useField({ name: id });

  const [unselected, setUnselected] = useState([]);

  useEffect(() => {
    // filter out any words that were deleted from source options
    const answers = selected.map(({ section, items }) => ({
      section,
      items: items.filter((item) =>
        options.map((word) => word.value).includes(item),
      ),
    }));

    helpers.setValue(answers);

    // unselected words = seed words - selected words
    setUnselected(
      options
        .map((word) => word.value)
        .filter(
          (word) => !answers.flatMap((section) => section.items).includes(word),
        ),
    );
  }, []);

  const onRemoveWord = (word, section, index) => {
    setUnselected(update(unselected, { $push: [word] }));

    helpers.setValue(
      update(selected, {
        [section]: { items: { $splice: [[index, 1]] } },
      }),
    );
  };

  const onAddWord = (word, section, index) => {
    setUnselected(update(unselected, { $splice: [[index, 1]] }));

    helpers.setValue(
      update(selected, {
        [section]: { items: { $push: [word] } },
      }),
    );
  };

  const onMoveWord = (word, fromSection, toSection, index) => {
    helpers.setValue(
      update(selected, {
        [toSection]: {
          items: {
            $push: [word],
          },
        },
        [fromSection]: { items: { $splice: [[index, 1]] } },
      }),
    );
  };

  const onDragEnd = ({ source, destination, draggableId }) => {
    // if dropped outside of drop zone or back in the source
    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    // if removed from a target section & added to unselected words
    if (destination.droppableId === 'unselected') {
      onRemoveWord(draggableId, parseInt(source.droppableId), source.index);
    }

    // if removed from unselected words & added to a target section
    else if (source.droppableId === 'unselected') {
      onAddWord(draggableId, parseInt(destination.droppableId), source.index);
    }

    // if removed from a target section & added to another target section
    else {
      onMoveWord(
        draggableId,
        parseInt(source.droppableId),
        parseInt(destination.droppableId),
        source.index,
      );
    }
  };

  return children({
    options,
    selected,
    originalSelection: sourceSelected,
    unselected,
    onDragEnd,
  });
};

DragDropChipWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      image: PropTypes.object,
      correctAnswer: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string }),
      ),
    }).isRequired,
  ).isRequired,
};

DragDropChipWrapper.displayName = 'DragDropChipWrapper';
export default DragDropChipWrapper;
