import Input from './Input';
import InputList from './InputList';
import Likert from './Likert';
import MultiSelect from './MultiSelect';
import MultiSelectGrid from './MultiSelectGrid';
import SingleSelect from './SingleSelect';
import SingleSelectGrid from './SingleSelectGrid';

export const questionTypeToComponent = {
  questionInput: Input,
  questionInputList: InputList,
  questionLikert: Likert,
  questionMultiSelect: MultiSelect,
  questionMultiSelectGrid: MultiSelectGrid,
  questionSingleSelect: SingleSelect,
  questionSingleSelectGrid: SingleSelectGrid,
};
