import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g>
    <path
      d="M14.1914 32.935L2.60838 26.0162C1.55053 25.3843 0.899164 24.2462 0.890232 23.014L0.766619 5.96239C0.75946 4.9749 1.44091 4.11567 2.40408 3.89775L13.4398 1.40089C15.4669 0.942226 17.5726 0.957619 19.5929 1.44586L29.6261 3.87061C30.5748 4.09988 31.2407 4.95225 31.2336 5.92824L31.1098 23.014C31.1008 24.2462 30.4495 25.3843 29.3916 26.0162L17.8086 32.935C16.6946 33.6004 15.3054 33.6004 14.1914 32.935Z"
      fill="#2E7D32"
      stroke="#2E7D32"
      strokeWidth="1.5"
    />
    <path
      d="M15.9998 21.5195L20.1498 24.0295C20.9098 24.4895 21.8398 23.8095 21.6398 22.9495L20.5398 18.2295L24.2098 15.0495C24.8798 14.4695 24.5198 13.3695 23.6398 13.2995L18.8098 12.8895L16.9198 8.42952C16.5798 7.61952 15.4198 7.61952 15.0798 8.42952L13.1898 12.8795L8.35983 13.2895C7.47983 13.3595 7.11983 14.4595 7.78983 15.0395L11.4598 18.2195L10.3598 22.9395C10.1598 23.7995 11.0898 24.4795 11.8498 24.0195L15.9998 21.5195Z"
      fill="white"
    />
  </g>,
  'BadgeIcon',
  { viewBox: '0 0 32 35' },
);
