import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import CampaignBackgroundImage from 'assets/shared/campaign-background.png';
import CampaignBackgroundMobileImage from 'assets/shared/campaign-background-mobile.png';
import ContentRenderer from 'components/ContentRenderer';
import Layout from 'layouts/BasicLayout';
import Privacy from './Privacy';
import Video from 'components/Video';
import { useStores } from 'core/hooks/useStores';

const useStyles = makeStyles((theme) => ({
  image: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  content: { minHeight: `100vh` },
  left: ({ isMobile }) => ({
    backgroundImage: isMobile
      ? `url(${CampaignBackgroundMobileImage})`
      : `url(${CampaignBackgroundImage})`,
    backgroundPosition: isMobile ? 'bottom' : 'right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: isMobile ? '20%' : '100%',
    minWidth: isMobile ? '100%' : '20%',
    maxHeight: isMobile ? '20%' : '100%',
  }),
  contentLeft: ({ isMobile }) => ({
    paddingRight: isMobile ? theme.spacing(10) : '0',
    paddingLeft: theme.spacing(10),
    paddingTop: isMobile ? theme.spacing(10) : theme.spacing(20),
    height: '100%',
  }),
  contentRight: ({ isMobile }) => ({
    '& p': {
      textAlign: 'left',
    },
    backgroundColor: 'none',
    margin: '0px 0px',
    maxHeight: '100vh',
    overflowY: isMobile ? 'visible' : 'scroll',
  }),
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  card: {
    width: '100%',
    display: 'flex',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  h2: {
    marginBottom: theme.spacing(2),
  },
  message: ({ isMobile }) => ({
    textAlign: isMobile ? 'center' : 'left',
    justifyContent: isMobile ? 'center' : 'left',
  }),
  empty: {
    paddingTop: theme.spacing(35),
  },
}));

const LandingPage = ({ isLoading, error, campaign }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  const { userStore } = useStores();
  const { user } = userStore;

  const renderError = (error) => {
    const code = error?.response?.data?.code;
    const id =
      code === 'ERROR_INVALID_CAMPAIGN_PROGRAM' ? 'expired' : 'generic';

    return (
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        className={classes.content}
      >
        <Grid item xs={isMobile ? 12 : 6} className={classes.left}>
          <Box className={classes.empty}></Box>
        </Grid>
        <Grid item xs={isMobile ? 12 : 6} className={classes.contentRight}>
          <Box p={8}>
            <Box p={0} pt={4}>
              <Typography variant="h2" className={classes.h2}>
                <FormattedMessage id={`campaign.error.${id}.title`} />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage id={`campaign.error.${id}.description`} />
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderSuccess = () => (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      className={classes.content}
    >
      <Grid item xs={isMobile ? 12 : 6} className={classes.left}>
        <Box className={classes.contentLeft}>
          {campaign ? (
            <Card variant="outlined" className={classes.card}>
              {campaign.landingPage?.video ? (
                <Video
                  video={campaign.landingPage.video.video.file.url}
                  closedCaptions={
                    campaign.landingPage.video.closedCaptions.file.url
                  }
                />
              ) : (
                <img
                  alt=""
                  src={campaign.image.file.url}
                  className={classes.image}
                />
              )}
            </Card>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6} className={classes.contentRight}>
        <Box p={8}>
          <Box p={0} pt={4} className={classes.message}>
            <Typography variant="h2" className={classes.h2}>
              <FormattedMessage id="campaign.success.title" />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage id="campaign.success.description" />
            </Typography>
            <Button
              variant="contained"
              component={Link}
              to="/"
              className={classes.button}
            >
              <FormattedMessage
                id={user ? 'navigation.home' : 'navigation.login'}
              />
            </Button>
          </Box>
          <Divider className={classes.divider} />
          {campaign?.landingPage?.content ? (
            <>
              <Box className={classes.customContent}>
                <ContentRenderer content={campaign?.landingPage?.content} />
              </Box>
              <Divider className={classes.divider} />
            </>
          ) : null}
          <Box p={0}>
            <Privacy />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Layout color="light">
      {isLoading ? (
        <Box mt={5} p={8} textAlign="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        renderError(error)
      ) : (
        renderSuccess()
      )}
    </Layout>
  );
};

LandingPage.displayName = 'LandingPage';

export default LandingPage;
