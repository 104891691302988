import React, { useRef, useState, useCallback, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import NotificationContent from './NotificationContent';

let showNotification;

const Notifications = ({ classes, ...otherProps }) => {
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});

  const queueRef = useRef([]);

  const processQueue = useCallback(() => {
    if (queueRef.current.length) {
      const messageInfo = queueRef.current.shift();
      setMessageInfo(messageInfo);
      setOpen(true);
    }
  }, []);

  const showMessage = useCallback(
    (params) => {
      const messageInfo = {
        ...params,
        key: new Date().getTime(),
      };
      queueRef.current.push(messageInfo);
      if (open) {
        setOpen(false);
      } else {
        processQueue();
      }
    },
    [open, processQueue],
  );

  const onClose = useCallback((event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  }, []);

  const onExited = useCallback(() => {
    processQueue();
  }, [processQueue]);

  useEffect(() => {
    showNotification = showMessage;
  }, [showMessage]);

  const { key, message, type = 'info', duration } = messageInfo;
  return (
    <Snackbar
      key={key}
      open={open}
      autoHideDuration={duration || 2000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={onClose}
      TransitionProps={{ onExited }}
      {...otherProps}
    >
      <NotificationContent onClose={onClose} variant={type} message={message} />
    </Snackbar>
  );
};

export const notification = ({ message, type, duration }) => {
  showNotification && showNotification({ message, type, duration });
};

export const infoNotification = (message) => {
  showNotification &&
    showNotification({ message, type: 'info', duration: 6000 });
};

export const successNotification = (message) => {
  showNotification &&
    showNotification({ message, type: 'success', duration: 6000 });
};

export const warningNotification = (message) => {
  showNotification &&
    showNotification({ message, type: 'warning', duration: 7000 });
};

export const errorNotification = (message) => {
  showNotification &&
    showNotification({ message, type: 'error', duration: 8000 });
};

export default Notifications;
