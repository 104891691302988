import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useStores } from 'core/hooks/useStores';

const Loading = ({ onClose }) => {
  const { userStore } = useStores();
  const { updateHasOnboarded } = userStore;

  useEffect(() => {
    updateHasOnboarded();
    setTimeout(() => {
      onClose();
    }, 2000);
  }, []);

  return (
    <Box textAlign="center" mt={8}>
      <CircularProgress size={160} thickness={2} />
      <Typography variant="h5" sx={{ mt: 5 }}>
        <FormattedMessage id="onboarding.loading" />
      </Typography>
    </Box>
  );
};

Loading.displayName = 'Loading';
export default Loading;
