import ApiService from './ApiService';
import FileDownload from 'js-file-download';
import moment from 'moment';

export const downloadManagerReport = async (token, firstName, lastName) => {
  const data = await ApiService.get(`report/manager/report/${token}`);
  const utcDate = moment.utc().format('YYYY-MM-DD');
  const fileName = `${firstName} ${lastName} Manager Progress Report ${utcDate}.csv`;
  FileDownload(data, fileName);
};

export const downloadOrganizationReport = async (token) => {
  const data = await ApiService.get(`report/organization/report/${token}`);
  const utcDate = moment.utc().format('YYYY-MM-DD');
  const fileName = `Organization Progress Report ${utcDate}.csv`;
  FileDownload(data, fileName);
};
