import React from 'react';
import { useField, useFormikContext } from 'formik';

import TextField from '@mui/material/TextField';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePicker = ({
  disabled,
  helperText,
  TextFieldProps,
  ...datePickerProps
}) => {
  const [field, meta, helpers] = useField(datePickerProps);
  const { isSubmitting } = useFormikContext();

  const showError = meta.touched && !!meta.error;

  return (
    <MuiDatePicker
      disabled={isSubmitting || disabled}
      renderInput={(props) => (
        <TextField
          error={showError}
          helperText={showError ? meta.error : helperText}
          {...props}
          {...TextFieldProps}
        />
      )}
      {...field}
      {...datePickerProps}
      onChange={(value) => helpers.setValue(value)}
    />
  );
};

DatePicker.displayName = 'DatePicker';
export default DatePicker;
