import React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import NeutralIcon from 'components/SvgIcons/NeutralIcon';
import PositiveNegativeIcon from 'components/SvgIcons/PositiveNegativeIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  buttonText: {
    marginLeft: theme.spacing(2),
    textTransform: 'none',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(),
    },
  },
  buttonTextActive: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  negativeIcon: {
    transform: 'rotate(180deg)',
  },
}));

const PositiveNegativeSingleSelect = ({ id, options }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const [field, , helpers] = useField({ name: id });

  const onClick = (value) => () => helpers.setValue(value);

  return (
    <ButtonGroup
      name={id}
      role="radiogroup"
      size={isMobile ? 'small' : 'medium'}
    >
      {options.map(({ label, value }) => {
        const isActive = field.value === value;
        const Icon = value === 'neutral' ? NeutralIcon : PositiveNegativeIcon;

        return (
          <Button
            key={value}
            role="radio"
            aria-checked={isActive}
            aria-label={value}
            onClick={onClick(value)}
          >
            <Icon
              viewBox="0 0 25 10"
              htmlColor={isActive ? '#007881' : '#b8dfe2'}
              className={cn({ [classes.negativeIcon]: value === 'negative' })}
            />
            <Typography
              className={cn(classes.buttonText, {
                [classes.buttonTextActive]: isActive,
              })}
            >
              {label}
            </Typography>
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

PositiveNegativeSingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

PositiveNegativeSingleSelect.displayName = 'PositiveNegativeSingleSelect';
export default PositiveNegativeSingleSelect;
