import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Pause from '@mui/icons-material/Pause';

import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles((theme) => ({
  audio: {
    padding: theme.spacing(0.5),
    alignSelf: 'center',
    marginBottom: theme.spacing(0.5),
  },
}));

const AudioPlayer = ({ url, title }) => {
  const classes = useStyles();

  const [isPlaying, toggleIsPlaying] = useBooleanState(false);
  const audio = useRef();

  const onClick = () => {
    isPlaying ? audio.current.pause() : audio.current.play();
    toggleIsPlaying();
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      className={classes.audio}
      onClick={onClick}
      aria-label={title ? `${title} audio` : 'audio'}
    >
      <audio ref={audio} src={url} />
      {isPlaying ? <Pause /> : <VolumeUpIcon />}
    </Button>
  );
};
AudioPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

AudioPlayer.displayName = 'AudioPlayer';
export default AudioPlayer;
