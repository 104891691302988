import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import cn from 'classnames';

import Box from '@mui/material/Box';
import Bullet from '@mui/icons-material/Stop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ErrorBoundary from 'components/ErrorBoundary';
import {
  useGetPathGlossary,
  useGetExperienceGlossary,
  useSaveEvent,
} from 'core/api/path';

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.typography.h5,
    color: theme.palette.primary.main,
    width: 28,
    height: 28,
  },
  activeButton: {
    color: theme.palette.primary.light,
  },
  letter: {
    color: theme.palette.primary.main,
    marginTop: 4,
    position: 'absolute',
  },
  list: {
    paddingLeft: theme.spacing(8),
  },
  item: {
    alignItems: 'start',
  },
  bullet: {
    minWidth: theme.spacing(4),
    marginTop: 4,
  },
}));

const Glossary = observer(
  ({ type, contentId, className, programId, parentContentId }) => {
    const classes = useStyles();
    const [activeLetter, setActiveLetter] = useState('');

    const { mutate: saveEvent } = useSaveEvent();

    if (programId !== 'preview') {
      useEffect(() => {
        saveEvent({
          programId,
          contentType: 'GLOSSARY',
          contentId,
          parentContentId,
          event: 'opened',
        });
      }, [programId, contentId, saveEvent]);
    }

    const { data: terms, isLoading, isSuccess, error } =
      type === 'path'
        ? useGetPathGlossary(contentId)
        : useGetExperienceGlossary(contentId);

    const isAlphabetized = !Array.isArray(terms);

    const onClick = (letter) => () =>
      letter === activeLetter ? setActiveLetter('') : setActiveLetter(letter);

    const renderGlossaryTerm = ({ term, definition }) => {
      return (
        <ListItem disableGutters className={classes.item}>
          <ListItemIcon className={classes.bullet}>
            <Bullet fontSize="inherit" color="primary" />
          </ListItemIcon>
          <Box>
            <Typography variant="h6">{term}</Typography>
            <Typography>{definition}</Typography>
          </Box>
        </ListItem>
      );
    };

    return (
      <ErrorBoundary error={error}>
        <div className={className}>
          {isLoading && (
            <Box m={10} textAlign="center">
              <CircularProgress size={20} />
            </Box>
          )}
          {isSuccess && (
            <>
              <Box textAlign="center">
                {isAlphabetized &&
                  letters.map((letter) => (
                    <IconButton
                      key={letter}
                      size="small"
                      onClick={onClick(letter)}
                      disabled={!Object.keys(terms).includes(letter)}
                      className={cn(classes.button, {
                        [classes.activeButton]: activeLetter === letter,
                      })}
                    >
                      {letter}
                    </IconButton>
                  ))}
              </Box>
              <Box mt={2}>
                {isAlphabetized
                  ? letters.map(
                      (letter) =>
                        (activeLetter
                          ? activeLetter === letter
                          : Object.keys(terms).includes(letter)) && (
                          <Box position="relative">
                            <Typography variant="h3" className={classes.letter}>
                              {letter}
                            </Typography>
                            <List disablePadding className={classes.list}>
                              {terms[letter].map(({ term, definition }) =>
                                renderGlossaryTerm({ term, definition }),
                              )}
                            </List>
                          </Box>
                        ),
                    )
                  : terms.map(({ term, definition }) =>
                      renderGlossaryTerm({ term, definition }),
                    )}
              </Box>
            </>
          )}
        </div>
      </ErrorBoundary>
    );
  },
);

Glossary.defaultProps = {
  type: 'path',
};

Glossary.propTypes = {
  type: PropTypes.oneOf(['experience', 'path']),
  contentId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Glossary.displayName = 'Glossary';
export default Glossary;
