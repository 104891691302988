import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useInView } from 'react-intersection-observer';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';
import ErrorBoundary from 'components/ErrorBoundary';
import { useGetDecks } from 'core/api/coach';

const Decks = () => {
  const { data, isLoading, error, fetchNextPage, hasNextPage } = useGetDecks();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <Paper
      sx={{ p: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <List
            subheader={
              <ListSubheader disableGutters disableSticky>
                <Typography variant="h6">
                  <FormattedMessage id="discover.decks.title" />
                </Typography>
              </ListSubheader>
            }
          >
            {data.pages.map((page) =>
              page.map((deck, idx) => (
                <CoachListItem
                  ref={idx === page.length - 1 ? ref : null}
                  key={idx}
                  {...deck}
                />
              )),
            )}
          </List>
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Decks.displayName = 'Decks';
export default Decks;
