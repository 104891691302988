import { useMutation, useQuery } from 'react-query';
import { CampaignService } from '../services';

export const useSaveCampaignResponses = () => {
  return useMutation(({ token, campaignAnswer, questionCid }) =>
    CampaignService.saveCampaignResponses(token, campaignAnswer, questionCid),
  );
};

export const useGetCampaign = (id) =>
  useQuery(['campaign', id], () => CampaignService.getCampaign(id), {
    enabled: !!id,
  });
