import React from 'react';
import cn from 'classnames';

import Badge from '@mui/material/Badge';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import MUIAvatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';

export const stringToHslColor = (str = '', saturation, lightness) => {
  const hash =
    [...str].reduce((acc, cur) => cur.charCodeAt() + ((acc << 5) - acc), 0) %
    360;

  return `hsl(${hash}, ${saturation}%, ${lightness}%)`;
};

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
  },
  avatar: ({ useName, name, selected }) => ({
    backgroundColor: useName ? stringToHslColor(name, 70, 37) : null,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightSemiBold,
    border: `1px solid ${theme.palette.divider}`,
    backfaceVisibility: 'hidden',
    willChange: 'transform',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard * 2,
      easing: 'cubic-bezier(1, 1.85, 0.65, 0.54)',
    }),
    transform: selected ? 'rotateY(180deg)' : null,
  }),
  small: {
    fontSize: theme.typography.pxToRem(14),
  },
  large: {
    fontSize: theme.typography.pxToRem(40),
    width: 80,
    height: 80,
  },
  overlay: ({ selected, disabled }) => ({
    top: 0,
    left: 0,
    position: 'absolute',
    opacity: disabled ? '0.95' : '0.8',
    backgroundColor: disabled
      ? theme.palette.grey[400]
      : theme.palette.primary.main,
    transform: selected || disabled ? 'rotateY(0deg)' : 'rotateY(180deg)',
    pointerEvents: disabled ? 'initial' : 'none',
    transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
    willChange: 'transform',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard * 2,
      easing: 'cubic-bezier(1, 1.85, 0.65, 0.54)',
    }),
  }),
  primaryBadge: ({ selected }) => ({
    top: '90%',
    left: -18,
    right: 'initial',
    border: `1px solid ${theme.palette.common.white}`,
    boxShadow: `0 0 2px ${theme.palette.common.white}`,
    opacity: selected ? 0 : 1,
    transition: 'opacity 0.3s ease-out',
  }),
  secondaryBadge: ({ selected }) => ({
    top: '90%',
    right: theme.spacing(1 / 4),
    border: `1px solid ${theme.palette.common.white}`,
    boxShadow: `0 0 2px ${theme.palette.common.white}`,
    opacity: selected ? 0 : 1,
    transition: 'opacity 0.3s ease-out',
  }),
}));

const Avatar = ({
  src,
  name,
  nameTypographyProps,
  className,
  size = 'small',
  onClick,
  disabled,
  selected,
  primaryBadgeIcon,
  secondaryBadgeIcon,
  primaryBadgeProps,
  secondaryBadgeProps,
  children,
  ...props
}) => {
  const useName = !!(name && !src && !children);
  const classes = useStyles({ useName, name, selected, disabled });

  const initials =
    useName &&
    name.split(' ').reduce((acc, cur) => acc + (cur[0] ? cur[0] : ''), '');

  const avatar = (
    <Badge
      color="primary"
      overlap="rectangular"
      classes={{ badge: classes.primaryBadge }}
      badgeContent={primaryBadgeIcon}
      invisible={!primaryBadgeIcon}
      {...primaryBadgeProps}
    >
      <Badge
        overlap="rectangular"
        color="secondary"
        classes={{ badge: classes.secondaryBadge }}
        badgeContent={secondaryBadgeIcon}
        invisible={!secondaryBadgeIcon}
        {...secondaryBadgeProps}
      >
        <MUIAvatar
          className={cn(classes.avatar, classes[size], className)}
          src={src}
          alt={name}
          {...(!onClick && props)}
        >
          {children || (useName && initials[0] + initials.slice(-1))}
        </MUIAvatar>
        <MUIAvatar className={classes.overlay} {...props}>
          <CheckIcon fontSize="small" />
        </MUIAvatar>
      </Badge>
    </Badge>
  );

  return onClick ? (
    <IconButton
      disableRipple
      className={classes.button}
      disabled={disabled}
      onClick={onClick}
      {...props}
      size="large"
    >
      {avatar}
    </IconButton>
  ) : (
    avatar
  );
};

Avatar.displayName = 'Avatar';
export default Avatar;
