import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  superscript: {
    fontWeight: 600,
    verticalAlign: 'super',
  },
}));

const Superscript = ({ value }) => {
  const classes = useStyles();

  return (
    <Typography
      color="primary"
      component="sup"
      display="inline"
      variant="caption"
      className={classes.superscript}
    >
      {value}
    </Typography>
  );
};

Superscript.propTypes = {
  value: PropTypes.string,
};

Superscript.displayName = 'Superscript';

export default Superscript;
