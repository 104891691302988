import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import MuiChip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: () => ({
    margin: theme.spacing(0.5),
  }),
}));

const Chip = ({ label, className, innerRef, ...props }) => {
  const classes = useStyles();

  return (
    <MuiChip
      ref={innerRef}
      label={label}
      className={cn(classes.root, className)}
      {...props}
    />
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

Chip.displayName = 'Chip';
export default Chip;
