import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    strokeLinecap="round"
  >
    <g
      transform="translate(-137.000000, -460.000000)"
      stroke="currentColor"
      strokeWidth="4"
    >
      <g transform="translate(120.000000, 440.000000)">
        <g transform="translate(19.000000, 22.000000)">
          <path d="M0,0 C2.24674355,3.27771046 5.71087236,5.37855075 9.59725728,5.37855075 C13.3896003,5.37855075 16.7798753,3.37815169 19.0292888,0.235859563" />
        </g>
      </g>
    </g>
  </g>,
  'PositiveNegativeIcon',
);
