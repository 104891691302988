import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    transform="translate(5, 2)"
  >
    <g fill="#007881">
      <path d="M11.0055533,11.8561633 C11.0055533,9.34769834 8.54187624,7.31418574 5.50277663,7.31418574 C2.46367701,7.31418574 -4.26325641e-14,9.34769834 -4.26325641e-14,11.8561633 C-4.26325641e-14,14.3646282 11.0055533,14.3646282 11.0055533,11.8561633 Z" />
      <ellipse cx="5.3205422" cy="3.29715815" rx="3.06607516" ry="3.29715815" />
    </g>
  </g>,
  'PersonalDataIcon',
);
