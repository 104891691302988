import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import InterestDialog from './InterestDialog';
import { useGetEmployeeInterests } from 'core/api/feed';
import { useBooleanState } from 'core/hooks/useBooleanState';

const Interests = () => {
  const intl = useIntl();
  const [openDialog, toggleOpenDialog] = useBooleanState(false);

  const {
    data: skills,
    isLoading: isSkillsLoading,
    error: skillsError,
  } = useGetEmployeeInterests('skill');

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    error: categoriesError,
  } = useGetEmployeeInterests('category');

  const isLoading = isCategoriesLoading || isSkillsLoading;

  return (
    <Paper
      sx={{ p: 2, mb: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">
          <FormattedMessage id="interests.title" />
        </Typography>
        <Button onClick={toggleOpenDialog}>
          <FormattedMessage id="action.edit" />
        </Button>
      </Box>
      {!isLoading && (
        <Box>
          <ErrorBoundary error={categoriesError}>
            <Box
              bgcolor="background.default"
              py={3}
              px={2}
              borderRadius={2}
              mb={2}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {`${intl.formatMessage({ id: 'interests.categories' })} (${
                  categories.length
                })`}
              </Typography>
              {categories.map(({ contentCid, contentTitle }) => (
                <Chip
                  key={contentCid}
                  label={contentTitle}
                  sx={{ mr: 1, mt: 1 }}
                />
              ))}
            </Box>
          </ErrorBoundary>
          <ErrorBoundary error={skillsError}>
            <Box bgcolor="background.default" py={3} px={2} borderRadius={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {`${intl.formatMessage({ id: 'interests.skills' })} (${
                  skills.length
                })`}
              </Typography>
              {skills.map(({ contentCid, contentTitle }) => (
                <Chip
                  key={contentCid}
                  label={contentTitle}
                  sx={{ mr: 1, mt: 1 }}
                />
              ))}
            </Box>
          </ErrorBoundary>
        </Box>
      )}
      {openDialog && (
        <InterestDialog
          open={openDialog}
          onClose={toggleOpenDialog}
          interests={{
            categories: categories?.map((category) => category.contentCid),
            skills: skills?.map((skill) => skill.contentCid),
          }}
        />
      )}
    </Paper>
  );
};

Interests.displayName = 'Interests';
export default Interests;
