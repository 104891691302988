import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import Typography from '@mui/material/Typography';

import { useStores } from 'core/hooks/useStores';

/**
 * Render an email that takes a subject and body as params
 */
const Email = observer(({ subject, body }) => {
  const { userStore } = useStores();
  const { user } = userStore;

  return (
    <Card variant="outlined">
      <Box display="flex" alignItems="center" pt={4} pb={2} px={4}>
        <EmailIcon color="primary" fontSize="large" />
        <Box pl={2}>
          <Typography variant="body1" gutterBottom>
            <FormattedMessage id="email.to" /> <b>{user.user.preferredName}</b>
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="email.subject" /> <b>{subject}</b>
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box pt={2} pb={4} px={4} whiteSpace="pre-line">
        <Typography variant="body1">{body}</Typography>
      </Box>
    </Card>
  );
});

Email.propTypes = {
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

Email.displayName = 'Email';
export default Email;
