import { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormWizardContext } from './FormWizard';

const FormWizardStep = ({ children }) => {
  const wizardProps = useContext(FormWizardContext);
  return typeof children === 'function' ? children(wizardProps) : children;
};

FormWizardStep.propTypes = {
  title: PropTypes.string,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

FormWizardStep.displayName = 'FormWizardStep';
export default FormWizardStep;
