import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import makeStyles from '@mui/styles/makeStyles';

import ContentRenderer from 'components/ContentRenderer';
import EmptyState from 'components/EmptyState';
import ErrorBoundary from 'components/ErrorBoundary';
import Experiences from './Experiences';
import Gate from 'components/Gate';
import Glossary from 'containers/paths/components/Glossary';
import Layout from 'layouts/Layout';
import ProgramContainer from 'components/ProgramContainer';
import References from 'containers/paths/components/References';
import { useGetProgram } from 'core/api/path';
import { LEARNING_VIEW } from 'core/utils/permissions';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
}));

const PathDetail = ({
  match: {
    params: { programId },
  },
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { data, isLoading, error } = useGetProgram(programId);

  const breadcrumbs = [
    { label: <FormattedMessage id="navigation.paths" />, path: '/paths' },
    { label: data?.title, path: `/paths/${programId}` },
  ];

  const pathTabs = [
    {
      value: 'experiences',
      label: intl.formatMessage({ id: 'paths.navigation.experiences' }),
      component: Experiences,
      props: { programId: data?.id, ...data },
    },
    {
      value: 'references',
      label: intl.formatMessage({ id: 'paths.navigation.references' }),
      component: References,
      props: { className: classes.container, programId: data?.id, ...data },
    },
    {
      value: 'glossary',
      label: intl.formatMessage({ id: 'paths.navigation.glossary' }),
      component: Glossary,
      props: { className: classes.container, programId: data?.id, ...data },
    },
  ];

  const customTabs = [
    {
      value: 'custom',
      component: ContentRenderer,
      props: { content: data?.content?.content },
    },
  ];

  return (
    <Gate permissions={[LEARNING_VIEW]} redirectOnDenied>
      <Layout showNavigation color="light">
        {!isLoading && (
          <ErrorBoundary error={error}>
            <ProgramContainer
              breadcrumbs={breadcrumbs}
              tabs={data.contentType === 'path' ? pathTabs : customTabs}
              {...data}
            />
          </ErrorBoundary>
        )}
        {isLoading && <EmptyState isLoading />}
      </Layout>
    </Gate>
  );
};

PathDetail.displayName = 'PathDetail';
export default PathDetail;
