import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import Logo from 'components/Logo';

const Layout = ({ children, color }) => {
  return (
    <Box
      component="main"
      height="100%"
      bgcolor={color === 'dark' ? 'background.default' : 'background.paper'}
    >
      <Box width="100%" position="absolute" textAlign="center" mt={3}>
        <Link to="/home">
          <Logo height={25} fullWidth />
        </Link>
      </Box>
      {children}
    </Box>
  );
};

Layout.defaultProps = {
  color: 'dark',
};

Layout.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['dark', 'light']),
};

Layout.displayName = 'Layout';
export default Layout;
