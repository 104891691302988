import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Equal from 'components/SvgIcons/Equal';
import Unequal from 'components/SvgIcons/Unequal';
import Check from '@mui/icons-material/Check';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0),
    border: `2px solid ${theme.palette.divider}`,
  },
  th: {
    fontWeight: theme.typography.fontWeightBold,
  },
  td: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(),
    },
  },
  columns: {
    '&:not(:last-child)': {
      borderRight: `2px solid ${theme.palette.divider}`,
    },
  },
  icons: {
    overflow: 'visible',
  },
}));

const DynamicTable = ({ columns, type, sourceData, getSourceDataValue }) => {
  const classes = useStyles();

  const sourceDataValue = getSourceDataValue(sourceData, type) || {};
  const { contentType, rows = [columns.map((column) => '')] } = sourceDataValue;

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      className={classes.container}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ label, value }) => (
              <TableCell
                aria-label={label || value}
                key={value}
                className={cn(classes.td, classes.th, {
                  [classes.columns]: contentType !== 'equal',
                })}
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx}>
              {row.map((content, idx) => (
                <TableCell
                  key={idx}
                  className={cn(classes.td, {
                    [classes.columns]: contentType !== 'equal',
                  })}
                >
                  {typeof content === 'boolean' ? (
                    contentType === 'check' ? (
                      <Check titleAccess="check" />
                    ) : content ? ( // type = equal
                      <Equal titleAccess="equal" className={classes.icons} />
                    ) : (
                      <Unequal
                        titleAccess="unequal"
                        className={classes.icons}
                      />
                    )
                  ) : (
                    content
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DynamicTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['contentDynamicTable']).isRequired,
  sourceData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  getSourceDataValue: PropTypes.func.isRequired,
};

DynamicTable.displayName = 'DynamicTable';
export default DynamicTable;
