import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import makeStyles from '@mui/styles/makeStyles';
import ContentRenderer from 'components/ContentRenderer';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `2px solid ${theme.palette.divider}`,
    '& p': {
      marginBottom: 0,
    },
    '& th, td': {
      '&:not(:last-child)': {
        borderRight: `2px solid ${theme.palette.divider}`,
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(),
      },
      '& p:empty': {
        display: 'none',
      },
    },
  },
}));

const Table = ({ content }) => {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      className={classes.container}
    >
      <ContentRenderer content={content} />
    </TableContainer>
  );
};

Table.propTypes = {
  content: PropTypes.string,
};

Table.displayName = 'Table';
export default Table;
