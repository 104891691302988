import React from 'react';

import Icon from '@mui/icons-material/Security';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import PrivacyDialog from 'components/PrivacyDialog';
import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'bottom',
    marginRight: 4,
    cursor: 'pointer',
  },
}));

const PrivacyPolicy = ({ label }) => {
  const classes = useStyles();
  const [dialogIsOpen, toggleDialogOpen] = useBooleanState(false);

  return (
    <>
      <Icon
        className={classes.icon}
        color="primary"
        onClick={toggleDialogOpen}
      />
      <Typography variant="h6" color="primary" display="inline">
        {label}
      </Typography>
      {dialogIsOpen && (
        <PrivacyDialog open onClose={toggleDialogOpen} maxWidth="md" />
      )}
    </>
  );
};

PrivacyPolicy.displayName = 'PrivacyPolicy';
export default PrivacyPolicy;
