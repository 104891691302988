import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g>
    <path
      d="M30.211 2.34375L27.8673 0H8.13273L5.78898 2.34375H0.421875V16.9999C0.421875 27.5468 7.52352 36.9999 17.6719 39.9062L18 40L18.3281 39.9062C28.4765 36.9999 35.5781 27.5468 35.5781 16.9999V2.34375H30.211Z"
      fill="#F3F5F9"
    />
    <path
      d="M35.5781 2.34375V16.9999C35.5781 27.5468 28.4765 36.9999 18.3281 39.9062L18 40V0H27.8673L30.211 2.34375H35.5781Z"
      fill="#E1E6F0"
    />
    <path
      d="M33.2344 4.6875V16.9999C33.2344 26.3984 26.9766 34.8438 18 37.5624C9.02336 34.8438 2.76562 26.3984 2.76562 16.9999V4.6875H6.77352L9.11727 2.34375H26.8828L29.2266 4.6875H33.2344Z"
      fill="#00A2AE"
    />
    <path
      d="M33.2344 4.6875V16.9999C33.2344 26.3984 26.9766 34.8438 18 37.5624V2.34375H26.8827L29.2265 4.6875H33.2344Z"
      fill="#00A2AE"
    />
    <path
      d="M23.8594 10.5469V15.3125H21.5156V10.5469C21.5156 8.60148 19.9454 7.03125 18 7.03125C16.0546 7.03125 14.4844 8.60148 14.4844 10.5469V15.3125H12.1406V10.5469C12.1406 7.3125 14.7656 4.6875 18 4.6875C21.2344 4.6875 23.8594 7.3125 23.8594 10.5469Z"
      fill="#F3F5F9"
    />
    <path
      d="M23.8594 10.5469V15.3125H21.5156V10.5469C21.5156 8.60148 19.9454 7.03125 18 7.03125V4.6875C21.2344 4.6875 23.8594 7.3125 23.8594 10.5469Z"
      fill="#E1E6F0"
    />
    <path
      d="M9.79688 14.1406V22.3438C9.79688 26.8672 13.4766 30.5469 18 30.5469C22.5234 30.5469 26.2031 26.8672 26.2031 22.3438V14.1406H9.79688Z"
      fill="#FFDF40"
    />
    <path
      d="M26.2031 14.1406V22.3438C26.2031 26.8672 22.5234 30.5469 18 30.5469V14.1406H26.2031Z"
      fill="#FFBE40"
    />
    <path
      d="M16.8281 18.8281H19.1719V25.8594H16.8281V18.8281Z"
      fill="#00A2AE"
    />
    <path d="M18 18.8281H19.1719V25.8594H18V18.8281Z" fill="#00A2AE" />
  </g>,
  'ShieldIcon',
  {
    viewBox: '0 0 36 40',
  },
);
