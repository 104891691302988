import React from 'react';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';

import Avatar from '@mui/material/Avatar';
import BackArrow from '@mui/icons-material/ArrowBackRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import StreakIcon from 'components/SvgIcons/Streak';
import { COACH_VIEW } from 'core/utils/permissions';

const BadgeDetail = ({ open, onClose, badges, streak }) => {
  return (
    <Dialog open={open} fullScreen>
      <Layout showNavigation align="center">
        <IconButton
          onClick={onClose}
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            mb: { xs: 1, md: 0 },
          }}
        >
          <BackArrow />
        </IconButton>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{
            width: { xs: '100%', md: 600 },
            margin: 'auto',
            borderRadius: 2,
            minHeight: '100%',
          }}
        >
          <Box
            p={3}
            bgcolor="primary.main"
            color="common.white"
            borderRadius="8px 8px 0px 0px"
          >
            <Typography variant="h2" color="inherit">
              <FormattedMessage id="profile.achievements.title" />
            </Typography>
          </Box>
          <Gate permissions={[COACH_VIEW]}>
            <Box borderRadius={2} border="1px solid #EBA043" m={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={1.5}
              >
                <StreakIcon />
                <Typography variant="h6" sx={{ ml: 1 }}>
                  <FormattedMessage
                    id="profile.achievements.streak.title"
                    values={{ count: streak }}
                  />
                </Typography>
              </Box>
              <Box
                bgcolor="#FDF5EC"
                textAlign="center"
                p={1.5}
                borderRadius="0 0 8px 8px"
              >
                <Typography>
                  <FormattedMessage
                    id={`profile.achievements.streak.description.${
                      streak === 0 ? 'start' : 'continue'
                    }`}
                  />
                </Typography>
              </Box>
            </Box>
          </Gate>
          <List disablePadding>
            {badges.map(
              ({
                title,
                description,
                receivedAt,
                image: {
                  file: { url },
                },
              }) => (
                <ListItem
                  divider
                  key={title}
                  sx={{
                    mixBlendMode: !!receivedAt ? 'normal' : 'luminosity',
                    opacity: !!receivedAt ? 1 : 0.5,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={url} />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={<Typography variant="h6">{title}</Typography>}
                    secondary={
                      <Box>
                        <Typography variant="body2">{description}</Typography>
                        {!!receivedAt && (
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 'bold' }}
                          >
                            <FormattedMessage
                              id="date.earned"
                              values={{
                                receivedAt: moment.utc(receivedAt).format('ll'),
                              }}
                            />
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              ),
            )}
          </List>
        </Paper>
      </Layout>
    </Dialog>
  );
};

BadgeDetail.displayName = 'BadgeDetail';
export default BadgeDetail;
