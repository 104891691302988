import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import List from '@mui/material/List';

import ExperienceRow from './ExperienceRow';

const Experiences = ({ content: { experiences }, ...program }) => {
  let idx = 0;
  return Object.values(
    experiences.reduce((list, experience) => {
      if (experience.isFollowOn) {
        list[idx - 1].push(experience);
      } else {
        list[idx] = [experience];
        idx++;
      }
      return list;
    }, {}),
  ).map((experiences, idx) => (
    <Box key={idx} mt={3}>
      <List disablePadding>
        {experiences.map((experience) => (
          <ExperienceRow
            key={experience.id}
            program={program}
            experience={experience}
          />
        ))}
      </List>
    </Box>
  ));
};

Experiences.propTypes = {
  content: PropTypes.shape({
    experiences: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        duration: PropTypes.number,
        isFollowOn: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }),
};

Experiences.displayName = 'Experiences';
export default Experiences;
