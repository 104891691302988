import React from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useBooleanState } from 'core/hooks/useBooleanState';

const PopoverItem = ({
  label,
  icon: Icon,
  popover: Popover,
  ...popoverProps
}) => {
  const [open, toggleOpen] = useBooleanState(false);

  return (
    <>
      <ListItemButton onClick={toggleOpen}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
      <Popover open={open} onClose={toggleOpen} {...popoverProps} />
    </>
  );
};

export default PopoverItem;
PopoverItem.displayName = 'PopoverItem';
