import { configure } from 'mobx';

import AuthStore from './AuthStore';
import UserStore from './UserStore';

configure({ enforceActions: 'always' });

const userStore = new UserStore();
const authStore = new AuthStore(userStore);

export default {
  authStore,
  userStore,
};
