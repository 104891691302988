import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Chip from 'components/ContentRenderer/Embeddable/embeddables/components/Chip';
import DraggableChip from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/DragDrop/components/DraggableChip';
import DropContainer from 'components/ContentRenderer/Embeddable/embeddables/components/DropContainer';
import StrikedChip from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/DragDrop/components/StrikedChip';

const CONTAINER_COLORS = {
  green: '#89B744',
  orange: '#DF6B2A',
  'light teal': '#00A2AE',
  'dark teal': '#048C96',
  purple: '#926CE0',
  yellow: '#F4DB6B',
};

const useStyles = makeStyles((theme) => ({
  outerContainer: ({ color }) => ({
    backgroundColor: color
      ? CONTAINER_COLORS[color]
      : theme.palette.primary.main,
    height: '100%',
    padding: 6,
  }),
  innerContainer: {
    border: '6px transparent dashed',
    height: '100%',
    padding: 6,
    position: 'relative',
    overflowY: 'scroll',
  },
  isDraggingOver: {
    border: '6px rgba(0, 0, 0, 0.2) dashed',
  },
  img: {
    width: 115,
    height: 115,
    borderRadius: 115,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 4,
    display: 'block',
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(),
    borderRadius: '4px',
    textTransform: 'uppercase',
    marginBottom: 6,
    position: 'relative',
    width: 'max-content',
  },
  originalChip: {
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}));

const DraggableChipTarget = ({
  droppableId,
  options,
  selected,
  originalSelection,
  isDisabled,
  image,
  color,
  label,
  correctAnswer,
}) => {
  const classes = useStyles({ color });

  const originalChips = originalSelection['items'].map((value, index) => (
    <Chip
      className={classes.originalChip}
      label={value}
      key={`original-${index}`}
    />
  ));

  const selectedChips = selected['items'].map((value, index) => {
    const option = options.find((o) => o.value === value);
    return <DraggableChip key={index} index={index} {...option} />;
  });

  const actualChips =
    correctAnswer &&
    Array.from(new Set(selected['items'].concat(correctAnswer))).map(
      (value, index) => {
        const option = options.find((o) => o.value === value);
        const Component = correctAnswer.includes(value) ? Chip : StrikedChip;
        return <Component key={index} {...option} />;
      },
    );

  return (
    <div className={classes.outerContainer}>
      <DropContainer
        droppableId={droppableId}
        isDropDisabled={isDisabled}
        classes={{
          root: classes.innerContainer,
          isDraggingOver: classes.isDraggingOver,
        }}
      >
        {label && <Typography className={classes.label}>{label}</Typography>}
        {image && (
          <img src={image.file.url} alt="Person" className={classes.img} />
        )}
        {isDisabled ? actualChips : [...originalChips, ...selectedChips]}
      </DropContainer>
    </div>
  );
};

DraggableChipTarget.defaultProps = {
  originalSelection: {
    items: [],
  },
};

DraggableChipTarget.propTypes = {
  droppableId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.string) }),
  originalSelection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.string),
  }),
  isDisabled: PropTypes.bool.isRequired,
  image: PropTypes.object,
  correctAnswer: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
  label: PropTypes.string,
};

DraggableChipTarget.displayName = 'DraggableChipTarget';
export default DraggableChipTarget;
