import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import EmptyState from 'components/EmptyState';
import FormField from 'components/FormField';
import { useSendRegistrationInvite } from 'core/api/auth';

const InviteForm = () => {
  const intl = useIntl();

  const { mutateAsync: sendRegistrationInvite } = useSendRegistrationInvite();

  const [success, setSuccess] = useState(false);

  const onSubmit = async ({ email }, { setSubmitting, setFieldError }) => {
    return sendRegistrationInvite(email, {
      onSuccess: ({ ssoUrl }) => {
        if (ssoUrl) {
          window.location = ssoUrl;
        } else {
          setSuccess(true);
        }
      },
      onError: ({ response }) => {
        const { code } = response.data;
        if (code === 'ERROR_USER_EXISTS') {
          setFieldError(
            'email',
            "It looks like you've already registered. Please login instead.",
          );
        } else {
          setFieldError(
            'email',
            'Unknown email. Please check with your administrator.',
          );
        }
      },
      onSettled: () => setSubmitting(false),
    });
  };

  return success ? (
    <EmptyState
      title="Check your email"
      description="Weʼve sent you a special link to complete your registration and activate your account."
    />
  ) : (
    <Formik
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{ email: '' }}
      validationSchema={Yup.object({
        email: Yup.string()
          .trim()
          .required(intl.formatMessage({ id: 'error.input.empty' }))
          .email(intl.formatMessage({ id: 'error.email.invalid' })),
      })}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Typography variant="h1">Let's create your account</Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 1, mb: 3 }}
          >
            Welcome to Eskalera! Please enter your work email.
          </Typography>
          <FormField
            name="email"
            label="Email"
            fullWidth
            autoFocus
            inputProps={{
              'aria-label': 'Email',
            }}
          />
          <Button
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            sx={{ my: 2 }}
            startIcon={isSubmitting && <CircularProgress size={15} />}
            disabled={isSubmitting}
          >
            <FormattedMessage id="action.continue" />
          </Button>
          <Box>
            <Typography variant="body1" display="inline" color="textSecondary">
              Already have an account?{' '}
            </Typography>
            <Typography
              component={Link}
              to="/login"
              display="inline"
              color="primary"
              style={{ textDecoration: 'none' }}
            >
              Login
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

InviteForm.displayName = 'InviteForm';
export default InviteForm;
