import ApiService from './ApiService';

export const saveCampaignResponses = async (token, answer, questionCid) =>
  ApiService.post(`campaigns/answer`, {
    token,
    answer,
    questionCid,
  });

export const getCampaign = async (id) => ApiService.get(`campaigns/${id}`);
