import PropTypes from 'prop-types';
import React from 'react';
import { useField, useFormikContext } from 'formik';

import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import { FormControl, FormGroup } from 'components/FormField';
import MultiSelectGridMobile from './MultiSelectGridMobile';

const useStyles = makeStyles(() => ({
  th: {
    borderBottom: 'hidden',
  },
}));

const MultiSelectGrid = ({ id, rows, columns, sourceDataValue = [] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const classes = useStyles();

  const [field, { value: response }] = useField({ name: id });
  const { isSubmitting } = useFormikContext();

  const rowList = rows || sourceDataValue;

  return isMobile ? (
    <MultiSelectGridMobile id={id} columns={columns} rowList={rowList} />
  ) : (
    <FormGroup {...field}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map(({ label, value }) => (
              <TableCell key={value}>
                <Typography align="center" sx={{ fontWeight: 'bold' }}>
                  {label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowList.map(({ label, value: rowValue }) => (
            <TableRow key={rowValue}>
              <TableCell component="th" scope="row">
                <Typography>{label}</Typography>
              </TableCell>
              {columns.map(({ value: columnValue }) => {
                const isChecked = Boolean(
                  response &&
                    response[rowValue] &&
                    response[rowValue][columnValue],
                );

                return (
                  <TableCell
                    key={columnValue}
                    component="th"
                    scope="row"
                    align="center"
                  >
                    <FormControl name={`${id}.${rowValue}`}>
                      <Checkbox
                        name={`${id}.${rowValue}.${columnValue}`}
                        value={isChecked}
                        checked={isChecked}
                        disabled={isSubmitting}
                      />
                    </FormControl>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FormGroup>
  );
};

MultiSelectGrid.propTypes = {
  id: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sourceDataValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

MultiSelectGrid.displayName = 'MultiSelectGrid';
export default MultiSelectGrid;
