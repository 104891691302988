import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Layout from 'layouts/Layout';
import Personal from './Personal';
import Notifications from './Notifications';
import Password from './Password';
import Language from './Language';
import Service from './Service';

const DesktopContainer = ({ children }) => (
  <Paper
    elevation={0}
    variant="outlined"
    sx={{
      display: 'flex',
      width: { xs: '100%', md: 700, lg: 800 },
      minHeight: '100%',
      borderRadius: 4,
      p: { xs: 4, md: 5 },
      margin: 'auto',
    }}
  >
    {children}
  </Paper>
);

const DesktopTabs = ({ tabs, onTabChange, activeTab }) => (
  <List disablePadding>
    {tabs.map((label, idx) => (
      <ListItemButton dense disableGutters onClick={() => onTabChange(idx)}>
        <ListItemText
          primary={<FormattedMessage id={`profile.settings.${label}`} />}
          primaryTypographyProps={{
            variant: 'subtitle1',
            sx: {
              color: activeTab === idx ? 'primary.main' : 'text.secondary',
              fontWeight: activeTab === idx ? 700 : 400,
              whiteSpace: 'nowrap',
            },
          }}
        />
      </ListItemButton>
    ))}
  </List>
);

const MobileTabs = ({ tabs, onTabChange, activeTab }) => (
  <Select
    value={activeTab}
    fullWidth
    onChange={(e) => onTabChange(e.target.value)}
    sx={{ mb: 3 }}
  >
    {tabs.map((label, idx) => (
      <MenuItem value={idx}>
        <FormattedMessage id={`profile.settings.${label}`} />
      </MenuItem>
    ))}
  </Select>
);

const Settings = ({ open, onClose, initialTab }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const [activeTab, setActiveTab] = useState(initialTab);
  const onTabChange = (idx) => setActiveTab(idx);

  const tabs = ['personal', 'notifications', 'password', 'language', 'service'];

  const Container = isMobile ? Box : DesktopContainer;
  const Navigation = isMobile ? MobileTabs : DesktopTabs;

  return (
    <Dialog open={open} fullScreen>
      <Layout color={isMobile ? 'light' : 'dark'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            mb: { xs: 1, md: 0 },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Container>
          <Navigation
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={onTabChange}
          />
          {activeTab === 0 && <Personal />}
          {activeTab === 1 && <Notifications />}
          {activeTab === 2 && <Password />}
          {activeTab === 3 && <Language />}
          {activeTab === 4 && <Service />}
        </Container>
      </Layout>
    </Dialog>
  );
};

Settings.propTypes = {
  ...Dialog.propTypes,
};

Settings.displayName = 'Settings';
export default Settings;
