import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import update from 'immutability-helper';
import { useField } from 'formik';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

import ContentRenderer from 'components/ContentRenderer/index';
import ShowActualSwitch from 'components/ContentRenderer/Embeddable/embeddables/components/ShowActualSwitch';
import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles((theme) => ({
  th: {
    ...theme.typography.h6,
    whiteSpace: 'nowrap',
    '& > *': {
      marginBottom: 0,
    },
  },
  td: {
    padding: theme.spacing(),
    minHeight: 50,
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '& p': {
      marginBottom: 0,
    },
    '& p:empty': {
      display: 'none',
    },
    '& img': {
      width: 200,
      height: 120,
    },
    [theme.breakpoints.down('md')]: { whiteSpace: 'pre-wrap' },
  },
  correct: {
    backgroundColor: 'rgba(137,183,68,0.2)',
  },
  incorrect: {
    backgroundColor: 'rgba(194,74,0, 0.2)',
  },
  blank: {
    textAlign: 'center',
  },
}));

export const MenuContext = React.createContext(null);

const SingleSelectTableDropdown = ({ id, headers, rows, showActual }) => {
  const classes = useStyles();
  const [showAnswers, toggleShowAnswers] = useBooleanState(false);

  const expandedColumns = headers.reduce((acc, header) => {
    if (header.type === 'activity' && showAnswers)
      acc.push(
        {
          ...header,
          title: <FormattedMessage id="singleSelectTableDropdown.answers" />,
          type: 'answer',
        },
        {
          ...header,
          title: <FormattedMessage id="singleSelectTableDropdown.actual" />,
          type: 'actual',
        },
      );
    else acc.push(header);
    return acc;
  }, []);

  const expandedRow = (row) =>
    row.cells.reduce((acc, cell, idx) => {
      if (showAnswers && headers[idx].type === 'activity')
        acc.push(
          {
            ...cell,
            type: 'answer',
            answerValue: answers[row.value][headers[idx].value] || '',
          },
          {
            ...cell,
            type: 'actual',
            actualValue: row.actual.label,
          },
        );
      else acc.push(cell);
      return acc;
    }, []);

  const initialValue = rows.reduce((answer, row) => {
    answer[row.value] = headers.reduce((acc, cell) => {
      if (cell.type === 'activity') {
        acc[cell.value] = '';
      }
      return acc;
    }, {});
    return answer;
  }, {});

  const [{ value: answers = initialValue }, , helpers] = useField({ name: id });

  const onChange = (row, column) => (option) => {
    helpers.setValue(
      update(answers, { [row]: { [column]: { $set: option } } }),
    );
  };

  return (
    <Box textAlign="right">
      {showActual && (
        <ShowActualSwitch checked={showAnswers} onChange={toggleShowAnswers} />
      )}
      <TableContainer component={Paper} variant="outlined">
        <Table
          id={id}
          aria-ColCount={
            showAnswers ? expandedColumns.length : expandedColumns.length
          }
          aria-disabled={showAnswers}
        >
          <TableHead>
            <TableRow>
              {expandedColumns.map((cell, idx) => {
                return (
                  <TableCell
                    aria-ColIndex={idx + 1}
                    key={idx}
                    className={cn(classes.td, classes.th)}
                  >
                    {cell.title || <ContentRenderer content={cell.content} />}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow key={idx}>
                {expandedRow(row).map((cell, idx) => {
                  return (
                    <TableCell
                      aria-colIndex={idx + 1}
                      key={idx}
                      className={cn(classes.td, {
                        [classes.correct]:
                          expandedColumns[idx].type === 'answer' &&
                          cell.answerValue &&
                          cell.answerValue === row.actual.value,
                        [classes.incorrect]:
                          expandedColumns[idx].type === 'answer' &&
                          cell.answerValue &&
                          cell.answerValue !== row.actual.value,
                        [classes.blank]:
                          expandedColumns[idx].type === 'answer' &&
                          !cell.answerValue,
                      })}
                    >
                      {cell.type === 'actual' ? (
                        cell.actualValue
                      ) : (
                        <MenuContext.Provider
                          value={{
                            answers,
                            value:
                              answers[row.value][expandedColumns[idx].value] ||
                              '',
                            columnValue: expandedColumns[idx].value,
                            rowValue: row.value,
                            type: cell.type,
                            showActual: showActual,
                            onChange: onChange(
                              row.value,
                              expandedColumns[idx].value,
                            ),
                          }}
                        >
                          <ContentRenderer content={cell.content} />
                        </MenuContext.Provider>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

SingleSelectTableDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      actual: PropTypes.shape({
        value: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        actual: PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
        cells: PropTypes.shape({
          type: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          content: PropTypes.object.isRequired,
        }),
      }),
    }),
  ),
  showActual: PropTypes.bool,
};

SingleSelectTableDropdown.displayName = 'SingleSelectTableDropdown';
export default SingleSelectTableDropdown;
