import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';
import ErrorBoundary from 'components/ErrorBoundary';
import Icon from 'components/SvgIcons/Trophy';
import { useSaveEvent, useGetNextDeck } from 'core/api/coach';

const LastCard = ({
  deckId,
  confirmationTitle,
  confirmationDescription,
  skills,
  takeawayTitle,
  takeaways,
  nextUpTitle,
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: saveEvent } = useSaveEvent();
  const { data, isLoading, error } = useGetNextDeck(deckId);

  useEffect(() => {
    // save deck completed event on last card load
    saveEvent({
      contentType: 'DECK',
      contentId: deckId,
      event: 'completed',
      isSurvey: false,
    });
  }, [deckId, saveEvent]);

  useEffect(() => {
    return () => {
      queryClient.resetQueries(['next']);
    };
  }, []);

  return (
    <>
      <Box textAlign="center" px={{ xs: 2, sm: 3 }}>
        <Icon sx={{ height: 100, width: 100 }} />
        <Typography variant="h1" sx={{ pb: 1 }}>
          {confirmationTitle}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {confirmationDescription}
        </Typography>
        {skills.map((skill) => (
          <Chip
            key={skill.title}
            label={skill.title}
            sx={{ mr: 1, mt: 1, bgcolor: 'background.default' }}
          />
        ))}
      </Box>
      <Box
        bgcolor="background.default"
        p={3}
        my={2}
        mx={{ xs: 0, sm: 3 }}
        borderRadius={{ xs: 0, sm: 2 }}
      >
        <Typography variant="h5">{takeawayTitle}</Typography>
        {documentToReactComponents(takeaways)}
        <Typography variant="h5">{nextUpTitle}</Typography>
        {!isLoading && (
          <ErrorBoundary error={error}>
            <CoachListItem {...data.coachContent[0]} />
          </ErrorBoundary>
        )}
        <Box textAlign="center" mt={2}>
          <Typography display="inline">
            <FormattedMessage id="boolean.or" />
          </Typography>
          <Typography
            component={Link}
            to="/discover"
            display="inline"
            color="primary"
            sx={{ textDecoration: 'none' }}
          >
            <FormattedMessage id="action.discover" />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

LastCard.displayName = 'LastCard';
export default LastCard;
