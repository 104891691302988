import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ProgramListItem from 'components/ProgramListItem';

const Circles = ({ programs }) => {
  // first circle
  const circle = programs.find((program) => program.contentType === 'circle');

  if (!circle) return null;

  return (
    <Paper
      sx={{ mb: 2, p: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      <List
        disablePadding
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">
              <FormattedMessage id="home.circles.title" />
            </Typography>
            <Button component={Link} to="/circles">
              <FormattedMessage id="action.viewAll" />
            </Button>
          </ListSubheader>
        }
      >
        <ProgramListItem programLink={`/circles/${circle.uid}`} {...circle} />
      </List>
    </Paper>
  );
};

Circles.displayName = 'Circles';
export default Circles;
