import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
  },
  progress: {
    position: 'absolute',
    opacity: 0.3,
  },
  svg: {
    overflow: 'visible',
  },
}));

const ProgressIndicator = ({ total, fraction }) => {
  const classes = useStyles();

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        variant="determinate"
        value={100}
        size={50}
        thickness={3}
        classes={{
          root: classes.progress,
          svg: classes.svg,
        }}
      />
      <CircularProgress
        size={50}
        variant="determinate"
        value={Math.floor((fraction / total) * 100)}
        thickness={3}
        classes={{ svg: classes.svg }}
      />
      <Avatar className={classes.avatar}>
        <Typography
          variant="body2"
          color="textPrimary"
        >{`${fraction}/${total}`}</Typography>
      </Avatar>
    </Box>
  );
};

ProgressIndicator.propTypes = {
  total: PropTypes.number.isRequired,
  fraction: PropTypes.number.isRequired,
};

ProgressIndicator.displayName = 'ProgressIndicator';
export default ProgressIndicator;
