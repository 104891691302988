import React from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';

import PrivacyDialog from 'components/PrivacyDialog';
import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  privacy: {
    textAlign: 'left',
  },
}));

const Privacy = () => {
  const classes = useStyles();

  const [openDialog, toggleOpenDialog] = useBooleanState();

  return (
    <>
      <Typography variant="body1" className={classes.privacy}>
        <FormattedMessage
          id="campaign.landing.privacyMessage"
          values={{
            button: (
              <Link onClick={toggleOpenDialog} className={classes.link}>
                <FormattedMessage id="campaign.landing.privacy" />
              </Link>
            ),
          }}
        />
      </Typography>
      {openDialog && (
        <PrivacyDialog open onClose={toggleOpenDialog} maxWidth="md" />
      )}
    </>
  );
};

Privacy.displayName = 'Privacy';

export default Privacy;
