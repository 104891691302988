import React, { useEffect, useState } from 'react';

import LandingPage from 'components/LandingPage';
import { useSaveCampaignResponses, useGetCampaign } from 'core/api/campaign';

const Campaign = () => {
  const {
    mutateAsync: saveCampaignResponses,
    isLoading: isLoadingAnswer,
    error,
  } = useSaveCampaignResponses();

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  const campaignAnswer = query.get('value');
  const [campaign, setCampaign] = useState(null);
  const [campaignId, setCampaignId] = useState(null);

  useEffect(() => {
    saveCampaignResponses(
      {
        token: token,
        campaignAnswer: campaignAnswer,
      },
      {
        onSuccess: ({ contentCid }) => {
          setCampaignId(contentCid);
        },
      },
    );
  }, [saveCampaignResponses, token, campaignAnswer]);

  const { data: campaignData, isLoading: isLoadingCampaign } = useGetCampaign(
    campaignId,
  );

  useEffect(() => {
    if (campaignData) {
      setCampaign(campaignData);
    }
  }, [campaignData]);

  return (
    <LandingPage
      isLoading={isLoadingAnswer || isLoadingCampaign}
      error={error}
      campaign={campaign}
    />
  );
};

Campaign.displayName = 'Campaign';
export default Campaign;
