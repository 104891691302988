import React from 'react';
import { FormattedMessage } from 'react-intl';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Avatar from 'components/Avatar';
import EmptyState from 'components/EmptyState';
import ErrorBoundary from 'components/ErrorBoundary';
import { useGetProgramParticipants } from 'core/api/circle';

const Participants = ({ id: programId, showParticipants }) => {
  const { data, isLoading, isSuccess, error } = useGetProgramParticipants(
    programId,
    {
      enabled: showParticipants,
    },
  );

  if (!showParticipants) {
    return (
      <EmptyState
        image={<VisibilityOffIcon fontSize="large" />}
        title={<FormattedMessage id="circles.participants.hidden" />}
        justify="start"
      />
    );
  }

  return (
    <ErrorBoundary error={error}>
      {isLoading && <EmptyState isLoading size="small" />}
      {isSuccess && data.length === 0 && <EmptyState title="Nothing to show" />}
      {isSuccess && data.length > 0 && (
        <List>
          {data.map(
            ({ uid, firstName, lastName, jobTitle, workEmail, avatarUrl }) => {
              const fullName = `${firstName} ${lastName}`;
              return (
                <ListItem key={`employee-${uid}`} divider>
                  <ListItemAvatar>
                    <Avatar
                      src={avatarUrl}
                      name={fullName}
                      size="small"
                      secondaryBadgeProps={{ color: 'default' }}
                      nameTypographyProps={{ variant: 'h1' }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={fullName}
                    secondary={jobTitle || workEmail}
                  />
                </ListItem>
              );
            },
          )}
        </List>
      )}
    </ErrorBoundary>
  );
};

Participants.propTypes = {};

export default Participants;
