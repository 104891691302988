import PropTypes from 'prop-types';
import React from 'react';

import CheckboxMultiSelect from './CheckboxMultiSelect';
import DropdownMultiSelect from './DropdownMultiSelect';

const MultiSelect = ({ appearance, ...props }) => {
  return (
    <>
      {(appearance === 'Checkbox' || !appearance) && (
        <CheckboxMultiSelect {...props} />
      )}
      {appearance === 'Dropdown' && <DropdownMultiSelect {...props} />}
    </>
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  appearance: PropTypes.oneOf(['Checkbox', 'Dropdown']).isRequired,
};

MultiSelect.displayName = 'MultiSelect';
export default MultiSelect;
