import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ListItem from '@mui/material/ListItem';
import makeStyles from '@mui/styles/makeStyles';

import DragComponent from 'components/ContentRenderer/Embeddable/embeddables/components/DragComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px 12px',
    borderRadius: 4,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  isDraggable: {
    transition: theme.transitions.create(
      ['background-color', 'box-shadow', 'transform'],
      {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeOut,
      },
    ),
    '&:active': {
      cursor: 'grabbing',
    },
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  isDragging: {
    boxShadow: '0 7px 8px 0 rgba(0,0,0,0.06)',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const DraggableTargetBlock = ({ label, index, isDisabled, ...props }) => {
  const classes = useStyles();

  const draggableBlock = (
    <DragComponent index={index} isDragDisabled={isDisabled} {...props}>
      {({ snapshot, innerRef, ...draggableProps }) => (
        <ListItem
          ref={innerRef}
          className={cn(classes.root, classes.isDraggable, {
            [classes.isDragging]: snapshot.isDragging,
          })}
          {...props}
          {...draggableProps}
        >
          {label}
        </ListItem>
      )}
    </DragComponent>
  );

  const block = (
    <ListItem className={classes.root} {...props}>
      {label}
    </ListItem>
  );

  return isDisabled ? block : draggableBlock;
};

DraggableTargetBlock.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
};

DraggableTargetBlock.displayName = 'DraggableTargetBlock';
export default DraggableTargetBlock;
