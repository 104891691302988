import React from 'react';
import { useField, useFormikContext } from 'formik';

import MuiSwitch from '@mui/material/Switch';

const Switch = ({ disabled, helperText, ...props }) => {
  const [field] = useField(props);
  const { isSubmitting } = useFormikContext();

  return (
    <MuiSwitch disabled={isSubmitting || disabled} {...field} {...props} />
  );
};

Switch.displayName = 'Switch';

Switch.propTypes = { ...MuiSwitch.propTypes };

export default Switch;
