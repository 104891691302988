import { useMutation, useQuery } from 'react-query';
import { PathService } from 'core/services';

export const useGetPrograms = () =>
  useQuery('programs', () => PathService.getProgramList());

export const useGetProgram = (programId) =>
  useQuery(['program', programId], () =>
    PathService.getProgramDetail(programId),
  );

export const useGetProgramExperience = (programId, pathId, experienceId) =>
  useQuery(
    ['programExperience', { programId, pathId, experienceId }],
    () => PathService.getProgramExperience(programId, pathId, experienceId),
    { cacheTime: 0 },
  );

export const useEmailTips = () =>
  useMutation((urls) => PathService.emailTips(urls));

export const useSaveEvent = () =>
  useMutation(({ programId, contentType, contentId, parentContentId, event }) =>
    PathService.saveEvent(
      programId,
      contentType,
      contentId,
      parentContentId,
      event,
    ),
  );

export const useSaveResponses = () => {
  return useMutation(({ programId, contentType, contentId, responses }) =>
    PathService.saveResponses(programId, contentType, contentId, responses),
  );
};

export const useGetPathGlossary = (pathId) =>
  useQuery(['pathGlossary', pathId], () => PathService.getPathGlossary(pathId));

export const useGetPathReferences = (pathId) =>
  useQuery(['pathReferences', pathId], () =>
    PathService.getPathReferences(pathId),
  );

export const useGetExperienceGlossary = (experienceId) =>
  useQuery(['experienceGlossary', experienceId], () =>
    PathService.getExperienceGlossary(experienceId),
  );

export const useGetExperienceReferences = (experienceId) =>
  useQuery(['experienceReferences', experienceId], () =>
    PathService.getExperienceReferences(experienceId),
  );
