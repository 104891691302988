import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';

import { ToggleContext } from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/SingleSelectTableToggle';

const useStyles = makeStyles((theme) => ({
  group: {
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      '&:not(:first-of-type)': {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  button: {
    border: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  selected: {},
}));

const Toggle = ({ value, leftValue, rightValue, leftLabel, rightLabel }) => {
  const { response, handleChange } = useContext(ToggleContext);
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={response?.[value] || ''}
      exclusive
      onChange={handleChange(value)}
      aria-label={`${leftLabel} or ${rightLabel}`}
      className={classes.group}
    >
      <ToggleButton
        value={leftValue}
        classes={{
          root: classes.button,
          selected: classes.selected,
        }}
        aria-label={
          response?.[value] === leftValue
            ? leftLabel
            : `${leftLabel} not selected`
        }
      />
      <ToggleButton
        value={rightValue}
        classes={{
          root: classes.button,
          selected: classes.selected,
        }}
        aria-label={
          response?.[value] === rightValue
            ? rightLabel
            : `${rightLabel} not selected`
        }
      />
    </ToggleButtonGroup>
  );
};

Toggle.propTypes = {
  value: PropTypes.string.isRequired,
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
  ...Toggle.propTypes,
};

Toggle.displayName = 'Toggle';
export default Toggle;
