import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Experience from './Experience';
import PathDetail from './PathDetail';
import PathList from './PathList';

const Paths = ({ match }) => {
  return (
    <Switch>
      <Route path={match.path} component={PathList} exact />
      <Route path={`${match.path}/:programId`} component={PathDetail} exact />
      <Route
        path={`${match.path}/:programId/p/:pathId/e/:experienceId`}
        component={Experience}
        exact
      />
    </Switch>
  );
};

export default Paths;
