import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import PrivacyDialog from 'components/PrivacyDialog';
import { useBooleanState } from 'core/hooks/useBooleanState';

const ProgramListItem = ({
  title,
  openAt,
  closeAt,
  isLocked,
  shouldResume,
  heroImageUrl,
  contentType,
  programLink,
  contentLink,
  showPrivacyPolicy,
}) => {
  const [dialogIsOpen, toggleDialogOpen] = useBooleanState(false);

  const subtitle = isLocked ? (
    <FormattedMessage
      id="date.unlocked"
      values={{ unlockAt: moment(openAt).fromNow() }}
    />
  ) : closeAt ? (
    <FormattedMessage
      id="date.closing"
      values={{ closeAt: moment(closeAt).fromNow() }}
    />
  ) : (
    ''
  );

  const buttonText = (
    <FormattedMessage
      id={
        isLocked
          ? 'action.view'
          : shouldResume
          ? 'action.resume'
          : 'action.start'
      }
    />
  );

  const src = JSON.parse(heroImageUrl);

  return (
    <>
      <ListItem
        secondaryAction={
          <Button
            variant={isLocked ? 'outlined' : 'contained'}
            onClick={toggleDialogOpen}
            component={showPrivacyPolicy ? 'a' : Link}
            to={
              showPrivacyPolicy
                ? null
                : contentType === 'path' && !isLocked
                ? contentLink
                : programLink
            }
          >
            {buttonText}
          </Button>
        }
        disablePadding
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 2,
          marginTop: 2,
          '>.MuiListItemButton-root': {
            pr: 16,
          },
        }}
      >
        <ListItemButton component={Link} to={programLink} sx={{ p: 2 }}>
          <ListItemAvatar>
            <Avatar variant="square" src={src.url} />
          </ListItemAvatar>
          <ListItemText
            primary={title}
            secondary={subtitle}
            primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
          />
        </ListItemButton>
      </ListItem>
      {dialogIsOpen && (
        <PrivacyDialog
          open
          onClose={toggleDialogOpen}
          maxWidth="md"
          link={contentType === 'path' ? contentLink : programLink}
        />
      )}
    </>
  );
};

ProgramListItem.displayName = 'ProgramListItem';
export default ProgramListItem;
