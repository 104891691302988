import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import FormField from 'components/FormField';
import { useStores } from 'core/hooks/useStores';

const OtpForm = ({ identifier, onSuccess }) => {
  const intl = useIntl();
  const { authStore } = useStores();
  const { login } = authStore;

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setErrors({});
      await login(identifier, values.code);
      onSuccess();
    } catch (e) {
      setErrors({
        code: intl.formatMessage(
          {
            id: 'error.input.invalid',
          },
          { field: 'code' },
        ),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{ code: '' }}
      validationSchema={Yup.object({
        code: Yup.string().required(
          intl.formatMessage({ id: 'error.input.empty' }),
        ),
      })}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Typography variant="h1">
            <FormattedMessage id="login.title" />
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 1, mb: 3 }}
          >
            We've emailed you a single-use code. Please enter it here.
          </Typography>
          <FormField name="code" label="Code" fullWidth autoFocus />
          <Button
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            sx={{ mt: 2 }}
            startIcon={isSubmitting && <CircularProgress size={15} />}
            disabled={isSubmitting}
          >
            <FormattedMessage id="action.continue" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

OtpForm.displayName = 'OtpForm';
export default OtpForm;
