import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import DownloadLandingPage from 'components/DownloadLandingPage';
import { useDownloadOrganizationReport } from 'core/api/reports';
import { errorNotification } from 'components/Notifications';

const OrganizationReport = () => {
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const {
    mutateAsync: downloadOrganizationReport,
  } = useDownloadOrganizationReport();

  const handleDownload = async () =>
    await downloadOrganizationReport(
      {
        token: token,
      },
      {
        onError: () =>
          errorNotification(<FormattedMessage id="error.generic" />),
      },
    );

  useEffect(() => {
    handleDownload();
  }, []);

  return <DownloadLandingPage handleDownload={handleDownload} />;
};

OrganizationReport.displayName = 'OrganizationReport';
export default OrganizationReport;
