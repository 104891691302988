import ApiService from './ApiService';

export const getDecks = async ({ limit, page }) => {
  const data = await ApiService.get(`feed/decks/${limit}`, {
    params: { page },
  });
  return data.coachContent;
};

export const getDeck = async (id) => ApiService.get(`deck/${id}`);

export const getDeckProgress = async (deckCids) =>
  ApiService.post('feed/decks/progress', { deckCids });

export const getNextDeck = async (deckId) =>
  ApiService.get('feed/decks/next-up/1', {
    params: { exclusionContentCid: deckId },
  });

export const getCategories = async () => ApiService.get('feed/categories');

export const getCategory = async (id) =>
  ApiService.get(`feed/categories/${id}`);

export const getCollections = async () => ApiService.get('feed/collections');

export const getCollection = async (id) =>
  ApiService.get(`feed/collections/${id}`);

export const setBookmark = async (id, isBookmarked) =>
  ApiService.post('user/bookmark', {
    contentType: 'deck',
    contentCid: id,
    isBookmarked,
  });

export const getBookmarks = async () => ApiService.get('user/bookmarks/deck');

export const saveResponse = async (
  contentId,
  contentChildId,
  response,
  isSurvey,
) =>
  ApiService.post('deck/response', {
    contentType: 'deck',
    contentId,
    contentChildId,
    response,
    isSurvey,
  });

export const saveEvent = async (
  contentType,
  contentId,
  parentContentId,
  event,
  isSurvey,
) =>
  ApiService.post('deck/event', {
    contentType,
    contentId,
    parentContentId,
    event,
    isSurvey,
  });
