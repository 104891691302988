import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  text: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}));

const Reference = ({ title, url }) => {
  const classes = useStyles();

  return (
    <Typography
      display="inline"
      className={classes.text}
      component={url ? 'a' : null}
      variant="caption"
      href={url}
      target={url ? '_blank' : null}
      rel={url ? 'noopener noreferrer' : null}
    >
      {title}
    </Typography>
  );
};

Reference.propTypes = {
  title: PropTypes.string.isRequired,
  authors: PropTypes.string,
  publicationYear: PropTypes.number,
  link: PropTypes.string,
  type: PropTypes.oneOf([
    'Article',
    'Book',
    'Guide',
    'Journal',
    'Credit',
    'Video',
  ]),
};

Reference.displayName = 'Reference';
export default Reference;
