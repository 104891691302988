import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import ErrorBoundary from 'components/ErrorBoundary';
import { useGetCategories } from 'core/api/coach';

const Categories = () => {
  const { data, isLoading, error } = useGetCategories();

  return (
    <Paper
      sx={{ p: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Typography variant="h6" sx={{ mb: 1.5 }}>
            <FormattedMessage id="discover.categories.title" />
          </Typography>
          {data.map(({ id, title, colorText, colorBg }) => (
            <Chip
              key={id}
              component={Link}
              to={`/coach/categories/${id}`}
              clickable
              label={title}
              sx={{
                margin: 0.5,
                backgroundColor: `rgba(${colorBg})`,
                color: `rgba(${colorText})`,
              }}
            />
          ))}
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Categories.displayName = 'Categories';
export default Categories;
