import React from 'react';
import { Route } from 'react-router-dom';

import Container from 'containers/auth/components/Container';
import InviteForm from './InviteForm';
import RegisterForm from './RegisterForm';

const Register = ({ match }) => {
  return (
    <Container>
      <Route path={`${match.path}/invite`} exact component={InviteForm} />
      <Route path={`${match.path}`} exact component={RegisterForm} />
    </Container>
  );
};

Register.displayName = 'Register';
export default Register;
