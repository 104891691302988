import DragDrop from './DragDrop';
import SingleSelectTableDropdown from './SingleSelectTableDropdown';
import SingleSelectTableToggle from './SingleSelectTableToggle';
import Tagging from './Tagging';

export const activityTypeToComponent = {
  activityDragDrop: DragDrop,
  activitySingleSelectTableDropdown: SingleSelectTableDropdown,
  activitySingleSelectTableToggle: SingleSelectTableToggle,
  activityTagging: Tagging,
};
