import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ title, image: { file: { url } } = {} }) => {
  return <img alt={title || ''} src={url} />;
};

Icon.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

Icon.displayName = 'Icon';
export default Icon;
