import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/FilterList';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: ({ active }) => ({
    color: active ? theme.palette.primary.main : theme.palette.secondary.main,
  }),
}));

const FilterButton = ({ onClick, active, count, className }) => {
  const classes = useStyles({ active: active || count > 0 });

  return (
    <Button
      className={cn(className, classes.button)}
      onClick={onClick}
      startIcon={<FilterIcon />}
    >
      <FormattedMessage id="action.filter" />
      {count > 0 ? ` (${count})` : ''}
    </Button>
  );
};

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
};

FilterButton.displayName = 'FilterButton';
export default FilterButton;
