import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <path
    d="M20.055 4.609c.746.621.888 1.7.357 2.487l-.115.154-8.707 10.457a3.375 3.375 0 0 1-5.206-.024l-.192-.259-2.925-4.37a1.875 1.875 0 0 1 3.002-2.24l.115.154 2.646 3.954L17.415 4.85a1.875 1.875 0 0 1 2.487-.356l.153.115z"
    fill="currentColor"
  />,
  'CheckIcon',
);
