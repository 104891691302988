import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import PropTypes from 'prop-types';

import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import Lock from 'components/SvgIcons/Lock';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'max-content',
    height: 260,
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      height: 200,
      gap: theme.spacing(1.5),
    },
  },
  media: ({ disabled }) => ({
    gridRow: '1 / 3',
    gridColumn: '1 / 2',
    width: 350,
    height: 260,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: disabled ? 'rgba(0,0,0,0.5)' : 'transparent',
    [theme.breakpoints.down('md')]: {
      gridRow: '1 / 2',
      width: 92,
      height: 92,
      marginLeft: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
    },
  }),
  image: ({ disabled }) => ({
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    opacity: disabled ? 0.2 : 1,
    filter: disabled ? 'blur(3px)' : 'none',
    [theme.breakpoints.down('md')]: {
      borderRadius: theme.spacing(),
    },
  }),
  lock: {
    width: '4em',
    height: '4em',
    position: 'absolute',
    color: theme.palette.common.black,
    [theme.breakpoints.down('md')]: {
      width: '2em',
      height: '2em',
    },
  },
  content: {
    gridRow: '1 / 2',
    gridColumn: '2 / 4',
    paddingTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1.5),
    },
  },
  icon: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(1.5),
    },
  },
  actions: {
    gridRow: '2 / 3',
    gridColumn: '2 / 3',
    whiteSpace: 'nowrap',
    paddingBottom: theme.spacing(4),
    alignSelf: 'end',
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / 4',
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      '& > *': {
        width: '100%',
      },
    },
  },
}));

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const Card = ({ title, subtitle, content, image, icon, actions, disabled }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(() => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const classes = useStyles({ disabled });

  return (
    <MuiCard variant="outlined" sx={{ borderRadius: 1 }}>
      <div className={classes.container}>
        <div className={classes.media}>
          <img alt="" src={image} className={classes.image} />
          {disabled && <Lock className={classes.lock} />}
        </div>
        <div className={classes.content}>
          <div>
            <Typography variant="h5" display="block">
              {title}
            </Typography>
            <Typography variant="body2" display="block">
              {subtitle}
            </Typography>
            {!isMobile && (
              <ResponsiveEllipsis
                text={content}
                maxLine={isSmall ? 2 : 3}
                component={Typography}
                sx={{ py: 2 }}
              />
            )}
          </div>
          <div className={classes.icon}>{icon}</div>
        </div>
        <div className={classes.actions}>{actions}</div>
      </div>
    </MuiCard>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  icon: PropTypes.node,
  actions: PropTypes.node,
};

Card.displayName = 'Card';
export default Card;
