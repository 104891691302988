import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ActivityDetail from './ActivityDetail';
import CoachListItem from 'components/CoachListItem';
import ErrorBoundary from 'components/ErrorBoundary';
import { useBooleanState } from 'core/hooks/useBooleanState';
import { useGetActivity } from 'core/api/user';

const Activity = () => {
  const [openDialog, toggleOpenDialog] = useBooleanState(false);
  const { data, isLoading, error } = useGetActivity(2);

  return (
    <Paper
      sx={{ p: 2, mb: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              <FormattedMessage id="profile.activity.title" />
            </Typography>
            <Button onClick={toggleOpenDialog}>
              <FormattedMessage id="action.viewAll" />
            </Button>
          </Box>
          {data.pages.map((page) =>
            page.map(({ lastActivity, ...deck }, idx) => {
              const utc = moment
                .utc(lastActivity, 'MM-DD-YYYY hh:mm:ss')
                .toDate();
              const local = moment(utc).local();
              const fromNow = local.fromNow();

              return (
                <CoachListItem key={idx} secondaryText={fromNow} {...deck} />
              );
            }),
          )}
          <ActivityDetail open={openDialog} onClose={toggleOpenDialog} />
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Activity.displayName = 'Activity';
export default Activity;
