import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MuiTooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { ClickAwayListener } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  term: {
    textDecoration: 'none',
    fontStyle: 'normal',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    borderBottom: `1px ${theme.palette.common.black} dashed`,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const GlossaryTerm = ({ term, definition }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  return (
    <ClickAwayListener onClickAway={handleHide}>
      <MuiTooltip
        title={definition}
        placement="top"
        arrow
        disableFocusListener
        disableTouchListener
        disableHoverListener
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleHide}
        open={show}
      >
        <dfn
          className={classes.term}
          role="term"
          tabIndex={0}
          onClick={handleShow}
          onMouseEnter={handleShow}
          onMouseLeave={handleHide}
        >
          {term}
        </dfn>
      </MuiTooltip>
    </ClickAwayListener>
  );
};

GlossaryTerm.propTypes = {
  term: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
};

GlossaryTerm.displayName = 'GlossaryTerm';

export default GlossaryTerm;
