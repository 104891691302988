import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/LanguageOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';

import { useStores } from 'core/hooks/useStores';
import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles((theme) => ({
  language: {
    paddingLeft: theme.spacing(9),
  },
}));

const LanguageSelectItem = observer(() => {
  const classes = useStyles();

  const { userStore } = useStores();
  const { user, updateLanguagePreference } = userStore;

  const [openLanguageList, toggleOpenLanguageList] = useBooleanState(false);

  const locale = user ? user.employee.language.languageCode : 'en';

  const handleLanguageChange = (languageCode) => () =>
    updateLanguagePreference({ languageCode });

  if (user.organization.allowedLanguages.length > 1) {
    return (
      <Box>
        <ListItemButton onClick={toggleOpenLanguageList}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id="user.language" />} />
          {openLanguageList ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openLanguageList} timeout="auto" unmountOnExit>
          <List disablePadding>
            {user.organization.allowedLanguages.map(
              ({ name, languageCode }) => (
                <ListItemButton
                  key={languageCode}
                  selected={locale === languageCode}
                  onClick={handleLanguageChange(languageCode)}
                  classes={{
                    root: classes.language,
                  }}
                >
                  <ListItemText primary={name} />
                </ListItemButton>
              ),
            )}
          </List>
        </Collapse>
      </Box>
    );
  } else return null;
});

export default LanguageSelectItem;
LanguageSelectItem.displayName = 'LanguageSelectItem';
