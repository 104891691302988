import React, { useRef, useState, useLayoutEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Accordion from 'components/ContentRenderer/Embeddable/embeddables/Accordion';
import CardFlip from 'components/ContentRenderer/Embeddable/embeddables/CardFlip';
import { activityTypeToComponent } from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities';

const typeToComponent = {
  contentAccordion: Accordion,
  contentCardFlip: CardFlip,
  ...activityTypeToComponent,
};

const MAX_HEIGHT = 28; // 1 line of text

const ActivityCard = ({
  activity: { id: activityId, contentType, ...activity },
  id,
  instructions,
  title,
}) => {
  const ActivityComponent = typeToComponent[contentType];

  const [isOverflowing, setIsOverflowing] = useState(false);

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight) {
      setIsOverflowing(ref.current?.clientHeight > MAX_HEIGHT);
    }
  }, []);

  return (
    <Box px={{ xs: 2, sm: 3 }}>
      {title && (
        <Typography
          variant="h2"
          paragraph
          sx={{ textAlign: isOverflowing ? 'left' : 'center' }}
        >
          {title}
        </Typography>
      )}
      {instructions && <Typography paragraph>{instructions}</Typography>}
      <ActivityComponent id={id} container="small" {...activity} />
    </Box>
  );
};

ActivityCard.displayName = 'ActivityCard';
export default ActivityCard;
