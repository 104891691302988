import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g fill="currentColor">
    <rect x="4.5" y="12" width="15" height="9" rx="2.25" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 7.5A4.5 4.5 0 0 0 13.5 3h-3A4.5 4.5 0 0 0 6 7.5v9a4.5 4.5 0 0 0 4.5 4.5h3a4.5 4.5 0 0 0 4.5-4.5v-9zM10.5 6h3l.175.01A1.5 1.5 0 0 1 15 7.5v9l-.01.175A1.5 1.5 0 0 1 13.5 18h-3l-.175-.01A1.5 1.5 0 0 1 9 16.5v-9l.01-.175A1.5 1.5 0 0 1 10.5 6z"
    />
  </g>,
  'LockIcon',
);
