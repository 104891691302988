import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ProgramListItem from 'components/ProgramListItem';

const Paths = ({ programs }) => {
  // first two paths
  const paths = programs
    .filter(
      (program) =>
        program.contentType === 'path' || program.contentType === 'custom',
    )
    .slice(0, 2);

  if (paths.length === 0) return null;

  return (
    <Paper
      sx={{ mb: 2, p: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      <List
        disablePadding
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">
              <FormattedMessage id="home.paths.title" />
            </Typography>
            <Button component={Link} to="/paths">
              <FormattedMessage id="action.viewAll" />
            </Button>
          </ListSubheader>
        }
      >
        {paths.map((path, idx) => (
          <ProgramListItem
            key={idx}
            contentLink={`/paths/${path.uid}/p/${path.contentCid}/e/${path.experienceToResume}`}
            programLink={`/paths/${path.uid}`}
            {...path}
          />
        ))}
      </List>
    </Paper>
  );
};

Paths.displayName = 'Paths';
export default Paths;
