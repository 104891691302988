import ApiService from './ApiService';

export const getFeed = async () => ApiService.get('feed/all');

export const getSearchResults = async (key, term) =>
  ApiService.post('feed/search', { key, term });

export const getPastSearches = async () => ApiService.get('feed/searches/past');

export const deletePastSearches = async ({ all, term }) =>
  ApiService.get('feed/search/remove', { params: { all, term } });

export const getPopularSearches = async () =>
  ApiService.get('feed/searches/popular');

export const getCategories = async () => ApiService.get('feed/categories');

export const getSkills = async () => ApiService.get('feed/skills');

export const getEmployeeInterests = async (type) =>
  ApiService.get(`feed/employees/interests/${type}`);

export const updateEmployeeInterests = async (interests) =>
  ApiService.put('feed/employees/interests', { interests });

export const getEmployeeSkills = async () =>
  ApiService.get('feed/employees/completed-skills');

export const getAlerts = async () => ApiService.get('feed/alert/new');

export const readAlerts = async (uids) =>
  ApiService.put('feed/alert/read', { alerts: uids });
