import { useMutation, useQuery } from 'react-query';
import { CircleService } from 'core/services';

export const useGetPrograms = () =>
  useQuery('connectPrograms', () => CircleService.getProgramList());

export const useGetProgram = (programId) =>
  useQuery(['connectProgram', programId], () =>
    CircleService.getProgramDetail(programId),
  );

export const useGetProgramParticipants = (programId) =>
  useQuery(['connectProgramParticipants', programId], () =>
    CircleService.getProgramParticipants(programId),
  );

export const useGetCirclePreview = (circleId) =>
  useQuery(['circlePreview', circleId], () =>
    CircleService.getCirclePreview(circleId),
  );

export const useGetQuestionInsights = (
  programId,
  questionIds,
  refreshInterval = 0,
) =>
  useQuery(
    ['questionInsights', { programId, questionIds }],
    () => CircleService.getQuestionInsights(programId, questionIds),
    {
      refetchInterval: refreshInterval,
    },
  );

export const useSaveEvent = () =>
  useMutation(({ programId, contentType, contentId, parentContentId, event }) =>
    CircleService.saveEvent(
      programId,
      contentType,
      contentId,
      parentContentId,
      event,
    ),
  );

export const useSaveResponses = () => {
  return useMutation(({ programId, contentType, contentId, responses }) =>
    CircleService.saveResponses(programId, contentType, contentId, responses),
  );
};
