import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Appbar from './Appbar';
import NavigationDrawer from './NavigationDrawer';
import Footer from './Footer';
import { useBooleanState } from 'core/hooks/useBooleanState';

const BG_COLOR = {
  dark: 'background.default',
  light: 'background.paper',
};

const Layout = ({
  showNavigation,
  showFooter,
  showAppbar,
  children,
  align,
  disablePadding,
  color,
}) => {
  const [navigationOpen, toggleNavigationOpen] = useBooleanState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('lg'));

  return (
    <Box display="flex" height="100%">
      {showAppbar && (
        <Appbar
          toggleNavigationOpen={toggleNavigationOpen}
          isMobile={isMobile}
        />
      )}
      {(isMobile || showNavigation) && (
        <NavigationDrawer
          open={navigationOpen}
          toggleOpen={toggleNavigationOpen}
          isMobile={isMobile}
        />
      )}
      <Box
        component="main"
        flexGrow={1}
        bgcolor={BG_COLOR[color] || color}
        overflow="scroll"
      >
        {showAppbar && <Toolbar />}
        <Box
          height={{
            xs: showAppbar ? 'calc(100% - 57px)' : '100%',
            sm: showAppbar ? 'calc(100% - 65px)' : '100%',
          }}
          overflow="scroll"
          display="flex"
          flexDirection="column"
        >
          <Box
            flex="1 1 auto"
            pl={{ xs: disablePadding ? 0 : 2, sm: disablePadding ? 0 : 5 }}
            pr={{
              xs: disablePadding ? 0 : 2,
              sm: disablePadding ? 0 : 5,
              xl: disablePadding ? 0 : align === 'left' ? 28 : 5,
            }}
            pt={{ xs: disablePadding ? 0 : 2, sm: disablePadding ? 0 : 5 }}
            pb={{ xs: disablePadding ? 0 : 1.5 }}
          >
            {children}
          </Box>
          {showFooter && <Footer />}
        </Box>
      </Box>
    </Box>
  );
};

Layout.defaultProps = {
  align: 'left',
  color: 'dark',
  showAppbar: true,
};

Layout.propTypes = {
  children: PropTypes.node,
  showNavigation: PropTypes.bool,
  showFooter: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center']),
  color: PropTypes.string,
  disablePadding: PropTypes.bool,
};

Layout.displayName = 'Layout';
export default Layout;
