import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useStores } from 'core/hooks/useStores';
import { TextField } from 'components/FormField';
import { TIMEZONE_OPTIONS } from 'containers/profile/constants';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const Language = observer(() => {
  const intl = useIntl();

  const { userStore } = useStores();
  const { user, update } = userStore;

  const initialValues = {
    timezone: user.user.timezone || '',
    language: user.employee.language.languageCode || '',
  };

  const validationSchema = Yup.object().shape({
    timezone: Yup.string(),
    language: Yup.string(),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await update(values);
      successNotification(
        intl.formatMessage({ id: 'profile.settings.update.success' }),
      );
    } catch {
      errorNotification(intl.formatMessage({ id: 'error.generic' }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box flex="1 1 auto" ml={{ xs: 0, sm: 5, md: 7 }}>
      <Typography variant="h1" sx={{ mb: 5 }}>
        <FormattedMessage id="profile.settings.language" />
      </Typography>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <Stack spacing={2}>
              <TextField
                name="language"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.language" />}
                select
              >
                <MenuItem value="" />
                {user.organization.allowedLanguages.map(
                  ({ name, languageCode }) => (
                    <MenuItem key={languageCode} value={languageCode}>
                      {name}
                    </MenuItem>
                  ),
                )}
                ),
              </TextField>
              <TextField
                name="timezone"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.timezone" />}
                select
              >
                <MenuItem value="" />
                {TIMEZONE_OPTIONS.map(([key, value]) => (
                  <MenuItem key={value} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 3, float: 'right' }}
              disabled={!isValid || isSubmitting || !dirty}
            >
              <FormattedMessage id="action.save" />
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});

Language.displayName = 'Language';
export default Language;
