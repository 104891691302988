import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import makeStyles from '@mui/styles/makeStyles';

import Chip from 'components/ContentRenderer/Embeddable/embeddables/components/Chip';
import DragComponent from 'components/ContentRenderer/Embeddable/embeddables/components/DragComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  isDraggable: {
    cursor: 'grab',
    '&:hover, &:focus': {
      transform: 'rotate(2deg)',
      boxShadow: '0 7px 8px 0 rgba(0,0,0,0.06)',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
  isDragging: {
    boxShadow: '0 7px 8px 0 rgba(0,0,0,0.06)',
    cursor: 'grabbing',
  },
}));

const DraggableChip = ({ index, isDragDisabled, ...props }) => {
  const classes = useStyles();

  return (
    <DragComponent index={index} isDragDisabled={isDragDisabled} {...props}>
      {({ snapshot, ...draggableProps }) => (
        <Chip
          className={cn(classes.root, {
            [classes.isDragging]: snapshot.isDragging,
            [classes.isDraggable]: !snapshot.isDragging && !isDragDisabled,
          })}
          {...props}
          {...draggableProps}
        />
      )}
    </DragComponent>
  );
};

DraggableChip.propTypes = {
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool,
  ...Chip.propTypes,
};

DraggableChip.displayName = 'DraggableChip';
export default DraggableChip;
