import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g>
    <path
      d="M57.9244 18.5784H56.6549L54.4486 22.3426C52.7845 25.1816 49.7404 26.9258 46.4496 26.9258C44.6934 26.9258 43.0413 26.4344 41.6315 25.5758L40.1292 50.9633L41.6314 72.8882C57.3786 72.8882 70.1441 60.1227 70.1441 44.3756C70.1441 28.4281 57.9244 18.5784 57.9244 18.5784Z"
      fill="#EC5635"
    />
    <path
      d="M37.2413 18.7385L35.6219 5H33.4848C33.4848 5 13.1187 24.0084 13.1187 44.3746C13.1187 60.1218 25.8841 72.8873 41.6313 72.8873V25.5751C39.2677 24.1354 37.586 21.6628 37.2413 18.7385Z"
      fill="#EC5635"
    />
    <path
      d="M41.6313 36.3306L40.1289 53.969L41.6313 72.8892C51.7267 72.8892 59.9106 64.7052 59.9106 54.6099C59.9106 44.5145 51.7267 36.3306 41.6313 36.3306Z"
      fill="#FFB763"
    />
    <path
      d="M23.3521 54.6086C23.3521 64.704 31.536 72.8879 41.6313 72.8879V36.3293C31.536 36.3293 23.3521 44.5133 23.3521 54.6086Z"
      fill="#FFB763"
    />
    <path
      d="M41.6313 34.2134L40.1289 35.9363L41.6313 38.4387C50.5457 38.4387 57.7979 45.6911 57.7979 54.6053C57.7979 63.5196 50.5457 70.772 41.6313 70.772L40.1289 73.4949L41.6313 74.9973C52.8754 74.9973 62.0233 65.8494 62.0233 54.6053C62.0233 43.3613 52.8754 34.2134 41.6313 34.2134Z"
      fill="#FFB763"
    />
    <path
      d="M25.4646 54.6053C25.4646 45.691 32.717 38.4387 41.6312 38.4387V34.2134C30.3871 34.2134 21.2393 43.3613 21.2393 54.6053C21.2393 65.8494 30.3871 74.9973 41.6312 74.9973V70.772C32.7169 70.772 25.4646 63.5196 25.4646 54.6053Z"
      fill="#FFB763"
    />
  </g>,
  'StreakIcon',
  {
    viewBox: '0 0 80 80',
  },
);
