import React from 'react';
import { useField, useFormikContext } from 'formik';

import MuiTextField from '@mui/material/TextField';

/**
 * This TextField is a wrapper around the MUI TextField, that adds Formik
 * support using hooks (useField, useFormikContext).
 */

const TextField = ({ disabled, helperText, ...props }) => {
  const [field, meta] = useField(props);
  const { isSubmitting } = useFormikContext();

  const showError = meta.touched && !!meta.error;

  return (
    <MuiTextField
      disabled={isSubmitting || disabled}
      error={showError}
      helperText={showError ? meta.error : helperText}
      {...field}
      {...props}
    />
  );
};

TextField.displayName = 'TextField';

TextField.propTypes = { ...MuiTextField.propTypes };

export default TextField;
