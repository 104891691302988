import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/min/locales';
import { useStores } from 'core/hooks/useStores';

import enLocale from 'locales/en.json';
import frLocale from 'locales/fr.json';
import esLocale from 'locales/es.json';
import deLocale from 'locales/de.json';
import ptLocale from 'locales/pt.json';
import zhLocale from 'locales/zh.json';
import jaLocale from 'locales/ja.json';

const DEFAULT_LOCALE = 'en';

const messages = {
  en: enLocale,
  fr: Object.assign({}, enLocale, frLocale),
  es: Object.assign({}, enLocale, esLocale),
  de: Object.assign({}, enLocale, deLocale),
  pt: Object.assign({}, enLocale, ptLocale),
  zh: Object.assign({}, enLocale, zhLocale),
  ja: Object.assign({}, enLocale, jaLocale),
};

const formats = {
  number: {
    percent: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
    },
    currency: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    },
  },
  date: {
    'long-date-time': {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

const ConnectedIntlProvider = observer(({ children }) => {
  const { userStore } = useStores();
  const { user } = userStore;

  const locale = user ? user.employee.language.languageCode : DEFAULT_LOCALE;

  // Moment locale settings
  // TODO: We probably don't need moment for display anymore. We should look into using <FormattedDate/>
  Moment.globalMoment = moment;
  Moment.globalLocale = locale;
  Moment.globalLocal = true;
  moment.locale(locale);

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      formats={formats}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
});

ConnectedIntlProvider.propTypes = {
  children: PropTypes.any,
};

export default ConnectedIntlProvider;
