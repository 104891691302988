import { useEffect, useRef } from 'react';

/*
 * Same as UseEffect hook, but skips the first invocation
 * Helpful for reactions that involve manual interaction such as an input onChange
 */
const useUpdateEffect = (effect, dependencies) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useUpdateEffect;
