import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import BadgeDetail from './BadgeDetail';
import ErrorBoundary from 'components/ErrorBoundary';
import { useGetBadges } from 'core/api/user';
import { useBooleanState } from 'core/hooks/useBooleanState';

const Badges = () => {
  const [openDialog, toggleOpenDialog] = useBooleanState(false);
  const { data, isLoading, error } = useGetBadges();
  const badges = data?.employeeBadges.filter((badge) => !!badge.receivedAt);

  return (
    <Paper
      sx={{ p: 2, mb: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              <FormattedMessage id="profile.achievements.title" />
            </Typography>
            {data.employeeBadges.length > 0 && (
              <Button onClick={toggleOpenDialog}>
                <FormattedMessage id="action.viewAll" />
              </Button>
            )}
          </Box>
          {badges.length > 0 ? (
            badges.map(({ title, image: { file: { url } } }, idx) => (
              <Tooltip title={title}>
                <img
                  key={idx}
                  src={url}
                  alt=""
                  style={{ height: 45, marginRight: 16 }}
                />
              </Tooltip>
            ))
          ) : (
            <Typography color="textSecondary">
              <FormattedMessage id="profile.achievements.empty" />
            </Typography>
          )}
          <BadgeDetail
            open={openDialog}
            onClose={toggleOpenDialog}
            badges={data.employeeBadges}
            streak={data.streak}
          />
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Badges.displayName = 'Badges';
export default Badges;
