import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    strokeLinecap="round"
  >
    <g
      transform="translate(-289.000000, -459.000000)"
      stroke="currentColor"
      strokeWidth="4"
    >
      <path
        d="M293.000847,469.424394 C292.92072,463.9731 298.545245,461.79801 301.785599,463.142691 C305.025953,464.487372 309.950988,462.712752 310.226337,458"
        transform="translate(301.613168, 463.712197) scale(1, -1) rotate(30.000000) translate(-301.613168, -463.712197) "
      />
    </g>
  </g>,
  'NeutralIcon',
);
