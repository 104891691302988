import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import EmptyState from 'components/EmptyState';
import { TextField, PasswordField } from 'components/FormField';
import { useStores } from 'core/hooks/useStores';
import { errorNotification } from 'components/Notifications';

const RegisterForm = observer(() => {
  const intl = useIntl();

  const [tokenData, setTokenData] = useState('');
  const [resendTokenSuccess, setResendTokenSuccess] = useState(false);

  const { authStore } = useStores();
  const {
    checkRegistrationToken,
    sendRegistrationInviteByToken,
    checkUsername,
    register,
    login,
  } = authStore;

  const query = new URLSearchParams(window.location.search);
  const regToken = query.get('token');

  useEffect(() => {
    async function check() {
      const data = await checkRegistrationToken(regToken);
      setTokenData(data);
    }
    check();
  }, [regToken, checkRegistrationToken]);

  const onSubmit = async (
    { username, password, secondaryEmail, preferredName },
    { setSubmitting, setFieldError },
  ) => {
    try {
      await register({
        token: regToken,
        username,
        password,
        secondaryEmail,
        preferredName,
      });
      if (tokenData.authType === 'sso') {
        window.location = tokenData.ssoUrl;
      } else {
        await login(username, password);
      }
    } catch (e) {
      setFieldError(
        'email',
        'Unknown email. Please check with your administrator.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  const onResendToken = async () => {
    try {
      await sendRegistrationInviteByToken(regToken);
      setResendTokenSuccess(true);
    } catch (e) {
      setResendTokenSuccess(false);
      errorNotification(intl.formatMessage({ id: 'error.generic' }));
    }
  };

  const form = (
    <Formik
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{
        username: '',
        password: '',
        secondaryEmail: '',
        preferredName: '',
      }}
      validationSchema={Yup.object({
        username: Yup.string()
          .required(intl.formatMessage({ id: 'error.input.empty' }))
          .min(5, intl.formatMessage({ id: 'error.username.length' }))
          .max(32, intl.formatMessage({ id: 'error.username.length' }))
          .matches(
            /^((?![A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}).)*$/,
            intl.formatMessage({ id: 'error.username.email' }),
          )
          .matches(
            /^[A-zÀ-ú0-9_.-][^^]+$/,
            intl.formatMessage({ id: 'error.username.characters' }),
          )
          .test(
            'isAvailable',
            intl.formatMessage({ id: 'error.username.taken' }),
            (value) => checkUsername(value),
          ),
        ...(tokenData.authType !== 'sso' && {
          password: Yup.string()
            .min(8, intl.formatMessage({ id: 'error.password.length' }))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
              intl.formatMessage({ id: 'error.password.characters' }),
            )
            .required(intl.formatMessage({ id: 'error.input.empty' })),
        }),
        secondaryEmail: Yup.string()
          .email(intl.formatMessage({ id: 'error.email.invalid' }))
          .max(255, intl.formatMessage({ id: 'error.email.invalid' }))
          .notOneOf(
            [tokenData.workEmail],
            intl.formatMessage({ id: 'error.email.unique' }),
          )
          .notRequired(),
        preferredName: Yup.string()
          .matches(
            /^[aA-zZ\-\.\' ][^^]+$/,
            intl.formatMessage({ id: 'error.nickname.characters' }),
          )
          .min(2, intl.formatMessage({ id: 'error.nickname.length' }))
          .max(100, intl.formatMessage({ id: 'error.nickname.length' }))
          .notRequired(),
      })}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Typography variant="h1" sx={{ mb: 3 }}>
            Create your Eskalera account
          </Typography>
          <Box mb={2}>
            <TextField
              name="username"
              label="Username"
              fullWidth
              autoFocus
              helperText="Username must be between 5 and 32 characters long and can contain only letters, numbers, periods, hyphens and/or an underscore."
              inputProps={{ 'aria-label': 'Username' }}
            />
          </Box>
          {tokenData.authType !== 'sso' && (
            <Box mb={2}>
              <PasswordField
                name="password"
                label="Password"
                autoComplete="new-password"
                fullWidth
                helperText="Password must be at least 8 characters long and contain at least one uppercase, one lowercase, and one number."
                inputProps={{ 'aria-label': 'Password' }}
              />
            </Box>
          )}
          <Box mb={2}>
            <TextField
              name="secondaryEmail"
              label="Secondary email (optional)"
              helperText="Include a secondary email to continue using Eskalera even after you finish your engagement with your current employer."
              autoComplete="new-email"
              fullWidth
              inputProps={{ 'aria-label': 'Secondary email (optional)' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              name="preferredName"
              label="Preferred name (optional)"
              fullWidth
              inputProps={{ 'aria-label': 'Preferred name (optional)' }}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="caption" display="inline">
              By continuing, you agree to the{' '}
            </Typography>
            <Typography
              sx={{ textDecoration: 'none' }}
              component="a"
              display="inline"
              color="primary"
              variant="caption"
              href="https://eskalera.com/terms-of-use/"
              target="_blank"
            >
              Terms of service
            </Typography>
          </Box>
          <Button
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            startIcon={isSubmitting && <CircularProgress size={15} />}
            disabled={isSubmitting}
          >
            <FormattedMessage id="action.complete" />
          </Button>
        </Form>
      )}
    </Formik>
  );

  const invalidTokenError = (
    <EmptyState
      title="Invalid registration link"
      description="Sorry, your registration token is invalid. Please try again."
    />
  );

  const expiredTokenError = (
    <EmptyState
      title="Expired registration link"
      description="Sorry, your registration token has expired. Click the button below to request a new one."
      isLoading={sendRegistrationInviteByToken.pending}
      size="small"
      action={
        <Button variant="contained" onClick={onResendToken}>
          Request new link
        </Button>
      }
    />
  );

  const resendTokenConfirmation = (
    <EmptyState
      title="Check your email"
      description="Weʼve sent you a special link to complete your registration and activate your account."
    />
  );

  return (
    <Box>
      {checkRegistrationToken.match({
        pending: () => <EmptyState isLoading size="small" />,
        rejected: (err) => {
          const code = err?.response?.data?.code;
          if (code === 'ERROR_EXPIRED_REG_TOKEN') {
            return resendTokenSuccess
              ? resendTokenConfirmation
              : expiredTokenError;
          }
          return invalidTokenError;
        },
        resolved: () => form,
      })}
    </Box>
  );
});

RegisterForm.displayName = 'RegisterForm';
export default RegisterForm;
