import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const SsoRedirect = ({ url }) => {
  useEffect(() => {
    setTimeout(() => (window.location = url), 1500);
  }, [url]);
  return (
    <Box textAlign="center">
      <Typography component="h1" variant="h6" gutterBottom>
        Redirecting you to SSO login...
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default SsoRedirect;
