import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <path
    d="M2.10526 8.3901H12.7916C8.78611 3.11845 9.87986 0.329343 16.0729 0.0227704C22.2658 -0.283802 23.6157 2.50531 20.1225 8.3901H30.0404C31.2031 8.3901 32.1457 9.33266 32.1457 10.4954V20.8172C37.3819 17.161 40 18.2209 40 23.9969C40 29.7728 37.3819 30.9023 32.1457 27.3851V37.8947C32.1457 39.0574 31.2031 40 30.0404 40H19.3653C23.2632 34.3898 22.1657 31.5847 16.0729 31.5847C9.97998 31.5847 8.96977 34.3898 13.0422 40H2.10526C0.942558 40 0 39.0574 0 37.8947V26.8436C5.40777 30.1305 8.11166 29.1816 8.11166 23.9969C8.11166 18.8121 5.40777 17.8614 0 21.1445V10.4954C0 9.33266 0.942558 8.3901 2.10526 8.3901Z"
    fill="#00A2AE"
  />,
  'PuzzleIcon',
  {
    viewBox: '0 0 40 40',
  },
);
