import React from 'react';
import { useField } from 'formik';

import Checkbox from '@mui/material/Checkbox';

const FormikCheckbox = ({ ...props }) => {
  const [field] = useField(props);
  return <Checkbox {...field} {...props} />;
};

export default FormikCheckbox;
