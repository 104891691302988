import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <path
    d="M6.176 3.192a1.875 1.875 0 0 1 2.5-.134l.151.137 7.5 7.518c.683.685.727 1.764.134 2.5l-.137.152-7.5 7.482a1.875 1.875 0 0 1-2.785-2.503l.137-.152 6.172-6.16-6.175-6.188a1.875 1.875 0 0 1-.134-2.5l.137-.152z"
    fill="currentColor"
  />,
  'ArrowIcon',
);
