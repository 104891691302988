import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

import ContentRenderer from 'components/ContentRenderer/index';
import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(),
  },
  buttonVisible: {
    position: 'absolute',
    zIndex: 1,
  },
  buttonHidden: {
    display: 'none',
  },
  contentHidden: {
    filter: 'blur(16px)',
  },
}));

const HiddenContent = ({ content, buttonLabel }) => {
  const classes = useStyles();
  const [showContent, toggleShowContent] = useBooleanState(false);

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        className={cn({
          [classes.buttonVisible]: !showContent,
          [classes.buttonHidden]: showContent,
        })}
        onClick={toggleShowContent}
      >
        {buttonLabel}
      </Button>
      <div className={cn({ [classes.contentHidden]: !showContent })}>
        <ContentRenderer content={content} />
      </div>
    </div>
  );
};

HiddenContent.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

HiddenContent.displayName = 'HiddenContent';
export default HiddenContent;
