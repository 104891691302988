import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import DownloadLandingPage from 'components/DownloadLandingPage';
import { useDownloadManagerReport } from 'core/api/reports';
import { errorNotification } from 'components/Notifications';
import { useStores } from 'core/hooks/useStores';

const ManagerReport = () => {
  const { userStore } = useStores();
  const { user } = userStore;
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const { mutateAsync: downloadManagerReport } = useDownloadManagerReport();

  const handleDownload = async () =>
    await downloadManagerReport(
      {
        token: token,
        firstName: user.employee.firstName,
        lastName: user.employee.lastName,
      },
      {
        onError: () =>
          errorNotification(<FormattedMessage id="error.generic" />),
      },
    );

  useEffect(() => {
    handleDownload();
  }, []);

  return <DownloadLandingPage handleDownload={handleDownload} />;
};

ManagerReport.displayName = 'ManagerReport';
export default ManagerReport;
