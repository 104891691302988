import * as AuthService from './AuthService';
import * as PathService from './PathService';
import * as CampaignService from './CampaignService';
import * as CircleService from './CircleService';
import * as UserService from './UserService';
import * as StaticService from './StaticService';
import * as ReportService from './ReportService';
import * as FeedService from './FeedService';
import * as CoachService from './CoachService';
import ApiService from './ApiService';

ApiService.init();

export {
  ApiService,
  AuthService,
  PathService,
  CampaignService,
  CircleService,
  UserService,
  StaticService,
  ReportService,
  FeedService,
  CoachService,
};
