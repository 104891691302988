import React from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from 'components/Video';

const Video = ({
  video: { file: { url: videoUrl } } = {},
  closedCaptions: { file: { url: closedCaptionUrl } = {} } = {},
}) => {
  return <VideoPlayer video={videoUrl} closedCaptions={closedCaptionUrl} />;
};

Video.propTypes = {
  video: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  closedCaptions: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

Video.displayName = 'Video';
export default Video;
