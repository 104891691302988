import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Arrow from 'components/SvgIcons/Arrow';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';

const COLOR_MAP = {
  teal: '#007674',
  orange: '#C24A00',
  purple: '#76628D',
};

const useStyles = makeStyles((theme) => ({
  card: ({ isFlipped, isChoice }) => ({
    display: 'flex',
    flexDirection: 'column',
    WebkitBackfaceVisibility: 'hidden',
    backfaceVisibility: 'hidden',
    height: '100%',
    left: '0',
    top: '0',
    transformStyle: 'preserve-3d',
    transition: '0.6s',
    position: isChoice ? 'relative' : 'absolute',
    '&:first-child': {
      transform: `rotateY(${isFlipped ? 180 : 0}deg)`,
    },
    '&:not(:first-child)': {
      transform: `rotateY(${isFlipped && isChoice ? 0 : -180}deg)`,
    },
  }),
  content: ({ color }) => ({
    flex: '1 1 auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: COLOR_MAP[color]
      ? alpha(COLOR_MAP[color], 0.15)
      : 'default',
  }),
  title: ({ color }) => ({
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(),
    color: COLOR_MAP[color] ? COLOR_MAP[color] : theme.palette.text.primary,
  }),
  button: {
    borderRadius: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:not(:first-child)': {
      margin: 0,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  icon: ({ isFlipped }) => ({
    height: 20,
    position: 'absolute',
    left: isFlipped ? '-24px' : '32px',
    transform: `rotate(${isFlipped ? 180 : 0}deg)`,
    color: theme.palette.primary.main,
  }),
}));

const CardFace = ({
  value,
  title,
  content,
  image,
  color,
  options,
  onClick,
  isFlipped,
  activeFace,
}) => {
  const classes = useStyles({
    isFlipped,
    isChoice: value === activeFace,
    color,
  });
  const actions = options
    ? options
    : [
        {
          value: 'front',
          displayName: (
            <Box display="flex" alignItems="center" position="relative">
              <Arrow className={classes.icon} />
              <FormattedMessage id="action.flip" />
            </Box>
          ),
        },
      ];
  const ariaHidden = activeFace !== value;
  return (
    <Card variant="outlined" classes={{ root: classes.card }}>
      {image && <CardMedia image={image} />}
      <CardContent className={classes.content}>
        {title && (
          <Typography className={classes.title} aria-hidden={ariaHidden}>
            {title}
          </Typography>
        )}
        {content && <Typography aria-hidden={ariaHidden}>{content}</Typography>}
      </CardContent>
      <CardActions sx={{ p: 0 }}>
        {actions.map(({ value, displayName }) => (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            key={value}
            onClick={onClick(value)}
            className={classes.button}
            aria-hidden={ariaHidden}
          >
            {displayName}
          </Button>
        ))}
      </CardActions>
    </Card>
  );
};

CardFace.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.oneOf(['teal', 'orange', 'purple']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  activeFace: PropTypes.string.isRequired,
};

CardFace.displayName = 'CardFace';
export default CardFace;
