import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FeedService } from 'core/services';

export const useGetFeed = () => useQuery('feed', () => FeedService.getFeed());

export const useGetSearchResults = () =>
  useMutation(({ key, term }) => FeedService.getSearchResults(key, term));

export const useGetPopularSearches = () =>
  useQuery('popularSearches', () => FeedService.getPopularSearches());

export const useGetPastSearches = () =>
  useQuery('pastSearches', () => FeedService.getPastSearches());

export const useDeleteSearch = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ all, term }) =>
      FeedService.deletePastSearches({ all, term }),
    onSuccess: (data, { term, all }) => {
      if (!!term)
        queryClient.setQueriesData(['pastSearches'], (oldWordList) =>
          oldWordList.filter((oldWord) => oldWord !== term),
        );
      if (!!all) queryClient.setQueriesData(['pastSearches'], []);
    },
  });
};

export const useGetCategories = () =>
  useQuery('categories', () => FeedService.getCategories());

export const useGetSkills = () =>
  useQuery('skills', () => FeedService.getSkills());

export const useGetEmployeeInterests = (type) =>
  useQuery(['interests', type], () => FeedService.getEmployeeInterests(type));

export const useUpdateEmployeeInterests = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ interests }) =>
      FeedService.updateEmployeeInterests(interests),
    onSuccess: () => queryClient.resetQueries(['interests']),
  });
};

export const useGetEmployeeSkills = () =>
  useQuery('employeeSkills', () => FeedService.getEmployeeSkills());

export const useGetAlerts = () =>
  useQuery('alerts', () => FeedService.getAlerts());

export const useReadAlerts = () =>
  useMutation(({ uids }) => FeedService.readAlerts(uids));
