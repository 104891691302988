import palette from '../palette';
import typography from '../typography';
import { chipClasses } from '@mui/material/Chip';

export default {
  styleOverrides: {
    root: {
      borderRadius: 4,
      [`& .${chipClasses.avatar}`]: {
        marginLeft: 8,
        marginRight: -4,
        width: 16,
        height: 16,
      },
      [`& .${chipClasses.icon}`]: {
        marginLeft: 8,
        marginRight: -4,
      },
    },
    label: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    sizeSmall: {
      fontSize: typography.body2.fontSize,
      height: 28,
    },
    sizeMedium: {
      fontSize: typography.body1.fontSize,
      height: 30,
    },
    sizeLarge: {
      fontSize: typography.subtitle1.fontSize,
      height: 38,
    },
    colorDefault: {
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.divider}`,
      [`&.${chipClasses.clickable}:hover, &.${chipClasses.deletable}:hover`]: {
        backgroundColor: palette.background.hover,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.common.white,
        },
      },
    },
    deleteIcon: {
      color: palette.text.secondary,
      '&:hover, &:active': {
        color: palette.common.black,
      },
    },
    colorPrimary: {
      backgroundColor: palette.primary.main,
      [`&.${chipClasses.clickable}:hover, &.${chipClasses.deletable}:hover`]: {
        backgroundColor: palette.primary.dark,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.primary.main,
        },
      },
    },
    colorSecondary: {
      color: palette.text.primary,
      backgroundColor: palette.secondary.light,
      border: `1px solid ${palette.primary.main}`,
      [`&.${chipClasses.clickable}:hover, &.${chipClasses.deletable}:hover`]: {
        backgroundColor: palette.secondary.light,
        border: `1px solid ${palette.primary.dark}`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.secondary.light,
          border: `1px solid ${palette.primary.main}`,
        },
      },
    },
    deleteIconColorSecondary: {
      color: palette.primary.main,
      '&:hover, &:active': {
        color: palette.primary.dark,
      },
    },
  },
};
