import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import DownloadIcon from '@mui/icons-material/GetAppRounded';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import GlossaryIcon from '@mui/icons-material/MenuBookRounded';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReferenceIcon from '@mui/icons-material/BookOutlined';

import Glossary from 'containers/paths/components/Glossary';
import References from 'containers/paths/components/References';
import { useEmailTips, useSaveEvent } from 'core/api/path';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const Resources = ({
  anchorEl,
  onClose,
  programId,
  contentId,
  isFollowOn,
  downloadables,
  parentContentId,
}) => {
  const intl = useIntl();

  const { mutate: sendEmail } = useEmailTips();
  const { mutate: saveEvent } = useSaveEvent();

  const [openDrawer, setOpenDrawer] = useState('');
  const onOpenDrawer = (name) => () => setOpenDrawer(name);
  const onCloseDrawer = () => setOpenDrawer('');

  const onEmailResources = async () => {
    if (programId !== 'preview') {
      saveEvent({
        programId,
        contentType: 'RESOURCE',
        contentId,
        parentContentId,
        event: 'emailed',
      });
    }
    sendEmail(
      downloadables.map((d) => d.asset.file),
      {
        onSuccess: () =>
          successNotification(
            intl.formatMessage({ id: 'experience.tools.email.success' }),
          ),
        onError: () =>
          errorNotification(intl.formatMessage({ id: 'error.generic' })),
      },
    );
  };

  const onDownloadResources = () => {
    if (programId !== 'preview') {
      saveEvent({
        programId,
        contentType: 'RESOURCE',
        contentId,
        parentContentId,
        event: 'downloaded',
      });
    }

    if (downloadables.length > 1) {
      const zip = new JSZip();

      downloadables.forEach((d) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', d.asset.file.url, false);
        xhr.overrideMimeType('text/plain; charset=x-user-defined');
        xhr.send();

        zip.file(d.asset.file.fileName, xhr.responseText, {
          binary: true,
          compression: 'DEFLATE',
        });
      });

      zip.generateAsync({ type: 'blob' }).then((file) => {
        saveAs(file, 'resources.zip');
      });
    } else {
      saveAs(
        downloadables[0].asset.file.url,
        downloadables[0].asset.file.fileName,
      );
    }
  };

  const actions = {
    glossary: {
      component: Glossary,
      onClick: onOpenDrawer('glossary'),
      icon: GlossaryIcon,
      isHidden: isFollowOn,
      name: intl.formatMessage({ id: 'experience.tools.glossary' }),
    },
    references: {
      component: References,
      onClick: onOpenDrawer('references'),
      icon: ReferenceIcon,
      isHidden: isFollowOn,
      name: intl.formatMessage({ id: 'experience.tools.references' }),
    },
    download: {
      onClick: onDownloadResources,
      icon: DownloadIcon,
      isHidden: !downloadables,
      name: intl.formatMessage({ id: 'experience.tools.download' }),
    },
    email: {
      onClick: onEmailResources,
      icon: EmailIcon,
      isHidden: !downloadables,
      name: intl.formatMessage({ id: 'experience.tools.email' }),
    },
  };

  const availableActions = Object.values(actions).filter(
    (action) => !action.isHidden,
  );

  const { component: Component, name } = actions[openDrawer] || {};

  const onActionClick = (onClick) => () => {
    onClick && onClick();
    onClose();
  };

  if (availableActions.length === 0) return null;

  return (
    <>
      <Menu
        id="menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {availableActions.map(
          ({ name, icon: Icon, onClick, buttonProps }, idx) => (
            <MenuItem
              key={idx}
              onClick={onActionClick(onClick)}
              {...buttonProps}
            >
              <Icon sx={{ mr: 1 }} />
              {name}
            </MenuItem>
          ),
        )}
      </Menu>
      <Drawer anchor="right" open={!!openDrawer} onClose={onCloseDrawer}>
        <ListItem>
          <ListItemAvatar>
            <IconButton onClick={onCloseDrawer} size="large">
              <CloseIcon />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ variant: 'h4' }}
          />
        </ListItem>
        <Box width={{ xs: '100%', md: 780 }} px={3} py={2}>
          {!!openDrawer && (
            <Component
              type="experience"
              programId={programId}
              contentId={contentId}
              parentContentId={parentContentId}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

Resources.displayName = 'Resources';
export default Resources;
