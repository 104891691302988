import { useMutation } from 'react-query';
import { ReportService } from 'core/services';

export const useDownloadManagerReport = () =>
  useMutation(({ token, firstName, lastName }) =>
    ReportService.downloadManagerReport(token, firstName, lastName),
  );

export const useDownloadOrganizationReport = () =>
  useMutation(({ token }) => ReportService.downloadOrganizationReport(token));
