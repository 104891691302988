import { useEffect, useState } from 'react';

// takes in a value and returns debounced value after delay
const useDebounce = (value, delay, initialValue) => {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    const timer = setTimeout(() => setState(value), delay);

    // clear timeout should the value change while already debouncing
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return state;
};

export default useDebounce;
