import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ placeholder, onChange }) => {
  return (
    <OutlinedInput
      fullWidth
      margin="dense"
      size="small"
      onChange={onChange}
      placeholder={placeholder}
      inputProps={{ 'aria-label': placeholder }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

SearchBar.defaultProps = {
  placeholder: 'Search',
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SearchBar.displayName = 'SearchBar';
export default SearchBar;
