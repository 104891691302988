import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import { useGetServiceEmployees } from 'core/api/user';

const Employees = () => {
  const { data, isLoading, error } = useGetServiceEmployees();

  return (
    <Box mt={2}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        <FormattedMessage id="profile.settings.service.employees.title" />
      </Typography>
      {!isLoading && (
        <ErrorBoundary error={error}>
          {data.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    'name',
                    'email',
                    'organization',
                    'startDate',
                    'endDate',
                  ].map((column) => (
                    <TableCell scope="col" key={column}>
                      <FormattedMessage
                        id={`profile.settings.service.${column}`}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ id, employee, startDate, endDate }) => (
                  <TableRow key={id}>
                    <TableCell>{`${employee.firstName} ${employee.lastName}`}</TableCell>
                    <TableCell>{employee.workEmail}</TableCell>
                    <TableCell>{employee.organization.displayName}</TableCell>
                    <TableCell>{moment(startDate).format('LL')}</TableCell>
                    <TableCell>{moment(endDate).format('LL')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography color="textSecondary">
              <FormattedMessage id="profile.settings.service.employees.empty" />
            </Typography>
          )}
        </ErrorBoundary>
      )}
    </Box>
  );
};

Employees.displayName = 'Employees';
export default Employees;
