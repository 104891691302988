import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import DropContainer from 'components/ContentRenderer/Embeddable/embeddables/components/DropContainer';
import Draggable from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/DragDrop/components/DraggableTargetBlock';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    padding: 6,
  },
  innerContainer: {
    border: '6px transparent dashed',
    height: '100%',
    padding: 6,
    overflowY: 'scroll',
  },
  isDraggingOver: {
    border: '6px rgba(0, 0, 0, 0.2) dashed',
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(),
    borderRadius: '4px',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 6,
    whiteSpace: 'pre-line',
  },
}));

const DraggableBlockTarget = ({
  droppableId,
  options,
  selected,
  isDisabled,
  label,
  correctAnswer,
}) => {
  const classes = useStyles();

  const selectedOption = options.find((o) => o.value === selected['item']);
  const correctOption = options.find((o) => o.value === correctAnswer[0]);
  const option = isDisabled ? correctOption : selectedOption;

  return (
    <div className={classes.outerContainer}>
      <DropContainer
        droppableId={droppableId}
        isDropDisabled={isDisabled}
        classes={{
          root: classes.innerContainer,
          isDraggingOver: classes.isDraggingOver,
        }}
      >
        <Typography className={classes.label}>{label}</Typography>
        {option && <Draggable index={0} isDisabled={isDisabled} {...option} />}
      </DropContainer>
    </div>
  );
};

DraggableBlockTarget.propTypes = {
  droppableId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.shape({
    section: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  correctAnswer: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DraggableBlockTarget.displayName = 'DraggableBlockTarget';
export default DraggableBlockTarget;
