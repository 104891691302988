import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { FormWizardContext } from './FormWizard';

const FormWizardStepper = ({
  children,
  containerComponent: ContainerComponent = 'div',
  containerProps,
  className,
  titleTypographyProps,
}) => {
  const wizardProps = useContext(FormWizardContext);

  if (typeof children === 'function') {
    return children(wizardProps);
  } else {
    const { activeStep, stepTitles } = wizardProps;
    return (
      <ContainerComponent {...containerProps}>
        <Stepper activeStep={activeStep} className={className}>
          {stepTitles.map((title, index) => (
            <Step key={index}>
              <StepLabel>
                <Typography variant="overline" {...titleTypographyProps}>
                  {title}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </ContainerComponent>
    );
  }
};

FormWizardStepper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  containerComponent: PropTypes.elementType,
  containerProps: PropTypes.object,
  titleTypographyProps: PropTypes.object,
};

FormWizardStepper.displayName = 'FormWizardStepper';
export default FormWizardStepper;
