import ApiService from './ApiService';

export const getProgramList = async () => ApiService.get('learning/programs');

export const getProgramDetail = async (id) =>
  ApiService.get(`learning/programs/${id}`);

export const getProgramExperience = async (programId, pathId, experienceId) =>
  ApiService.get(`learning/programs/${programId}/${pathId}/${experienceId}`);

export const saveResponses = async (
  programId,
  contentType,
  contentId,
  responses,
) =>
  ApiService.post(`learning/programs/${programId}/responses`, {
    contentType,
    contentId,
    responses,
  });

export const saveEvent = async (
  programId,
  contentType,
  contentId,
  parentContentId,
  event,
) =>
  ApiService.post(`learning/programs/${programId}/event`, {
    contentType,
    contentId,
    parentContentId,
    event,
  });

export const emailTips = async (urls) => {
  return await ApiService.post(`learning/programs/email-tips`, {
    fileUrls: urls,
  });
};

export const getPathGlossary = async (id) => {
  return await ApiService.get(`learning/paths/${id}/glossary`);
};

export const getPathReferences = async (id) => {
  return await ApiService.get(`learning/paths/${id}/references`);
};

export const getExperienceGlossary = async (id) => {
  return await ApiService.get(`learning/experiences/${id}/glossary`);
};

export const getExperienceReferences = async (id) => {
  return await ApiService.get(`learning/experiences/${id}/references`);
};
