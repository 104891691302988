import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';

import Container from 'containers/auth/components/Container';
import EmptyState from 'components/EmptyState';
import { useStores } from 'core/hooks/useStores';

const VerifyEmail = observer(() => {
  const { authStore } = useStores();
  const { verifyEmail } = authStore;

  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  useEffect(() => {
    verifyEmail(token);
  }, [token, verifyEmail]);

  return (
    <Container>
      {verifyEmail.match({
        pending: () => <EmptyState isLoading size="small" />,
        resolved: () => (
          <EmptyState
            title="Success!"
            description="Your email address has been verified."
            action={
              <Button variant="contained" component={Link} to="/">
                Home
              </Button>
            }
          />
        ),
        rejected: () => (
          <EmptyState
            title="Invalid token"
            description="We could not verify your email. Please try again later."
          />
        ),
      })}
    </Container>
  );
});

VerifyEmail.displayName = 'VerifyEmail';
export default VerifyEmail;
