import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useStores } from 'core/hooks/useStores';

/**
 * Component that is in charge of showing/hiding UI elements based on the logged
 * in user's permissions.
 */

const Gate = ({
  any,
  children,
  onDenied,
  permissions,
  redirectOnDenied,
  noServiceLogin,
  demoOnly,
}) => {
  const { userStore } = useStores();

  // Validates that the user is logged in
  if (!userStore.loggedIn) {
    return onDenied || <Redirect to="/login" />;
  }

  if (demoOnly && !userStore.user.organization.isDemoOrganization) {
    return redirectOnDenied ? <Redirect to="/404" /> : onDenied || null;
  }

  if (noServiceLogin && userStore.user.employee.isServiceLogin) {
    return redirectOnDenied ? <Redirect to="/404" /> : onDenied || null;
  }

  // Validates that the user has the provided permissions
  if (
    !userStore.hasPermission({
      requiredPerms: permissions,
      any,
    })
  ) {
    // Redirect the user to a 404 if the redirectOnDenied flag is set
    return redirectOnDenied ? <Redirect to="/404" /> : onDenied || null;
  }

  return children;
};

Gate.propTypes = {
  /* Render if any of the passed in permissions match (as opposed to all). */
  any: PropTypes.bool,
  /* Custom node to render if the user is denied. */
  onDenied: PropTypes.any,
  /* Array of permissions that a user should have to see the children. */
  permissions: PropTypes.arrayOf(PropTypes.string),
  /* Redirect the user to /404 if they are denied. */
  redirectOnDenied: PropTypes.bool,
  /* Whether component should be rendered when logged in user is a service account */
  noServiceLogin: PropTypes.bool,
  /* Only renders component when the org is set as demo and the user has the demo permission*/
  demoOnly: PropTypes.bool,
  /* The content to show when permissions are met */
  children: PropTypes.node.isRequired,
};

Gate.defaultProps = {
  permissions: [],
  any: false,
  noServiceLogin: false,
  demoOnly: false,
};

Gate.displayName = 'Gate';

export default Gate;
