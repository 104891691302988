import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import makeStyles from '@mui/styles/makeStyles';

import ContentRenderer from 'components/ContentRenderer';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `2px solid ${theme.palette.divider}`,
    '& p': {
      marginBottom: 0,
    },
    '& th, td': {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(),
      },
      '& p:empty': {
        display: 'none',
      },
      alignItems: 'center',
      textAlign: 'center',
    },
  },
}));

export const ToggleContext = React.createContext(null);

const SingleSelectTableToggle = ({ id, table }) => {
  const classes = useStyles();
  const [{ value: response }, , helpers] = useField({ name: id });
  const handleChange = (key) => (e, value) => {
    helpers.setValue({ ...response, [key]: value });
  };

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      className={classes.container}
    >
      <ToggleContext.Provider
        value={{ response: response || {}, handleChange: handleChange }}
      >
        <ContentRenderer content={table} />
      </ToggleContext.Provider>
    </TableContainer>
  );
};

SingleSelectTableToggle.propTypes = {
  table: PropTypes.object,
};

SingleSelectTableToggle.displayName = 'SingleSelectTableToggle';
export default SingleSelectTableToggle;
