import React, { useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, responsiveFontSizes, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import ContentRenderer from 'components/ContentRenderer';
import { useSaveEvent } from 'core/api/path';

const PROGRESS_BAR_HEIGHT = 4;

const DESKTOP_APPBAR_HEIGHT = 65;
const DESKTOP_NAVIGATION_HEIGHT = 56;

const MOBILE_HEADER_HEIGHT = 56;
const MOBILE_NAVIGATION_HEIGHT = 57;

const MOBILE_OFFSET =
  MOBILE_HEADER_HEIGHT + PROGRESS_BAR_HEIGHT + MOBILE_NAVIGATION_HEIGHT;
const DESKTOP_OFFSET =
  DESKTOP_APPBAR_HEIGHT + DESKTOP_NAVIGATION_HEIGHT + PROGRESS_BAR_HEIGHT;

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'scroll',
    height: `calc(100vh - ${DESKTOP_OFFSET}px)`,
    [theme.breakpoints.down('md')]: {
      height: `calc(100vh - ${MOBILE_OFFSET}px)`,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& p:empty': {
      display: 'none',
    },
  },
  paddedColumn: {
    padding: theme.spacing(4),
  },
  secondColumn: {
    backgroundColor: '#F9F9F9',
  },
}));

const Step = ({
  id,
  experienceId,
  columns,
  direction = 'up',
  programId,
  program,
  isClosed = false,
  isPreview = false,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isSmall = useMediaQuery(() => theme.breakpoints.down('md'));

  const { mutate: saveEvent } = useSaveEvent();

  useEffect(() => {
    if (!isPreview) {
      saveEvent({
        programId,
        contentType: 'EXPERIENCE_STEP',
        contentId: id,
        parentContentId: experienceId,
        event: 'started',
      });
    }
  }, [isPreview, saveEvent, id, programId, experienceId]);

  return (
    <ThemeProvider
      theme={(theme) =>
        responsiveFontSizes(
          createTheme({
            ...theme,
            typography: {
              ...theme.typography,
              body1: {
                ...theme.typography.h6,
                fontWeight: theme.typography.fontWeightRegular,
              },
            },
          }),
        )
      }
    >
      <div className={classes.container}>
        {(isClosed || isPreview) && (
          <Alert
            severity="warning"
            sx={{ m: 2, px: 1, py: 0, borderRadius: '6px' }}
          >
            <FormattedMessage
              id={isClosed ? 'program.expired' : 'program.preview'}
              values={{ date: moment(program?.closeAt).format('ll') }}
            />
          </Alert>
        )}
        <Grid container direction="row">
          {columns.map((column, idx) => {
            const isFullWidth = columns.length === 1;
            const isFirstColumn = idx === 0;
            const isAsset =
              column.content[0]?.nodeType === 'embedded-asset-block';

            return (
              <Slide
                key={`slide-${idx}`}
                in={true}
                direction={direction}
                timeout={isFullWidth ? 1300 : isFirstColumn ? 1400 : 1500}
                mountOnEnter
              >
                <Grid
                  xs={isFullWidth || isSmall ? 12 : isFirstColumn ? 8 : 4}
                  className={cn(classes.column, {
                    [classes.paddedColumn]: !isAsset,
                    [classes.secondColumn]: !isFirstColumn,
                  })}
                >
                  <ContentRenderer
                    content={column}
                    embeddableProps={{ programId, experienceId }}
                  />
                </Grid>
              </Slide>
            );
          })}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

Step.displayName = 'Step';
export default Step;
