import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import PasswordField from 'components/FormField/PasswordField';
import OrganizationPickerForm from './OrganizationPickerForm';
import PasswordResetForm from './PasswordResetForm';
import { useStores } from 'core/hooks/useStores';

const PasswordForm = ({ identifier, onSuccess }) => {
  const intl = useIntl();
  const { authStore } = useStores();
  const [password, setPassword] = useState();
  const [forcePasswordReset, setForcePasswordReset] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const { login } = authStore;

  const onSubmit = async (
    { password, organizationUid },
    { setSubmitting, setErrors },
  ) => {
    try {
      setErrors({});
      setPassword(password);
      const { loggedIn, forcePasswordReset, organizations } = await login(
        identifier,
        password,
      );
      if (forcePasswordReset) {
        // show password reset form
        setForcePasswordReset(forcePasswordReset);
      } else if (organizations && organizations.length > 0) {
        // show organization picker form
        setOrganizationOptions(organizations);
      } else if (loggedIn) {
        onSuccess();
      }
    } catch (e) {
      const error = e.response.data?.error;
      if (error === 'ERROR_RATE_LIMITED') {
        setErrors({
          password: 'Too many login attempts. Try again in 60 seconds.',
        });
      } else if (error === 'ERROR_UNAUTHENTICATED') {
        setErrors({ password: 'Incorrect username or password' });
      } else {
        setErrors({
          password: intl.formatMessage({ id: 'error.generic' }),
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (organizationOptions.length > 0) {
    return (
      <OrganizationPickerForm
        identifier={identifier}
        password={password}
        organizations={organizationOptions}
      />
    );
  }

  if (forcePasswordReset) {
    return <PasswordResetForm identifier={identifier} password={password} />;
  }

  return (
    <Formik
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{ password: '' }}
      validationSchema={Yup.object({
        password: Yup.string().required(
          intl.formatMessage({ id: 'error.input.empty' }),
        ),
      })}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Typography variant="h1">
            <FormattedMessage id="login.title" />
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 1, mb: 3 }}
          >
            {`Signing in as ${identifier}`}
          </Typography>
          <PasswordField
            name="password"
            label="Password"
            fullWidth
            autoFocus
            inputProps={{
              'aria-label': 'Password',
            }}
          />
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Typography
              variant="caption"
              component={Link}
              to="/"
              color="primary"
              sx={{ textDecoration: 'none' }}
            >
              {`Not ${identifier}?`}
            </Typography>
            <Typography
              sx={{ textDecoration: 'none' }}
              component={Link}
              variant="caption"
              to="/recover"
              display="inline"
              color="primary"
            >
              Forgot password?
            </Typography>
          </Box>
          <Button
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            sx={{ mt: 2 }}
            startIcon={isSubmitting && <CircularProgress size={15} />}
            disabled={isSubmitting}
          >
            <FormattedMessage id="action.continue" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

PasswordForm.displayName = 'PasswordForm';
export default PasswordForm;
