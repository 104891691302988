import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
      borderColor: palette.divider,
      '.MuiInputAdornment-root .MuiSvgIcon-root': {
        color: palette.text.secondary,
      },
      '&.Mui-disabled': {
        backgroundColor: palette.background.disabled,
        color: palette.text.disabled,
      },
    },
    input: {
      '&::-webkit-input-placeholder': {
        color: palette.text.secondary,
        opacity: 1,
      },
      '&::-moz-placeholder': { color: palette.text.secondary, opacity: 1 }, // Firefox 19+
      '&:-ms-input-placeholder': { color: palette.text.secondary, opacity: 1 }, // IE 11
      '&::-ms-input-placeholder': { color: palette.text.secondary, opacity: 1 }, // Edge
    },
  },
};
