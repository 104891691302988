import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useStores } from 'core/hooks/useStores';
import { PasswordField } from 'components/FormField';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const Password = observer(() => {
  const intl = useIntl();

  const { authStore } = useStores();
  const { updatePassword } = authStore;

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, intl.formatMessage({ id: 'error.password.length' }))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
        intl.formatMessage({ id: 'error.password.characters' }),
      )
      .required(intl.formatMessage({ id: 'error.input.empty' })),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({ id: 'error.password.match' }),
      )
      .required(intl.formatMessage({ id: 'error.input.empty' })),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await updatePassword(values);
      successNotification(
        intl.formatMessage({ id: 'profile.settings.update.success' }),
      );
    } catch (e) {
      const error = e.response.data?.error;
      if (error === 'ERROR_PASSWORD_INVALID')
        errorNotification(
          intl.formatMessage(
            {
              id: 'error.input.invalid',
            },
            { field: 'current password' },
          ),
        );
      else errorNotification(intl.formatMessage({ id: 'error.generic' }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box flex="1 1 auto" ml={{ xs: 0, sm: 5, md: 7 }}>
      <Typography variant="h1" sx={{ mb: 5 }}>
        <FormattedMessage id="profile.settings.password" />
      </Typography>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <Stack spacing={2}>
              <PasswordField
                name="oldPassword"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.password.current" />}
              />
              <PasswordField
                name="newPassword"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.password.new" />}
              />
              <PasswordField
                name="confirmPassword"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.password.confirm" />}
              />
            </Stack>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 3, float: 'right' }}
              disabled={!isValid || isSubmitting || !dirty}
            >
              <FormattedMessage id="action.update" />
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});

Password.displayName = 'Password';
export default Password;
