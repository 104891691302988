import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import BackArrow from '@mui/icons-material/KeyboardArrowLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandArrow from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import NextArrow from '@mui/icons-material/KeyboardArrowRight';

import Resources from '../Resources';
import { FormWizardActions } from 'components/FormWizard';

const MobileNavigation = ({
  isPreview,
  title,
  pathId,
  experienceId,
  programId,
  program,
  programLink,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const onOpenResources = (e) => setAnchorEl(e.currentTarget);
  const onCloseResources = () => setAnchorEl(null);

  return (
    <FormWizardActions>
      {({
        isFirstStep,
        isLastStep,
        previousStep,
        isSubmitting,
        totalSteps,
      }) => (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={1}
          px={2}
          borderTop={1}
          borderColor="divider"
        >
          {totalSteps > 1 && (
            <IconButton
              disabled={isFirstStep}
              onClick={previousStep}
              color="primary"
              sx={{
                border: 2,
                borderRadius: '4px',
                py: '6px',
                px: 2,
                borderColor: isFirstStep
                  ? 'background.disabled'
                  : 'primary.main',
              }}
            >
              <BackArrow />
            </IconButton>
          )}
          <Button
            aria-controls="menu"
            aria-haspopup="true"
            onClick={onOpenResources}
            endIcon={<ExpandArrow />}
          >
            <FormattedMessage id="experience.tools.title" />
          </Button>
          <Resources
            anchorEl={anchorEl}
            onClose={onCloseResources}
            programId={programId}
            contentId={experienceId}
            parentContentId={program?.contentId}
            {...props}
          />
          {!isLastStep && (
            <IconButton
              type="submit"
              disabled={isSubmitting}
              color="primary"
              sx={{
                border: 2,
                borderRadius: '4px',
                py: '6px',
                px: 2,
                bgcolor: 'primary.main',
                color: 'common.white',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              {isSubmitting ? <CircularProgress size={15} /> : <NextArrow />}
            </IconButton>
          )}
          {isLastStep && (
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              sx={{ minWidth: 60, width: 60 }}
            >
              {isSubmitting ? (
                <CircularProgress size={15} />
              ) : (
                <FormattedMessage id="action.done" />
              )}
            </Button>
          )}
        </Box>
      )}
    </FormWizardActions>
  );
};

MobileNavigation.displayName = 'MobileNavigation';
export default MobileNavigation;
