import { useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useField } from 'formik';

const DragDropBlockWrapper = ({ id, sections, options, children }) => {
  const [
    {
      value: selected = sections.map((section) => ({
        section: section.id,
        item: '',
      })),
    },
    ,
    helpers,
  ] = useField({ name: id });

  // unselected options = all options - selected items
  const [unselected, setUnselected] = useState(
    options
      .map((option) => option.value)
      .filter(
        (option) => !selected.map((section) => section.item).includes(option),
      ),
  );

  const onRemoveOption = (option, section) => {
    setUnselected(update(unselected, { $push: [option] }));

    helpers.setValue(
      update(selected, {
        [section]: { item: { $set: '' } },
      }),
    );
  };

  const onAddOption = (option, section, index) => {
    // if an option is already in the drop zone remove it
    if (selected[section]['item']) {
      setUnselected(
        update(unselected, {
          $splice: [[index, 1]],
          $push: [selected[section]['item']],
        }),
      );
    } else {
      setUnselected(update(unselected, { $splice: [[index, 1]] }));
    }

    helpers.setValue(
      update(selected, {
        [section]: { item: { $set: option } },
      }),
    );
  };

  const onMoveOption = (option, fromSection, toSection) => {
    // if an option is already in the drop zone swap the two options
    helpers.setValue(
      update(selected, {
        [toSection]: {
          item: {
            $set: option,
          },
        },
        [fromSection]: {
          item: { $set: selected[toSection]['item'] || '' },
        },
      }),
    );
  };

  const onDragEnd = ({ source, destination, draggableId }) => {
    // if dropped outside of drop zone or back in the source
    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    // if removed from a target section & added to unselected options
    if (destination.droppableId === 'unselected') {
      onRemoveOption(draggableId, parseInt(source.droppableId));
    }

    // if removed from unselected options & added to a target section
    else if (source.droppableId === 'unselected') {
      onAddOption(draggableId, parseInt(destination.droppableId), source.index);
    }

    // if removed from a target section & added to another target section
    else {
      onMoveOption(
        draggableId,
        parseInt(source.droppableId),
        parseInt(destination.droppableId),
      );
    }
  };

  return children({ options, selected, unselected, onDragEnd });
};

DragDropBlockWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      color: PropTypes.string,
      avatar: PropTypes.string,
    }),
  ).isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      image: PropTypes.object,
      correctAnswer: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string }),
      ),
    }).isRequired,
  ).isRequired,
};

DragDropBlockWrapper.displayName = 'DragDropBlockWrapper';
export default DragDropBlockWrapper;
