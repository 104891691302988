import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import Arrow from '@mui/icons-material/PlayCircleFilledOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Check from 'components/SvgIcons/Check';
import ContentRenderer from 'components/ContentRenderer';

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    border: `1px solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    padding: theme.spacing(0, 4),
    '&:hover': {
      backgroundColor: theme.palette.background.hover,
    },
  },
  icon: {
    marginLeft: theme.spacing(2),
  },
}));

const Step = ({
  expanded,
  onExpandChange,
  hasNext,
  onNext,
  program,
  isLoading,
  step: { id, title, duration, content },
}) => {
  const classes = useStyles({ isClosed: program.isClosed });
  const status = (program.itemStatus && program.itemStatus[id]) || {};
  const isCompleted = !!status.completedAt;
  const isClosed = program.isClosed;
  const Icon = isCompleted ? Check : Arrow;
  const { values, handleSubmit } = useFormikContext();

  useEffect(() => {
    if (!expanded) handleSubmit();
  }, [expanded]);

  return (
    <Accordion
      elevation={0}
      classes={{ root: classes.accordionRoot }}
      expanded={expanded}
      onChange={onExpandChange}
      TransitionProps={{
        timeout: {
          enter: 500,
          // exit: 0 so that the content isn't transitioning to the bottom requiring the user to scroll back up
          exit: 0,
          // only mount content when expanded for performance
          unmountOnExit: true,
        },
      }}
    >
      <AccordionSummary
        classes={{
          root: cn(classes.accordionSummary),
        }}
        aria-controls={`step-${id}-content`}
        id={`step-${id}-header`}
      >
        <ListItemText
          primary={title}
          secondary={
            isCompleted && (
              <FormattedMessage
                id="date.completed"
                values={{
                  completedAt: moment.utc(status['completedAt']).format('ll'),
                }}
              />
            )
          }
          primaryTypographyProps={{ variant: 'h6' }}
          secondaryTypographyProps={{ variant: 'caption' }}
        />
        <ListItemSecondaryAction>
          <Box display="flex" alignItems="end" whiteSpace="nowrap" ml={1}>
            {!isClosed && (
              <Typography color="inherit">
                <FormattedMessage id="time.minutes" values={{ duration }} />
              </Typography>
            )}
            <Icon
              className={classes.icon}
              htmlColor={isClosed ? '#212c2c' : '#007881'}
            />
          </Box>
        </ListItemSecondaryAction>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <Box p={2}>
            <ContentRenderer
              content={content}
              embeddableProps={{ programId: program.id }}
            />
            <Box mt={4}>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                aria-controls={`step-${id}-content`}
                onClick={Object.keys(values) > 0 ? handleSubmit : onNext}
              >
                <FormattedMessage id={`action.${hasNext ? 'next' : 'done'}`} />
              </Button>
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

Step.propTypes = {
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  hasNext: PropTypes.bool,
  onNext: PropTypes.func,
  isLoading: PropTypes.bool,
  program: PropTypes.shape({
    isClosed: PropTypes.bool,
    itemStatus: PropTypes.objectOf(
      PropTypes.shape({
        completedAt: PropTypes.string,
        completedItems: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }),
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['form', 'content']),
  }),
};

Step.displayName = 'Step';
export default Step;
