import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Layout from 'layouts/Layout';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(3),
  },
}));

const DownloadLandingPage = ({ handleDownload }) => {
  const classes = useStyles();

  return (
    <Layout showNavigation>
      <Typography variant="h4" display="block" className={classes.content}>
        <FormattedMessage id="download.title" />
      </Typography>
      <Typography variant="subtitle1" className={classes.content}>
        <FormattedMessage id="download.description" />
      </Typography>
      <Button
        variant="contained"
        onClick={handleDownload}
        className={classes.button}
      >
        <FormattedMessage id="action.download" />
      </Button>
    </Layout>
  );
};

DownloadLandingPage.displayName = 'DownloadLandingPage';
export default DownloadLandingPage;
