import React from 'react';
import { useField } from 'formik';

import MuiRadioGroup from '@mui/material/RadioGroup';

const RadioGroup = ({ name, children, ...props }) => {
  const [field] = useField(name);

  return (
    <MuiRadioGroup name={name} {...field} {...props}>
      {children}
    </MuiRadioGroup>
  );
};

export default RadioGroup;
