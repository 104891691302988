import React from 'react';
import { createSvgIcon } from './svg-helper';

export default createSvgIcon(
  <g
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    opacity="0.9"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g
      transform="translate(-567.000000, -400.000000)"
      stroke="#1C215F"
      strokeWidth="2"
    >
      <path d="M582,401 C575.699525,406.656049 571.032859,411.322716 568,415" />
    </g>
  </g>,
  'DashIcon',
);
