import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DurationIcon from '@mui/icons-material/AccessTime';
import Typography from '@mui/material/Typography';

import { useSaveEvent } from 'core/api/coach';

const FirstCard = ({
  deckId,
  categoryPrimary,
  categoriesOther,
  title,
  description,
  duration,
  objectivesTitle,
  objectives,
  skillsTitle,
  skills,
}) => {
  const { mutateAsync: saveEvent } = useSaveEvent();

  useEffect(() => {
    // save deck started event on first card load
    saveEvent({
      contentType: 'DECK',
      contentId: deckId,
      event: 'started',
      isSurvey: false,
    });
  }, [deckId, saveEvent]);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Box px={{ xs: 2, sm: 3 }}>
          <Box pb={2}>
            <Chip
              label={categoryPrimary.title}
              sx={{
                backgroundColor: `rgba(${categoryPrimary.colorBg})`,
                color: `rgba(${categoryPrimary.colorText})`,
              }}
            />
            {categoriesOther &&
              categoriesOther.map((category) => (
                <Chip
                  key={category.title}
                  label={category.title}
                  sx={{
                    backgroundColor: `rgba(${category.colorBg})`,
                    color: `rgba(${category.colorText})`,
                    ml: 1,
                  }}
                />
              ))}
          </Box>
          <Typography variant="h2" sx={{ pb: 1 }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" sx={{ pb: 1 }}>
            {description}
          </Typography>
          <Box display="flex" alignItems="center">
            <DurationIcon sx={{ color: 'text.secondary', mr: 0.5 }} />
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="time.minutes" values={{ duration }} />
            </Typography>
          </Box>
        </Box>
        <Box
          bgcolor="background.default"
          p={3}
          my={2}
          mx={{ xs: 0, sm: 3 }}
          borderRadius={{ xs: 0, sm: 2 }}
        >
          <Typography variant="h5">{objectivesTitle}</Typography>
          {documentToReactComponents(objectives)}
          <Typography variant="h5">{skillsTitle}</Typography>
          {skills &&
            skills.map((skill) => (
              <Chip
                key={skill.title}
                label={skill.title}
                sx={{ mr: 1, mt: 1 }}
              />
            ))}
        </Box>
      </Box>
      <Button
        variant="contained"
        type="submit"
        sx={{ width: { xs: 'calc(100% - 32px)', sm: 'auto' } }}
      >
        <FormattedMessage id="action.getStarted" />
      </Button>
    </Box>
  );
};

FirstCard.displayName = 'FirstCard';
export default FirstCard;
