import PropTypes from 'prop-types';
import React from 'react';
import { useField, useFormikContext } from 'formik';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

const SingleSelectGrid = ({ id, rows, columns, sourceDataValue = [] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const [field] = useField({ name: id });
  const { isSubmitting } = useFormikContext();
  const rowList = rows || sourceDataValue;

  return (
    <Grid container>
      <Grid
        item
        container
        alignItems="center"
        component={Box}
        pb={2}
        borderBottom="2px solid #ebf1f1"
        wrap="nowrap"
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        />
        {columns.map(({ label, value }) => (
          <Grid item xs key={value}>
            <Typography align="center" sx={{ fontWeight: 'bold' }}>
              {label}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" fullWidth {...field}>
          {rowList.map(({ label, value }) => (
            <Grid
              container
              key={value}
              alignItems="center"
              component={Box}
              py={1}
              borderBottom="2px solid #ebf1f1"
            >
              <Grid item xs={12} sm={6}>
                <Typography align={isMobile ? 'center' : 'inherit'}>
                  {label}
                </Typography>
              </Grid>
              <Grid item xs>
                <RadioGroup
                  name={`${id}.${value}`}
                  value={field.value ? field.value[value] : null}
                  row
                >
                  <Grid container>
                    {columns.map(({ value }) => (
                      <Grid
                        item
                        xs
                        key={value}
                        component={Box}
                        textAlign="center"
                      >
                        <Radio value={value} disabled={isSubmitting} />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          ))}
        </FormControl>
      </Grid>
    </Grid>
  );
};

SingleSelectGrid.propTypes = {
  id: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sourceDataValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

SingleSelectGrid.displayName = 'SingleSelectGrid';
export default SingleSelectGrid;
