import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'components/ContentRenderer/Embeddable/embeddables/components/Chip';

const TaggableChip = ({ value, onClick, isTagged, ...props }) => (
  <Chip
    sx={{ mb: 1 }}
    clickable
    onClick={onClick(value)}
    color={isTagged ? 'secondary' : 'default'}
    {...props}
  />
);

TaggableChip.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isTagged: PropTypes.bool,
  ...Chip.propTypes,
};

TaggableChip.displayName = 'TaggableChip';
export default TaggableChip;
