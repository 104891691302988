import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Unstable_Grid2';

import Card from 'components/ContentRenderer/Embeddable/embeddables/CardFlip/Card';

/**
 * Component to display a list of flippable cards with a dynamic number of faces
 */
const CardFlip = ({ deck, container }) => (
  <Grid container spacing={2}>
    {deck.map((card, idx) => (
      <Grid
        key={idx}
        xs={12}
        md={container === 'small' ? 12 : 6}
        lg={container === 'small' ? 6 : 4}
      >
        <Card {...card} />
      </Grid>
    ))}
  </Grid>
);

CardFlip.propTypes = {
  deck: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

CardFlip.displayName = 'CardFlip';
export default CardFlip;
