const black = '#212C2C';
const white = '#FFFFFF';

export default {
  mode: 'light',
  common: {
    black,
    white,
  },
  primary: {
    light: '#78B4BE',
    main: '#467D86',
    dark: '#376870',
    contrastText: white,
  },
  secondary: {
    light: '#ECF2F3',
    main: '#A2BEC3',
    contrastText: '#376870',
  },
  text: {
    primary: black,
    secondary: '#525252',
    disabled: '#929292',
    link: '#467D86',
  },
  divider: '#E6E6E6',
  background: {
    paper: white,
    default: '#F0F1F1',
    disabled: '#ECECEC',
    hover: '#F4F4F5',
  },
  error: {
    main: '#C30008',
    light: '#FC6865',
    dark: '#8D151A',
    contrastText: white,
  },
  warning: {
    main: '#E56F25',
    light: '#F2A97B',
    dark: '#AF5A24',
    contrastText: white,
  },
  info: {
    main: '#1061AD',
    light: '#4C97FF',
    dark: '#003A70',
    contrastText: white,
  },
  success: {
    main: '#2E7D32',
    light: '#50CE56',
    dark: '#116616',
    contrastText: white,
  },
  muted: {
    primary: '#ECF2F3',
    text: '#DEDFDF',
    green: '#E0ECE0',
    blue: '#DBE7F3',
    orange: '#FBE9DE',
    red: '#F6D9DA',
  },
};
