import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';

import ArrowIcon from 'components/SvgIcons/Arrow';
import Avatar from 'components/Avatar';
import DragComponent from 'components/ContentRenderer/Embeddable/embeddables/components/DragComponent';
import { useBooleanState } from 'core/hooks/useBooleanState';
import { useTheme } from '@mui/material';

const COLORS = {
  green: '#89B744',
  orange: '#DF6B2A',
  teal: '#00A2AE',
  purple: '#926CE0',
  yellow: '#F4DB6B',
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      width: '50%',
      display: 'inline-block',
      padding: theme.spacing(0, 0.5),
    },
  },
  root: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: 12,
    margin: theme.spacing(1, 0),
  },
  isDraggable: {
    transition: theme.transitions.create(
      ['background-color', 'box-shadow', 'transform'],
      {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeOut,
      },
    ),
    '&:active': {
      cursor: 'grabbing',
    },
    '&:hover, &:focus': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  isDragging: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: '0 7px 8px 0 rgba(0,0,0,0.06)',
    '&:hover, &:focus': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  button: {
    width: 120,
    justifyContent: 'space-between',
  },
  icon: {
    transform: 'rotate(90deg)',
  },
  iconExpanded: {
    transform: 'rotate(270deg)',
  },
  dialog: {
    padding: theme.spacing(2),
  },
}));

const DraggableBankBlock = ({
  label,
  color,
  description,
  avatar,
  index,
  isDisabled,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(() => theme.breakpoints.down('md'));

  const [expanded, toggleExpanded] = useBooleanState(false);

  return (
    <DragComponent index={index} isDragDisabled={isDisabled} {...props}>
      {({ snapshot, innerRef, ...draggableProps }) => (
        <div ref={innerRef} className={classes.container}>
          <ListItem
            alignItems="flex-start"
            className={cn(classes.root, {
              [classes.isDraggable]: !isDisabled,
              [classes.isDragging]: snapshot.isDragging,
            })}
            {...props}
            {...draggableProps}
          >
            {avatar && (
              <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' } }}>
                <Avatar style={{ background: COLORS[color] }}>{avatar}</Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              primary={
                <Typography variant={description ? 'h6' : 'body1'}>
                  {label}
                </Typography>
              }
              secondary={
                description && (
                  <>
                    <Button
                      onClick={toggleExpanded}
                      size="small"
                      className={classes.button}
                      endIcon={
                        !isSmall && (
                          <ArrowIcon
                            titleAccess={expanded ? 'Read less' : 'Read more'}
                            className={cn(classes.icon, {
                              [classes.iconExpanded]: expanded,
                            })}
                          />
                        )
                      }
                    >
                      <FormattedMessage
                        id={`action.${expanded ? 'readLess' : 'readMore'}`}
                      />
                    </Button>
                    {isSmall ? (
                      <Dialog
                        open={expanded}
                        onClose={toggleExpanded}
                        maxWidth="xs"
                        classes={{ paper: classes.dialog }}
                      >
                        <Box textAlign="center">
                          {avatar && (
                            <Box mb={1}>
                              <Avatar
                                size="large"
                                style={{ background: COLORS[color] }}
                              >
                                {avatar}
                              </Avatar>
                            </Box>
                          )}
                          <Typography variant="h6" paragraph>
                            {label}
                          </Typography>
                        </Box>
                        <Typography paragraph>{description}</Typography>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={toggleExpanded}
                        >
                          <FormattedMessage id="action.close" />
                        </Button>
                      </Dialog>
                    ) : (
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box mt={1}>
                          <Typography>{description}</Typography>
                        </Box>
                      </Collapse>
                    )}
                  </>
                )
              }
              disableTypography
            />
          </ListItem>
        </div>
      )}
    </DragComponent>
  );
};

DraggableBankBlock.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  description: PropTypes.string,
  color: PropTypes.string,
  avatar: PropTypes.string,
  isDisabled: PropTypes.bool,
};

DraggableBankBlock.displayName = 'DraggableBankBlock';
export default DraggableBankBlock;
