import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Arrow from '@mui/icons-material/PlayCircleFilledOutlined';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Check from 'components/SvgIcons/Check';
import Lock from 'components/SvgIcons/Lock';
import PrivacyDialog from 'components/PrivacyDialog';
import { useBooleanState } from 'core/hooks/useBooleanState';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    border: `2px solid ${theme.palette.divider}`,
    marginTop: '-2px',
    '&:first-child': {
      borderRadius: '12px 12px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 12px 12px',
    },
    '&:first-child:last-child': {
      borderRadius: 12,
    },
    '&$locked': {
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.hover,
    },
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  locked: {},
}));

const ExperienceRow = ({
  experience: { id, title, duration, isFollowOn },
  program: {
    id: programId,
    contentId,
    isClosed,
    isLocked: programIsLocked,
    itemStatus,
  },
}) => {
  const classes = useStyles({ isClosed });
  const [dialogIsOpen, toggleDialogOpen] = useBooleanState(false);

  // status of the current experience
  const status = itemStatus[id] || {};

  const isComplete = !!status['completedAt'];
  const isIncomplete = !isComplete && isClosed;
  const isLocked = programIsLocked || status['locked'];
  const isAlwaysLocked = status['locked'] && !status['unlockAt'];
  const isActive = !!status['completedItems']; // in progress
  const isOpen = !isLocked && !isComplete && !isActive; // open but not yet in progress

  const Icon = isLocked ? Lock : isComplete ? Check : Arrow;

  /**
   * "Completed { date }" --> Experience is complete
   * "In progress" --> Experience is unlocked & progress is > 0
   * "Unlocks { date }" --> Experience is locked & has an unlock date
   */
  return (
    <ListItemButton
      onClick={toggleDialogOpen}
      component={isOpen && !isFollowOn ? 'a' : Link}
      to={isOpen && !isFollowOn ? null : `${programId}/p/${contentId}/e/${id}`}
      disabled={isLocked}
      disableTouchRipple
      classes={{
        root: cn(classes.item, {
          [classes.active]: isActive,
        }),
        disabled: classes.locked,
      }}
    >
      <Box>
        <Typography
          color={isActive ? 'primary' : 'default'}
          sx={{ fontWeight: isFollowOn ? 'regular' : 'bold' }}
        >
          {title}
        </Typography>
        {!(isOpen || isIncomplete || isAlwaysLocked) && (
          <Typography variant="body2" color={isActive ? 'primary' : 'default'}>
            <FormattedMessage
              id={
                isComplete
                  ? 'date.completed'
                  : isLocked
                  ? 'date.unlocked'
                  : 'program.active'
              }
              values={{
                unlockAt: moment.utc(status['unlockAt']).format('ll'),
                completedAt: moment.utc(status['completedAt']).format('ll'),
              }}
            />
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="end" whiteSpace="nowrap" ml={1}>
        {!(isClosed || isLocked) && (
          <Typography variant="body2">
            <FormattedMessage id="time.minutes" values={{ duration }} />
          </Typography>
        )}
        {!isIncomplete && (
          <Icon
            sx={{
              ml: 2,
              color: isLocked || isClosed ? 'text.disabled' : 'primary.main',
            }}
          />
        )}
      </Box>
      {dialogIsOpen && (
        <PrivacyDialog
          open
          onClose={toggleDialogOpen}
          maxWidth="md"
          link={`${programId}/p/${contentId}/e/${id}`}
        />
      )}
    </ListItemButton>
  );
};

ExperienceRow.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    isFollowOn: PropTypes.bool.isRequired,
  }),
  program: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contentId: PropTypes.string.isRequired,
    isClosed: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
    itemStatus: PropTypes.objectOf(
      PropTypes.shape({
        completedAt: PropTypes.string,
        completedItems: PropTypes.arrayOf(PropTypes.string),
        locked: PropTypes.bool,
        unlockAt: PropTypes.string,
      }),
    ).isRequired,
  }),
};

ExperienceRow.displayName = 'ExperienceRow';
export default ExperienceRow;
