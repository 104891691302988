import { menuItemClasses } from '@mui/material/MenuItem';

import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: palette.background.hover,
      },
      [`&.${menuItemClasses.selected}, &.${menuItemClasses.selected}:hover, &.${menuItemClasses.selected}:focus`]: {
        backgroundColor: palette.muted.primary,
      },
    },
    selected: {},
  },
};
