import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import DashIcon from 'components/SvgIcons/DashIcon';

const COLORS = {
  teal: '#48a1a7',
  orange: '#c66630',
  green: '#7b9f45',
  navy: '#5f85c3',
  purple: '#876bc1',
  burgundy: '#b35a8d',
  yellow: '#F4DB6B',
};

const DotIndicator = ({ color, striked }) => {
  return (
    <Box
      bgcolor={COLORS[color]}
      width={18}
      height={18}
      whiteSpace="noWrap"
      border="1px solid white"
      borderRadius="50%"
      mr={1}
    >
      {striked && <DashIcon />}
    </Box>
  );
};

DotIndicator.propTypes = {
  color: PropTypes.oneOf(['teal', 'yellow', 'orange', 'purple']),
  striked: PropTypes.bool,
};

DotIndicator.displayName = 'DotIndicator';
export default DotIndicator;
