import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Fade from '@mui/material/Fade';

import { FormWizardContext } from './FormWizard';

const FormWizardSteps = ({
  children,
  containerComponent: ContainerComponent = 'div',
  containerProps,
  transitionComponent: TransitionComponent = Fade,
  transitionProps,
  disableTransition,
}) => {
  const { activeStep } = useContext(FormWizardContext);
  const step = React.Children.toArray(children)[activeStep];

  if (disableTransition) {
    return <ContainerComponent {...containerProps}>{step}</ContainerComponent>;
  }

  return (
    <TransitionComponent
      key={`step${activeStep}-transition`}
      appear
      in
      mountOnEnter
      unmountOnExit
      {...transitionProps}
    >
      <ContainerComponent {...containerProps}>{step}</ContainerComponent>
    </TransitionComponent>
  );
};

FormWizardSteps.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  containerComponent: PropTypes.elementType,
  containerProps: PropTypes.object,
  transitionComponent: PropTypes.elementType,
  transitionProps: PropTypes.object,
  disableTransition: PropTypes.bool,
};

FormWizardSteps.displayName = 'FormWizardSteps';
export default FormWizardSteps;
