import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import makeStyles from '@mui/styles/makeStyles';

import ProfileIcon from 'assets/learning/profile-summary.svg';
import { useStores } from 'core/hooks/useStores';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0),
  },
  listItem: {
    display: 'inline-flex',
    width: '50%',
    '&:before': {
      content: '"-"',
      paddingRight: theme.spacing(),
    },
  },
  actions: {
    justifyContent: 'end',
    padding: theme.spacing(0, 2, 2),
  },
}));

const SavableAnswerList = observer(
  ({ title, attributeName, type, sourceData, getSourceDataValue }) => {
    const classes = useStyles();
    const intl = useIntl();

    const { userStore } = useStores();
    const { getAttributesByName, updateAttributes } = userStore;

    const sourceDataValue = getSourceDataValue(sourceData, type) || {};
    const { items = [] } = sourceDataValue;

    useEffect(() => {
      // Reset the state of updateAttributes task before it's potentially called again
      updateAttributes.reset();
    }, [updateAttributes]);

    const onSave = async () => {
      // Get existing attribute values for current user, e.g. -
      // { identities: [{ id: 1, value: 'woman' }, { id: 2, value: 'brunette' }] }
      const existingAttributeValues = await getAttributesByName([
        attributeName,
      ]);

      // Get new attribute values (values not included in existing)
      const newAttributeValues = items.filter(
        (item) =>
          !existingAttributeValues[attributeName].some(
            ({ value }) => value === item.value,
          ),
      );

      try {
        // Map merged attributes values into array of objects in shape accepted by API & save
        await updateAttributes([
          {
            name: attributeName,
            values: [
              ...newAttributeValues,
              ...existingAttributeValues[attributeName],
            ],
          },
        ]);
        successNotification(
          intl.formatMessage({
            id: 'savableAnswerList.save.success',
          }),
        );
      } catch {
        errorNotification(intl.formatMessage({ id: 'error.generic' }));
      }
    };

    return (
      <Card variant="outlined" className={classes.container}>
        <CardHeader
          avatar={<img src={ProfileIcon} alt="" width={35} />}
          title={title}
          titleTypographyProps={{ variant: 'h6' }}
          subheaderTypographyProps={{ variant: 'caption' }}
          subheader={intl.formatMessage({
            id: 'savableAnswerList.subtitle',
          })}
        />
        <CardContent>
          <List dense disablePadding>
            {items.map(({ value }) => (
              <ListItem key={value} className={classes.listItem}>
                {value}
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={onSave} variant="contained" size="small">
            <FormattedMessage id="action.save" />
          </Button>
        </CardActions>
      </Card>
    );
  },
);

SavableAnswerList.propTypes = {
  title: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['savableAnswerList']).isRequired,
  sourceData: PropTypes.arrayOf(PropTypes.object),
  getSourceDataValue: PropTypes.func.isRequired,
};

SavableAnswerList.displayName = 'SavableAnswerList';
export default SavableAnswerList;
