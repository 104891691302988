import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CircleDetail from './CircleDetail';
import CircleList from './CircleList';

const Circles = ({ match }) => {
  return (
    <Switch>
      <Route path={match.path} component={CircleList} exact />
      <Route
        path={[
          `${match.path}/:programId`,
          `${match.path}/:programId/:circleId`,
        ]}
        component={CircleDetail}
        exact
      />
    </Switch>
  );
};

export default Circles;
